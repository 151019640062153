import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Header from '../CommonTemplates/Header.js';
import Sidebar from '../CommonTemplates/SideBar.js';
import 'react-toastify/dist/ReactToastify.css';
import { getProducts } from '../../_actions/productActions.js';
import { getReview, updateReview } from '../../_actions/reviewActions.js';
import { getUsers } from '../../_actions/userActions.js';


class EditReview extends Component { 
    state = {
        readOnly    : true,
        fields      : {},
        errors      : {},
    }

    async componentDidMount() {
        await this.props.getProducts();
        await this.props.getUsers();
        await this.props.getReview(this.props.match.params.id);
        var review = this.props.reviews.review;
        var fields = this.state.fields;
        Object.keys(review)
            .forEach(function eachKey(key) {               
                fields[key] = review[key];                   
            });
        this.setState(fields);
    }  
        
    // Input handle  
    handleChange = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({errors:''}); 
        this.setState(fields);
    }

    // Validation 
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if(!fields[ 'rating' ]){
            formIsValid = false;
            errors[ 'rating' ] = 'Please select rating.';
        }
        if(!fields[ 'comment' ]){
            formIsValid = false;
            errors[ 'comment' ] = 'Please enter comment.';
        }
        if(!fields[ 'product_id' ]){
            formIsValid = false;
            errors[ 'product' ] = 'Please enter select product.';
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    //Handle Submit 
    handleSubmit = async (e) => {
        e.preventDefault();
        if(this.handleValidation()) {
            const commentData = {
                review_id     : this.state.fields[ 'id' ],
                rating        : this.state.fields[ 'rating' ],
                comment       : this.state.fields[ 'comment' ],
                product_id    : this.state.fields[ 'product_id' ],
                rated_by      : this.state.fields[ 'rated_by' ],
            };
            await this.props.updateReview(commentData, this.props.history);
        }            
    }
    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar/>
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                                <Header/>
                                <div className="main-content-container container-fluid px-4">
                                    <div className="page-header row no-gutters py-4">
                                        <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                                            <span className="text-uppercase page-subtitle">Overview</span>
                                            <h3 className="page-title">Edit Review</h3>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card card-small mb-4">
                                                <div className="card-header border-bottom">
                                                    <h6 className="m-0">Review Details</h6>
                                                </div>
                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item p-3">
                                                        <div className="row">
                                                            <div className="col">
                                                                <form onSubmit={this.handleSubmit} enctype='multipart/form-data'>
                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6">
                                                                            <label for="feProduct">Product</label>
                                                                            <select id="category" name="product_id" disabled className="form-control" value={this.state.fields[ 'product_id' ]} onChange={this.handleChange}>
                                                                                <option>Select Product</option>
                                                                                { this.props.products.products.map((item, index) => (
                                                                                    <option key ={index}  value={item.id}>{item.name}</option>
                                                                                )) }
                                                                            </select>
                                                                            <span style={ { color : 'red' } }>{this.state.errors[ 'product' ]}</span>
                                                                        </div>
                                                                        <div className="form-group col-md-6">
                                                                            <label for="feProduct">Rated By</label>
                                                                            <select id="ratedby" name="rated_by" disabled className="form-control" value={this.state.fields[ 'rated_by' ]} onChange={this.handleChange}>
                                                                                <option>Select User</option>
                                                                                { this.props.users.users.map((item, index) => (
                                                                                    <option key ={index}  value={item.id}>{item.name}</option>
                                                                                )) }
                                                                            </select>
                                                                            <span style={ { color : 'red' } }>{this.state.errors[ 'product' ]}</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6">
                                                                            <label for="feComment">Comment</label>
                                                                            <textarea className="form-control" name="comment"  onChange={this.handleChange} placeholder='Enter comment...' id="comment" value={this.state.fields[ 'comment' ]} rows="5"></textarea>
                                                                            <span style={ { color : 'red' } }>{this.state.errors[ 'comment' ]}</span>
                                                                        </div>
                                                                        <div className="form-group col-md-6">
                                                                            <label for="feRatings">Rating</label>
                                                                            <select id="rating" name="rating" className="form-control" value={this.state.fields[ 'rating' ]} onChange={this.handleChange}>
                                                                                <option value=''>Select Rating</option>
                                                                                <option value='5'>Excellent</option>
                                                                                <option value='4'>Very Good</option>
                                                                                <option value='3'>Good</option>
                                                                                <option value='2'>Fair</option>
                                                                                <option value='1'>Poor</option>
                                                                            </select> 
                                                                            <span style={{color: 'red' }}>{this.state.errors[ 'rating' ]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <button type="submit" className="btn btn-success">Update</button>&nbsp;
                                                                    <Link to="/reviews-list" className="btn btn-danger">Cancel</Link>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <ToastContainer />
                            {/* <Footer/> */}
                        </main>
                    </div>
                </div>
            </React.Fragment>   
        );
    }
}

const mapStateToProps = state => ({
    products    : state.products,
    reviews     : state.reviews,
    users       : state.users,
});
const actionCreators = {
    getProducts     : getProducts,
    getReview       : getReview,
    getUsers        : getUsers,
    updateReview    : updateReview
};

export default connect(mapStateToProps, actionCreators)(EditReview);