import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { login } from "../../_actions/authActions.js";

export class Login extends Component {
  state = {
    errors: {},
    fields: {},
  };

  componentDidMount() {
    var remember = reactLocalStorage.getObject("RememberMe");
    if (remember !== "") {
      let fields = this.state.fields;
      fields["email"] = remember.email;
      fields["password"] = remember.password;
      this.setState(fields);
    }
  }
  onChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState(fields);
  };

  handleInputChange(event) {
    var email = document.getElementById("email").value;
    var password = document.getElementById("password").value;
    var data = {
      email: email,
      password: password,
    };
    if (email === "" && password === "") {
      alert("Please fill details");
    } else {
      reactLocalStorage.setObject("RememberMe", data);
    }
  }

  componentWillMount() {
    this.handleAuth();
  }

  // Check if user login or not
  handleAuth() {
    var auth = reactLocalStorage.getObject("Auth");
    const data = JSON.stringify(auth);
    console.log(data + data.length);
    if (data.length === 2) {
      this.props.history.push("/");
    } else {
      this.props.history.push("/dashboard");
    }
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Please enter email.";
    }
    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Please enter valid email.";
      }
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Please enter password.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      const userData = {
        username: this.state.fields["email"],
        password: this.state.fields["password"],
      };
      this.props.login(userData, this.props.history);
    }
  };
  render() {
    return (
      <React.Fragment>
        <main className="main-content col">
          <div className="main-content-container container-fluid px-4 my-auto h-100">
            <div className="row no-gutters h-100">
              <div className="col-lg-3 col-md-5 auth-form mx-auto my-auto my-5">
                <div className="card">
                  <div className="card-body">
                    {/* <img className="auth-form__logo d-table mx-auto mb-3" src="../assets/images/shards-dashboards-logo.svg" alt="Node Admin Infowind"/> */}
                    <img
                      className="auth-form__logo d-table mx-auto mb-3"
                      src="../assets/images/PITENXLM_logo.png"
                      alt="Pitenxlm Panel"
                      height="100"
                      width="100"
                    />
                    <h5 className="auth-form__title text-center mb-4">
                      Pitenxlm Panel
                    </h5>
                    <form onSubmit={this.onSubmit}>
                      <div className="form-group">
                        <label for="Email">Email address</label>
                        <input
                          type="email"
                          className="form-control"
                          onChange={this.onChange}
                          id="email"
                          name="email"
                          aria-describedby="emailHelp"
                          placeholder="Enter email"
                          value={this.state.fields["email"]}
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["email"]}
                        </span>
                      </div>
                      <div className="form-group">
                        <label for="Password1">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          onChange={this.onChange}
                          name="password"
                          placeholder="Password"
                          value={this.state.fields["password"]}
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["password"]}
                        </span>
                      </div>
                      {/* <div className="form-group mb-3 d-table mx-auto">
                                                <div className="custom-control custom-checkbox mb-1">
                                                    <input type="checkbox" onChange={this.handleInputChange} className="custom-control-input" id="customCheck2"/>
                                                    <label className="custom-control-label" for="customCheck2">Remember me for 30 days.</label>
                                                </div>
                                            </div> */}
                      <button
                        type="submit"
                        className="btn btn-pill btn-accent d-table mx-auto"
                      >
                        Login
                      </button>
                    </form>
                  </div>
                </div>
                <div className="auth-form__meta text-center mt-3">
                  <Link className="btn btn-sm btn-accent" to="/reset-password">
                    Forgot your password?
                  </Link>
                  {/* <Link className="ml-auto" to="/sign-up">Create new account?</Link> */}
                </div>
              </div>
            </div>
          </div>
        </main>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { login })(Login);
