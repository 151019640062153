import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Header from '../CommonTemplates/Header.js';
import Sidebar from '../CommonTemplates/SideBar.js';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { addCoupon } from '../../_actions/couponActions.js';

class AddCoupon extends Component {
    state = {
        readOnly: true,
        fields: {},
        errors: {},
        expiry_date: '',
    }

    async componentDidMount() {
    }

    // Input handle  
    handleChange = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ errors: '' });
        this.setState(fields);
    }

    // Validation 
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields['code']) {
            formIsValid = false;
            errors['code'] = 'Please enter coupon code.';
        }
        if (!fields['value']) {
            formIsValid = false;
            errors['value'] = 'Please enter value.';
        }
        if (!fields['type']) {
            formIsValid = false;
            errors['type'] = 'Please select type.';
        }
        if (!fields['date']) {
            formIsValid = false;
            errors['date'] = 'Please select date.';
        }
        if (!fields['member']) {
            formIsValid = false;
            errors['member'] = 'Please select member.';
        }
        if (!fields['minimum_amount']) {
            formIsValid = false;
            errors['minimum_amount'] = 'Please enter minimum amount for coupon.'
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    // Handle Submit 
    handleSubmit = async (e) => {
        e.preventDefault();
        if (this.handleValidation()) {
            const couponData = {
                expiry_date: this.state.fields['date'],
                code: this.state.fields['code'],
                value: this.state.fields['value'],
                minimum_amount: this.state.fields['minimum_amount'],
                type: this.state.fields['type'],
                member: this.state.fields['member'],
            };
            await this.props.addCoupon(couponData, this.props.history);
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <Header />
                            <div className="main-content-container container-fluid px-4">
                                <div className="page-header row no-gutters py-4">
                                    <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                                        <span className="text-uppercase page-subtitle">Overview</span>
                                        <h3 className="page-title">Add Coupon</h3>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card card-small mb-4">
                                            <div className="card-header border-bottom">
                                                <h6 className="m-0">Coupon Details</h6>
                                            </div>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item p-3">
                                                    <div className="row">
                                                        <div className="col">
                                                            <form onSubmit={this.handleSubmit} enctype='multipart/form-data'>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feCode">Coupon Code</label>
                                                                        <input type="text" className="form-control" id="code" name="code" maxLength='6' placeholder="Enter Coupon Code." value={this.state.fields['code']} onInput={(e) => { e.target.value = e.target.value.replace(/[^A-Z0-9]/g, ''); }} onChange={this.handleChange} />
                                                                        <span style={{ color: 'red' }}>{this.state.errors['code']}</span>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feAmount">Minimum amount</label>
                                                                        <input type="text" className="form-control" id="minimum_amount" name="minimum_amount" maxLength='5' placeholder="Enter minimum amount." value={this.state.fields['minimum_amount']} onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, ''); }} onChange={this.handleChange} />
                                                                        <span style={{ color: 'red' }}>{this.state.errors['minimum_amount']}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feValue">Coupon Value</label>
                                                                        <input type="text" className="form-control" id="value" name="value" maxLength='10' placeholder="Enter Coupon value." value={this.state.fields['value']} onChange={this.handleChange} />
                                                                        <span style={{ color: 'red' }}>{this.state.errors['value']}</span>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feType">Status</label>
                                                                        <select id="type" name="type" className="form-control" value={this.state.fields['type']} onChange={this.handleChange}>
                                                                            <option value=''>Coupon Type</option>
                                                                            <option value='flat'>Flat</option>
                                                                            <option value='percent'>Percent</option>
                                                                        </select>
                                                                        <span style={{ color: 'red' }}>{this.state.errors['type']}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-row">
                                                                    {/* <div className='form-group col-md-6'>
                                                                            <div><label for="feExpiry">Expiry Date</label></div>

                                                                            <DatePicker selected={this.state.expiry_date} onChange={this.dateChange} className="form-control" />
                                                                            <span style={{color: 'red' }}>{this.state.errors[ 'date' ]}</span>
                                                                        </div> */}
                                                                    <div className='form-group col-md-6'>
                                                                        <div><label for="feExpiry">Expiry Date</label></div>
                                                                        <input type='date' name='date' className="form-control" value={this.state.fields['date']} onChange={this.handleChange} />
                                                                        <span style={{ color: 'red' }}>{this.state.errors['date']}</span>
                                                                    </div>
                                                                    <div className='form-group col-md-6'>
                                                                        <div><label for="feExpiry">Member</label></div>
                                                                        <select id="member" name="member" className="form-control" value={this.state.fields['member']} onChange={this.handleChange}>
                                                                            <option value={""}>Select Member</option>
                                                                            <option value={'all'}>ALL</option>
                                                                            <option value={'silver'}>SILVER</option>
                                                                            <option value={'gold'}>GOLD</option>
                                                                            <option value={'platinum'}>PLATINUM</option>
                                                                        </select>
                                                                        <span style={{ color: 'red' }}>{this.state.errors['member']}</span>
                                                                    </div>
                                                                </div>
                                                                <button type="submit" className="btn btn-success">Submit</button>&nbsp;
                                                                <Link to='/coupons-list' className="btn btn-danger">Cancel</Link>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer />
                            {/* <Footer/> */}
                        </main>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
});
const actionCreators = {
    addCoupon: addCoupon,
};

export default connect(mapStateToProps, actionCreators)(AddCoupon);