import {
    POST_BANNER_ADD_REQUEST, GET_BANNER_ADD_SUCCESS, GET_BANNER_ADD_FAILURE,
    POST_BANNERS_REQUEST, GET_BANNERS_SUCCESS, GET_BANNERS_FAILURE,
    POST_BANNER_REQUEST, GET_BANNER_SUCCESS, GET_BANNER_FAILURE,
    POST_REMOVE_BANNER_REQUEST, GET_REMOVE_BANNER_SUCCESS, GET_REMOVE_BANNER_FAILURE,
    POST_UPDATE_BANNER_REQUEST, GET_UPDATE_BANNER_SUCCESS, GET_UPDATE_BANNER_FAILURE,
    POST_BANNER_IMAGE_REQUEST, GET_BANNER_IMAGE_SUCCESS, GET_BANNER_IMAGE_FAILURE
} from '../_actions/_type';
import {
    URL_ADMIN_ADD_BANNER, URL_ADMIN_GET_SINGLE_BANNER, URL_ADMIN_GET_BANNERS_LIST,
    URL_ADMIN_UPDATE_BANNER, URL_ADMIN_UPLOAD_BANNER_IMAGE, URL_ADMIN_REMOVE_BANNER
} from '../utils/admin-api-url.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';
import axios from 'axios';

// Add banner.
export const addBanner = (bannerData, history) => async dispatch => {
    try {
        dispatch({ type: POST_BANNER_ADD_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_ADD_BANNER, bannerData, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_BANNER_ADD_SUCCESS, payload: response.data });
            history.push('/banners-list');
            toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_BANNER_ADD_FAILURE, payload: error });
    }
};


// Get Banner
export const getBanner = (ID) => async dispatch => {
    try {
        dispatch({ type: POST_BANNER_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_GET_SINGLE_BANNER}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_BANNER_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_BANNER_FAILURE, payload: error });
    }
};

// Get Brand list
export const getBanners = () => async dispatch => {
    try {
        dispatch({ type: POST_BANNERS_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_GET_BANNERS_LIST, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_BANNERS_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_BANNERS_FAILURE, payload: error });
    }
};

// Update Brand
export const updateBanner = (bannerData, history) => async dispatch => {
    try {
        dispatch({ type: POST_UPDATE_BANNER_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_UPDATE_BANNER, bannerData, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_UPDATE_BANNER_SUCCESS, payload: response.data });
            history.push('/banners-list');
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_UPDATE_BANNER_FAILURE, payload: error });
    }
};

// Remove Brand
export const deleteBanner = (ID, history) => async dispatch => {
    try {
        dispatch({ type: POST_REMOVE_BANNER_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_REMOVE_BANNER}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_REMOVE_BANNER_SUCCESS, payload: response.data });
            toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_REMOVE_BANNER_FAILURE, payload: error });
    }
};

// Upload Brand Image
export const uploadBannerImage = (brandImage) => async dispatch => {
    try {
        dispatch({ type: POST_BANNER_IMAGE_REQUEST, payload: true });
        const responseLogo = await axios.post(URL_ADMIN_UPLOAD_BANNER_IMAGE, brandImage, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (responseLogo.data.status === true) {
            dispatch({ type: GET_BANNER_IMAGE_SUCCESS, payload: responseLogo.data });
        } else {
            toast.error(responseLogo.data.message, { position: toast.POSITION.TOP_RIGHT });
        }
    } catch (error) {
        dispatch({ type: GET_BANNER_IMAGE_FAILURE, payload: error });
    }
};
