import {  
    POST_ADD_DELIVERY_REQUEST, GET_ADD_DELIVERY_SUCCESS, GET_ADD_DELIVERY_FAILURE,
    POST_DELIVERIES_REQUEST, GET_DELIVERIES_SUCCESS, GET_DELIVERIES_FAILURE,
    POST_DELIVERY_REQUEST, GET_DELIVERY_SUCCESS, GET_DELIVERY_FAILURE,
    POST_UPDATE_DELIVERY_REQUEST, GET_UPDATE_DELIVERY_SUCCESS, GET_UPDATE_DELIVERY_FAILURE,
    POST_REMOVE_DELIVERY_REQUEST, GET_REMOVE_DELIVERY_SUCCESS, GET_REMOVE_DELIVERY_FAILURE,
} from '../_actions/_type'; 

export const initialState = {
    deliveryCreated     : false,
    deliveryUpdated     : false,
    deliveryDeleted     : false,
    delivery            : [],
    deliveries          : [],
    errors              : {},
};
export default function deliveries (state = initialState, action) {
    switch (action.type) {
        case POST_ADD_DELIVERY_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_ADD_DELIVERY_SUCCESS:
            return {
                ...state,
                deliveryCreated : true,
                loading         : false
            };
        case GET_ADD_DELIVERY_FAILURE:
            return {
                ...state,
                loading         : false
            };
        case POST_DELIVERIES_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_DELIVERIES_SUCCESS:
            return {
                ...state,
                deliveries      : action.payload,
                loading         : false
            };
        case GET_DELIVERIES_FAILURE:
            return {
                ...state,
                loading         : false
            };
        case POST_DELIVERY_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_DELIVERY_SUCCESS:
            return {
                ...state,
                delivery        : action.payload,
                loading         : false
            };
        case GET_DELIVERY_FAILURE:
            return {
                ...state,
                loading         : false
            };
        case POST_UPDATE_DELIVERY_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_UPDATE_DELIVERY_SUCCESS:
            return {
                ...state,
                deliveryUpdated : true,
                loading         : false
            };
        case GET_UPDATE_DELIVERY_FAILURE:
            return {
                ...state,
                loading         : false
            };
        case POST_REMOVE_DELIVERY_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_REMOVE_DELIVERY_SUCCESS:
            return {
                ...state,
                deliveryDeleted : true,
                loading         : false
            };
        case GET_REMOVE_DELIVERY_FAILURE:
            return {
                ...state,
                loading         : false
            };
        default:
            return state;
    }
}
