import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MDBDataTable } from 'mdbreact';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { Fab } from 'react-tiny-fab';
import EditIcon from '@material-ui/icons/Edit';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import BlockIcon from '@material-ui/icons/Block';
import SideBar from '../CommonTemplates/SideBar.js';
import Header from '../CommonTemplates/Header.js';
import Moment from 'moment';
import { BASEASSETURL, URL_ADMIN_ARTICLE, URL_ADMIN_DELETE_ARTICLE, URL_ADMIN_GET_ARTICLE } from '../../utils/admin-api-url.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';
import axios from 'axios';

export class ListArticles extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            readOnly: true,
            showModal: false,
            id: ''
        }
    }
    async componentDidMount() {
        await this.getData.bind(this)()
    }

    async getData() {
        try {
            const response = await axios.post(URL_ADMIN_GET_ARTICLE, {}, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    'x-access-token': reactLocalStorage.getObject('access-token')
                }
            });
            if (response.data.status === true) {
                this.setState({
                    data: response.data.data
                })
            } else {
                toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            console.log(error);
        }
    }

    handleOpenModal = id => {
        this.setState({ showModal: true, id });
    };

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    handleConfirmDelete = async () => {
        this.setState({ showModal: false });
        const response = await axios.post(`${URL_ADMIN_DELETE_ARTICLE}/${this.state.id}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
            await this.getData.bind(this)();
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    };

    addNew = () => {
        this.props.history.push('add-articles');
    }

    render() {
        const coupons = this.state.data;
        let displayContent = [];
        // let i = 1;
        console.log(coupons);
        displayContent = coupons.map((rowInfo, index) => ({
            no: index++ + 1,
            // eslint-disable-next-line jsx-a11y/img-redundant-alt
            image: <img
                // width={500}
                style={{
                    width: 100
                }}
                src={BASEASSETURL + rowInfo.images}
                alt="notfound"
                className=""
            />,
            title: rowInfo.title,
            description: rowInfo.description?.substring(0, 300) + ' ...',
            created_at: Moment(rowInfo.created_at).format('YYYY-MM-DD'),
            action:
                <div>
                    <button className="btn btn-danger" onClick={() => this.handleOpenModal(rowInfo.id)}><DeleteRoundedIcon /></button>&nbsp;
                    <Link to={`/edit-articles/${(rowInfo.id)}`} className="btn btn-warning"><EditIcon /></Link>
                </div>
        }));
        // this.setState({
        //     displayContent
        // })
        // console.log(displayContent);
        const data = {
            columns: [
                {
                    label: 'S.No',
                    field: 'no',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Images',
                    field: 'image',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Title',
                    field: 'title',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'description',
                    field: 'description',
                    sort: 'asc',
                    width: 150
                },

                {
                    label: 'Createdat',
                    field: 'created_at',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Action',
                    field: 'action',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: displayContent
        };

        console.log(data);
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <SideBar />
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <Header />
                            <div className="main-content-container container-fluid px-4">
                                <div class="page-header row no-gutters py-4">
                                    <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
                                        <span class="text-uppercase page-subtitle">Overview</span>
                                        <h3 class="page-title">List Articles</h3>
                                    </div>
                                </div>
                                <Fab
                                    icon="+"
                                    alwaysShowTitle={false}
                                    onClick={this.addNew}
                                >
                                </Fab>
                                <div class="row">
                                    <div class="col">
                                        <div class="card card-small mb-4">
                                            {/* <div class="card-header border-bottom">
                                                <h6 class="m-0">Active Coupons</h6>
                                            </div> */}
                                            <div class="card-body pb-3 text-center">
                                                <MDBDataTable striped bordered hover data={data} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer />
                            {/* <Footer/> */}
                            <ReactModal isOpen={this.state.showModal} contentLabel="onRequestClose Example" onAfterOpen={this.afterOpenModal}
                                onRequestClose={this.handleCloseModal} shouldCloseOnOverlayClick={false} style={customStyles} >
                                <h3>Are you sure delete this user ?</h3>
                                <div className="btn-modal-container">
                                    <ul>
                                        <li>
                                            <button
                                                className="btn btn-sm btn-warning btn-modal-close"
                                                onClick={this.handleCloseModal}
                                            >
                                                Cancel
                                            </button>
                                        </li>
                                        <li>
                                            {' '}
                                            <button
                                                className="btn btn-sm btn-danger btn-modal-confirm"
                                                onClick={this.handleConfirmDelete}
                                            >
                                                Delete
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </ReactModal>
                        </main>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}



const customStyles = {
    content: {
        color: 'darkred',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    },
    overlay: {
        backgroundColor: '#444',
        opacity: '0.9'
    }
};

export default ListArticles
