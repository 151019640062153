import { combineReducers } from 'redux';
import auth from './auth.js';
import users from './users.js';
import categories from './categories.js';
import products from './products.js';
import addresses from './addresses.js';
import reviews from './reviews.js';
import deliveries from './deliveries.js';
import coupons from './coupons.js';
import brands from './brands.js';
import banners from './banners.js';
import units from './units.js';
import packets from './packets.js';
import productVariants from './productVariants.js';
import shippings from './shippings.js';
import options from './helpOptions.js';
import orders from './orders.js';
import queries from './queries.js';
import taxes from './taxes.js';
import dashboards from './dashboards.js';
import onboardings from './onboarding.js';

export default combineReducers({
    products, categories, auth,
    users, addresses, reviews,
    deliveries, coupons, brands,
    banners, units, packets,
    productVariants, shippings, options,
    orders, queries, taxes,
    dashboards, onboardings
});
