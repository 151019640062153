import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Header from '../CommonTemplates/Header.js';
import Sidebar from '../CommonTemplates/SideBar.js';
import 'react-toastify/dist/ReactToastify.css';
import { addUser, uploadUserImage } from '../../_actions/userActions.js';

class AddUser extends Component { 
    state = {
        readOnly: true,
        fields: {},
        errors: {},
        form_data:{},
        userImage:'',
    }

    async componentDidMount() {
    }  
        
    // Input handle  
    handleChange = (e) => { 
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({errors:''}); 
        this.setState(fields);
    }

    // Upload category image
    onChangeFile = async (e) => { 
        var file = e.target.files[0];
        var formData = new FormData();
        formData.append('user', file.name);
        formData.append('photo', file);
        await this.props.uploadUserImage(formData);
        this.setState({userImage: this.props.users.imagePath});
    }

    // Validation 
    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if(!fields[ 'name' ]){
            formIsValid = false;
            errors[ 'name' ] = 'Please enter name.';
        } else if(!fields[ 'name' ].match(/^[a-zA-Z ]+$/)){
            formIsValid = false;
            errors[ 'name' ] = 'Please enter valid name.';
        }
        if(!fields[ 'phone' ]){
            formIsValid = false;
            errors[ 'phone' ] = 'Please enter phone number.';
        } else if (!fields[ 'phone' ].match(/^[6789]\d{9}$/)) {
            formIsValid = false;
            errors[ 'phone' ] = 'Please valid enter phone number.';
        }
        if(!fields[ 'email' ]){
            formIsValid = false;
            errors[ 'email' ] = 'Please enter email.';
        }
        if(this.state.userImage === '') {
            formIsValid = false;
            errors[ 'image' ] = 'Please select image.';
        }
        if(typeof fields[ 'email' ] !== 'undefined'){
            let lastAtPos = fields[ 'email' ].lastIndexOf('@');
            let lastDotPos = fields[ 'email' ].lastIndexOf('.');
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields[ 'email' ].indexOf('@@') === -1 && lastDotPos > 2 && (fields[ 'email' ].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors[ 'email' ] = 'Please enter valid email.';
            }
        }
        if(!fields[ 'password' ]){
            formIsValid = false;
            errors[ 'password' ] = 'Please enter password.';
        }/*  else if (!fields[ 'password' ].match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)){
            formIsValid = false;
            errors[ 'password' ] = 'Please enter valid password.';
        } */
        if(!fields[ 'c_password' ]){
            formIsValid = false;
            errors[ 'c_password' ] = 'Please enter password.';
        }
        if(fields[ 'password' ] !== fields[ 'c_password' ]){
            formIsValid = false;
            errors[ 'c_password' ] = 'Confirm password has not matched.';
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    //Handle Submit 
    handleSubmit = async (e) =>{
        e.preventDefault();
        if(this.handleValidation()){
            const user = {
                profile       : this.state.userImage,
                name          : this.state.fields[ 'name' ],
                phone         : this.state.fields[ 'phone' ],
                email         : this.state.fields[ 'email' ],
                password      : this.state.fields[ 'password' ],
            };
            await this.props.addUser(user, this.props.history);
        }           
    }
    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar/>
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                                <Header/>
                                <div className="main-content-container container-fluid px-4">
                                    <div className="page-header row no-gutters py-4">
                                        <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                                            <span className="text-uppercase page-subtitle">Overview</span>
                                            <h3 className="page-title">Add user</h3>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card card-small mb-4">
                                                <div className="card-header border-bottom">
                                                    <h6 className="m-0">Account Details</h6>
                                                </div>
                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item p-3">
                                                        <div className="row">
                                                            <div className="col">
                                                                <form onSubmit={this.handleSubmit}>
                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6">
                                                                            <label for="feFirstName">Full Name</label>
                                                                            <input type="text" className="form-control" id="name" name="name" maxLength='30' onInput={(e) => { e.target.value = e.target.value.replace(/[^a-zA-Z ]/g, ''); }} placeholder="Full Name" value={this.state.fields[ 'name' ]} onChange={this.handleChange}/> 
                                                                            <span style={{color: 'red' }}>{this.state.errors[ 'name' ]}</span>
                                                                        </div>
                                    
                                                                        <div className="form-group col-md-6">
                                                                            <label for="feFirstName">Phone</label>
                                                                            <input type="text" className="form-control" id="phone" maxLength='10' name="phone" onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, ''); }} placeholder="Phone" value={this.state.fields[ 'phone' ]} onChange={this.handleChange}/> 
                                                                            <span style={{color: 'red'}}>{this.state.errors[ 'phone' ]}</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6">
                                                                            <label for="feEmailAddress">Email</label>
                                                                            <input type="email" className="form-control" id="email" maxLength='35' name="email" placeholder="Email" value={this.state.fields[ 'email' ]} onChange={this.handleChange}/> 
                                                                            <span style={{color: 'red' }}>{this.state.errors[ 'email' ]}</span>
                                                                        </div>
                                                                        <div className="form-group col-md-6">
                                                                            <label for="feFirstName">Image</label>
                                                                            <input type='file' name='image' className='form-control' onChange={ this.onChangeFile } accept='image/*' /> 
                                                                            <span style={ { color : 'red' } }>{this.state.errors[ 'image' ]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6">
                                                                            <label for="fePassword">Password</label>
                                                                            <input type="password" className="form-control" id="password" maxLength='10' name="password" placeholder="Password" value={this.state.fields[ 'password' ]} onChange={this.handleChange}/> 
                                                                            <span style={{color: 'red' }}>{this.state.errors[ 'password' ]}</span>
                                                                        </div>
                                                                        <div className="form-group col-md-6">
                                                                            <label for="fePassword">Confirm Password</label>
                                                                            <input type="password" className="form-control" id="c_password" maxLength='10' name="c_password" placeholder="Confirm Password" value={this.state.fields[ 'c_password' ]} onChange={this.handleChange}/> 
                                                                            <span style={{color: 'red' }}>{this.state.errors[ 'c_password' ]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <button type="submit" className="btn btn-success">Submit</button>&nbsp;
                                                                    <Link to="/users-list" className="btn btn-danger">Cancel</Link>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <ToastContainer />
                            {/* <Footer/> */}
                        </main>
                    </div>
                </div>
            </React.Fragment>   
        );
    }
}

const mapStateToProps = state => ({
    users : state.users,
});

const actionCreators = {
    addUser: addUser,
    uploadUserImage: uploadUserImage,
};

export default connect(mapStateToProps, actionCreators)(AddUser);