import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Header from '../CommonTemplates/Header.js';
import Sidebar from '../CommonTemplates/SideBar.js';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { getCoupon, updateCoupon } from '../../_actions/couponActions.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';
import axios from 'axios';
import { URL_ADMIN_ADD_NOTIFICATION, URL_ADMIN_GET_NOTIFICATION, URL_ADMIN_GET_USERS_LIST, URL_ADMIN_UPDATE_NOTIFICATION } from '../../utils/admin-api-url.js';

class EditNotifications extends Component {
    state = {
        readOnly: true,
        fields: {},
        errors: {},
        expiry_date: '',
        loading: false,
        users: []

    }

    async componentDidMount() {
        const id = this.props.match.params.id;
        try {
            const responseUser = await axios.post(URL_ADMIN_GET_USERS_LIST, {}, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    'x-access-token': reactLocalStorage.getObject('access-token')
                }
            });
            this.setState({
                users: responseUser.data.data.map(item => Object.assign({
                    name: item.name,
                    id: item.id
                }))
            })
            const response = await axios.post(`${URL_ADMIN_GET_NOTIFICATION}/${id}`, {}, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    'x-access-token': reactLocalStorage.getObject('access-token')
                }
            });
            if (response.data.status === true) {
                var couponData = response.data.data
                var fields = this.state.fields;
                Object.keys(couponData)
                    .forEach(function eachKey(key) {
                        fields[key] = couponData[key];
                    });
                this.setState(fields);
            } else {
                toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {

        }
    }
    // Input handle  
    handleChange = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ errors: '' });
        this.setState(fields);
    }

    // Validation 
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields['name']) {
            formIsValid = false;
            errors['name'] = 'Please enter name code.';
        }
        if (!fields['title']) {
            formIsValid = false;
            errors['title'] = 'Please enter title.';
        }
        if (!fields['type']) {
            formIsValid = false;
            errors['type'] = 'Please select type.';
        }
        if (!fields['message']) {
            formIsValid = false;
            errors['message'] = 'Please select message.';
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    //Handle Submit 
    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        if (this.handleValidation()) {
            const response = await axios.post(URL_ADMIN_ADD_NOTIFICATION, this.state.fields, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    'x-access-token': reactLocalStorage.getObject('access-token')
                }
            });
            this.setState({ loading: false })
            if (response.data.status === true) {
                toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
                this.props.history.push('/notifications-list');
            } else {
                toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <Header />
                            <div className="main-content-container container-fluid px-4">
                                <div className="page-header row no-gutters py-4">
                                    <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                                        <span className="text-uppercase page-subtitle">Overview</span>
                                        <h3 className="page-title">Clone Notofication</h3>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card card-small mb-4">
                                            <div className="card-header border-bottom">
                                                <h6 className="m-0">Coupon Details</h6>
                                            </div>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item p-3">
                                                    <div className="row">
                                                        <div className="col">
                                                            <form onSubmit={this.state.loading ? null : this.handleSubmit} enctype='multipart/form-data'>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feCode">Name</label>
                                                                        <input type="text" className="form-control" id="name" name="name" placeholder="Enter Name Code." value={this.state.fields['name']} onChange={this.handleChange} />
                                                                        <span style={{ color: 'red' }}>{this.state.errors['name']}</span>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feAmount">Topic</label>
                                                                        <select id="type" name="type" className="form-control" value={this.state.fields['type']} onChange={this.handleChange}>
                                                                            <option value={""}>Select Topic</option>
                                                                            <option value={'system'}>SYSTEM</option>
                                                                            <option value={'promo'}>PROMO</option>
                                                                            <option value={'silver'}>SILVER</option>
                                                                            <option value={'gold'}>GOLD</option>
                                                                            <option value={'platinum'}>PLATINUM</option>
                                                                        </select>                                                                        <span style={{ color: 'red' }}>{this.state.errors['title']}</span>
                                                                        <span style={{ color: 'red' }}>{this.state.errors['type']}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feValue">Title</label>
                                                                        <input type="text" className="form-control" id="title" name="title" placeholder="Enter Title." value={this.state.fields['title']} onChange={this.handleChange} />
                                                                        <span style={{ color: 'red' }}>{this.state.errors['title']}</span>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feValue">Message</label>
                                                                        <textarea className="form-control" id="message" name="message" maxLength='10' placeholder="Enter Message." value={this.state.fields['message']} onChange={this.handleChange} />
                                                                        {/* <input /> */}
                                                                        <span style={{ color: 'red' }}>{this.state.errors['message']}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feValue">User <small>*optional</small></label>
                                                                        <select id="received_userid" name="received_userid" className="form-control" value={this.state.fields['received_userid']} onChange={this.handleChange}>
                                                                            <option value={0}>Select User</option>
                                                                            {this.state.users.map((item, index) => (
                                                                                <option key={index} value={item.id}>{item.name}</option>
                                                                            ))}
                                                                        </select>                                                                        <span style={{ color: 'red' }}>{this.state.errors['title']}</span>
                                                                    </div>
                                                                </div>
                                                                <button type="submit" className="btn btn-success" disabled={this.state.loading}>{this.state.loading ? 'Please Wait...' : 'Update'}</button>&nbsp;
                                                                <Link to="/notifications-list" className="btn btn-danger">Cancel</Link>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer />
                            {/* <Footer/> */}
                        </main>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default EditNotifications;