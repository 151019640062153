import {
    POST_ADD_TAX_REQUEST, GET_ADD_TAX_SUCCESS, GET_ADD_TAX_FAILURE,
    POST_TAX_REQUEST, GET_TAX_SUCCESS, GET_TAX_FAILURE,
    POST_TAXES_REQUEST, GET_TAXES_SUCCESS, GET_TAXES_FAILURE,
    POST_REMOVE_TAX_REQUEST, GET_REMOVE_TAX_SUCCESS, GET_REMOVE_TAX_FAILURE,
    POST_UPDATE_TAX_REQUEST, GET_UPDATE_TAX_SUCCESS, GET_UPDATE_TAX_FAILURE,
    POST_ACTIVE_TAXES_REQUEST, GET_ACTIVE_TAXES_SUCCESS, GET_ACTIVE_TAXES_FAILURE
} from '../_actions/_type';

export const initialState = {
    taxCreated: false,
    taxUpdated: false,
    taxDeleted: false,
    activeTaxes: [],
    tax: [],
    taxes:[],
    loading: false,
    errors: {},
};
export default function taxes (state = initialState, action) {
    switch (action.type) {
        case POST_UPDATE_TAX_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_UPDATE_TAX_SUCCESS:
            return {
                ...state,
                taxUpdated: true,
                loading: false
            };
        case GET_UPDATE_TAX_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        case POST_REMOVE_TAX_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_REMOVE_TAX_SUCCESS:
            return {
                ...state,
                taxDeleted: true,
                loading: false
            };
        case GET_REMOVE_TAX_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        case POST_TAXES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_TAXES_SUCCESS:
            return {
                ...state,
                taxes: action.payload,
                loading: false
            };
        case GET_TAXES_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        case POST_ADD_TAX_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_ADD_TAX_SUCCESS:
            return {
                ...state,
                taxCreated: true,
                loading: false
            };
        case GET_ADD_TAX_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        case POST_TAX_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_TAX_SUCCESS:
            return {
                ...state,
                tax: action.payload,
                loading: false
            };
        case GET_TAX_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };

        case POST_ACTIVE_TAXES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_ACTIVE_TAXES_SUCCESS:
            return {
                ...state,
                activeTaxes: action.payload,
                loading: false
            };
        case GET_ACTIVE_TAXES_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        default:
            return state;
    }
}
