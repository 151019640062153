import {
    POST_ADMIN_LOGIN_REQUEST, GET_ADMIN_LOGIN_SUCCESS, GET_ADMIN_LOGIN_FAILURE,
    POST_CHANGE_PASSWORD_REQUEST, GET_CHANGE_PASSWORD_SUCCESS, GET_CHANGE_PASSWORD_FAILURE,
    POST_UPLOAD_PHOTO_REQUEST, GET_UPLOAD_PHOTO_SUCCESS, GET_UPLOAD_PHOTO_FAILURE,
    POST_FORGET_PASSWORD_REQUEST, POST_FORGET_PASSWORD_SUCCESS, POST_FORGET_PASSWORD_FAILURE
} from '../_actions/_type';

export const initialState = {
    passwordChanged: true,
    imagePath: '',
    forgetPassword: true,
    loading: false,
    errors: {},
    auth: false
};
export default function auth (state = initialState, action) {
    switch (action.type) {
        case POST_FORGET_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            };
        case POST_FORGET_PASSWORD_SUCCESS:
            return {
                ...state,
                user: action.payload,
                forgetPassword:true,
                loading: false
            };
        case POST_FORGET_PASSWORD_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        case POST_ADMIN_LOGIN_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_ADMIN_LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload,
                loading: false
            };
        case GET_ADMIN_LOGIN_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        case POST_CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordChanged: true,
                loading: false
            };
        case GET_CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        case POST_UPLOAD_PHOTO_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_UPLOAD_PHOTO_SUCCESS:
            return {
                ...state,
                imagePath: action.payload.filepath,
                loading: false
            };
        case GET_UPLOAD_PHOTO_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        default:
            return state;
    }
}
