import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Header from '../CommonTemplates/Header.js';
import Sidebar from '../CommonTemplates/SideBar.js';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { URL_ADMIN_ADD_ARTICLE, URL_ADMIN_ADD_NOTIFICATION, URL_ADMIN_ARTICLE } from '../../utils/admin-api-url.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';
import axios from 'axios';

class AddArticles extends Component {
    state = {
        readOnly: true,
        fields: {},
        errors: {},
        expiry_date: '',
        loading: false
    }

    async componentDidMount() {
    }

    // Input handle  
    handleChange = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ errors: '' });
        this.setState(fields);
    }

    // Validation 
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields['title']) {
            formIsValid = false;
            errors['title'] = 'Please enter title.';
        }
        if (!fields['description']) {
            formIsValid = false;
            errors['description'] = 'Please select message.';
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    // Handle Submit 
    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        if (this.handleValidation()) {
            let formData = new FormData();
            formData.append('images', this.state.images);
            formData.append('title', this.state.fields['title']);
            formData.append('description', this.state.fields['description']);
            const response = await axios.post(URL_ADMIN_ADD_ARTICLE, formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    'x-access-token': reactLocalStorage.getObject('access-token')
                }
            });
            this.setState({ loading: false })

            if (response.data.status === true) {
                toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
                this.props.history.push('/articles-list');
            } else {
                toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT });
            }

        }
    }

    onChangeFile = async (e) => {
        // var file = e.target.files[0];
        // var formData = new FormData();
        // formData.append('user', file.name);
        // formData.append('photo', file);
        // await this.props.uploadUserImage(formData);
        this.setState({ images: e.target.files[0] });
    }

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <Header />
                            <div className="main-content-container container-fluid px-4">
                                <div className="page-header row no-gutters py-4">
                                    <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                                        <span className="text-uppercase page-subtitle">Overview</span>
                                        <h3 className="page-title">Add Articles</h3>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card card-small mb-4">
                                            <div className="card-header border-bottom">
                                                <h6 className="m-0">Articles Details</h6>
                                            </div>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item p-3">
                                                    <div className="row">
                                                        <div className="col">
                                                            <form onSubmit={this.state.loading ? null : this.handleSubmit} enctype='multipart/form-data'>

                                                                <div className="form-row">
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feValue">Title</label>
                                                                        <input type="text" className="form-control" id="title" name="title" placeholder="Enter Title." value={this.state.fields['title']} onChange={this.handleChange} />
                                                                        <span style={{ color: 'red' }}>{this.state.errors['title']}</span>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feValue">description</label>
                                                                        <textarea type="text" className="form-control" id="description" name="description" placeholder="Enter Message." value={this.state.fields['description']} onChange={this.handleChange} />
                                                                        {/* <input type="text" className="form-control" id="description" name="description" placeholder="Enter Message." value={this.state.fields['description']} onChange={this.handleChange} /> */}
                                                                        <span style={{ color: 'red' }}>{this.state.errors['description']}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feFirstName">Image</label>
                                                                        <input type='file' name='image' className='form-control' onChange={this.onChangeFile} accept='image/*' />
                                                                        <span style={{ color: 'red' }}>{this.state.errors['image']}</span>
                                                                    </div>
                                                                </div>
                                                                <button type="submit" disabled={this.state.loading} className="btn btn-success">{this.state.loading ? 'Please Wait...' : 'Submit'}</button>&nbsp;
                                                                <Link to='/coupons-list' className="btn btn-danger">Cancel</Link>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer />
                            {/* <Footer/> */}
                        </main>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default AddArticles;