import {
    POST_ADD_PRODUCT_REQUEST, GET_ADD_PRODUCT_SUCCESS, GET_ADD_PRODUCT_FAILURE,
    POST_PRODUCT_REQUEST, GET_PRODUCT_SUCCESS, GET_PRODUCT_FAILURE,
    POST_REMOVE_PRODUCT_REQUEST, GET_REMOVE_PRODUCT_SUCCESS, GET_REMOVE_PRODUCT_FAILURE,
    POST_PRODUCTS_REQUEST, GET_PRODUCTS_SUCCESS, GET_PRODUCTS_FAILURE,
    POST_ACTIVE_PRODUCTS_REQUEST, GET_ACTIVE_PRODUCTS_SUCCESS, GET_ACTIVE_PRODUCTS_FAILURE,
    POST_UPDATE_PRODUCT_REQUEST, GET_UPDATE_PRODUCT_SUCCESS, GET_UPDATE_PRODUCT_FAILURE,
    POST_PRODUCT_IMAGE_REQUEST, GET_PRODUCT_IMAGE_SUCCESS, GET_PRODUCT_IMAGE_FAILURE,
    POST_POPULAR_PRODUCT_REQUEST, GET_POPULAR_PRODUCT_SUCCESS, GET_POPULAR_PRODUCT_FAILURE,
    POST_REMOVE_PRO_IMG_REQUEST, GET_REMOVE_PRO_IMG_SUCCESS, GET_REMOVE_PRO_IMG_FAILURE
} from '../_actions/_type';
import {
    URL_ADMIN_GET_PRODUCTS_LIST, URL_ADMIN_UPDATE_POPULAR_PRODUCT, URL_ADMIN_DELETE_PRODUCT_IMAGE,
    URL_ADMIN_UPLOAD_PRODUCT_IMAGE, URL_ADMIN_ADD_PRODUCT, URL_ADMIN_DELETE_PRODUCT,
    URL_ADMIN_GET_SINGLE_PRODUCT, URL_ADMIN_UPDATE_PRODUCT, URL_ADMIN_GET_ACTIVE_PRODUCTS
} from '../utils/admin-api-url.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';
import axios from 'axios';

// Get Active Products
export const getActiveProducts = () => async dispatch => {
    try {
        dispatch({ type: POST_ACTIVE_PRODUCTS_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_GET_ACTIVE_PRODUCTS, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_ACTIVE_PRODUCTS_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_ACTIVE_PRODUCTS_FAILURE, payload: error });
    }
};

// Product Update as popular
export const deleteProductImage = (data) => async dispatch => {
    try {
        dispatch({ type: POST_REMOVE_PRO_IMG_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_DELETE_PRODUCT_IMAGE, data, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_REMOVE_PRO_IMG_SUCCESS, payload: response.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_REMOVE_PRO_IMG_FAILURE, payload: error });
    }
};

// Product Update as popular
export const updateProductAsPopular = (data, history) => async dispatch => {
    try {
        dispatch({ type: POST_POPULAR_PRODUCT_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_UPDATE_POPULAR_PRODUCT, data, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_POPULAR_PRODUCT_SUCCESS, payload: response.data });
            history.push('/products-list');
            toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_POPULAR_PRODUCT_FAILURE, payload: error });
    }
};

// Add Product
export const addProduct = (data, history) => async dispatch => {
    try {
        dispatch({ type: POST_ADD_PRODUCT_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_ADD_PRODUCT, data, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_ADD_PRODUCT_SUCCESS, payload: response.data });
            history.push('/products-list');
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_ADD_PRODUCT_FAILURE, payload: error });
    }
};

// Upload Product Image
export const uploadProductImage = (productImage, history) => async dispatch => {
    try {
        dispatch({ type: POST_PRODUCT_IMAGE_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_UPLOAD_PRODUCT_IMAGE, productImage, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_PRODUCT_IMAGE_SUCCESS, payload: response.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_PRODUCT_IMAGE_FAILURE, payload: error });
    }
};

// Get All products list
export const getProducts = () => async dispatch => {
    try {
        dispatch({ type: POST_PRODUCTS_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_GET_PRODUCTS_LIST, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_PRODUCTS_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_PRODUCTS_FAILURE, payload: error });
    }
};

// Get Single Product
export const getProduct = ID => async dispatch => {
    try {
        dispatch({ type: POST_PRODUCT_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_GET_SINGLE_PRODUCT}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_PRODUCT_SUCCESS, payload: response.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_PRODUCT_FAILURE, payload: error });
    }
};

// Delete Product.
export const deleteProduct = ID => async dispatch => {
    try {
        dispatch({ type: POST_REMOVE_PRODUCT_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_DELETE_PRODUCT}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_REMOVE_PRODUCT_SUCCESS, payload: response.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_REMOVE_PRODUCT_FAILURE, payload: error });
    }
};

// Update product.
export const updateProduct = (data, history) => async dispatch => {
    try {
        dispatch({ type: POST_UPDATE_PRODUCT_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_UPDATE_PRODUCT, data, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_UPDATE_PRODUCT_SUCCESS, payload: response.data });
            history.push('/products-list');
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_UPDATE_PRODUCT_FAILURE, payload: error });
    }
};
