import {
    POST_ONBOARDING_ADD_REQUEST, GET_ONBOARDING_ADD_SUCCESS, GET_ONBOARDING_ADD_FAILURE,
    POST_ONBOARDINGS_REQUEST, GET_ONBOARDINGS_SUCCESS, GET_ONBOARDINGS_FAILURE,
    POST_ONBOARDING_REQUEST, GET_ONBOARDING_SUCCESS, GET_ONBOARDING_FAILURE,
    POST_REMOVE_ONBOARDING_REQUEST, GET_REMOVE_ONBOARDING_SUCCESS, GET_REMOVE_ONBOARDING_FAILURE,
    POST_UPDATE_ONBOARDING_REQUEST, GET_UPDATE_ONBOARDING_SUCCESS, GET_UPDATE_ONBOARDING_FAILURE,
    POST_ONBOARDING_IMAGE_REQUEST, GET_ONBOARDING_IMAGE_SUCCESS, GET_ONBOARDING_IMAGE_FAILURE
} from '../_actions/_type';

export const initialState = {
    onboardingCreated: false,
    onboardingUpdated: false,
    onboardingRemoved: false,
    imagePath: '',
    onboarding: [],
    onboardings: [],
    errors: {}
};
export default function onboardings(state = initialState, action) {
    switch (action.type) {
        case POST_ONBOARDING_IMAGE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_ONBOARDING_IMAGE_SUCCESS:
            return {
                ...state,
                imagePath: action.payload.filepath,
                loading: false
            };
        case GET_ONBOARDING_IMAGE_FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.payload
            };
        case POST_ONBOARDING_ADD_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_ONBOARDING_ADD_SUCCESS:
            return {
                ...state,
                onboardingCreated: action.payload,
                loading: false
            };
        case GET_ONBOARDING_ADD_FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.payload
            };
        case POST_ONBOARDINGS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_ONBOARDINGS_SUCCESS:
            return {
                ...state,
                onboardings: action.payload,
                loading: false
            };
        case GET_ONBOARDINGS_FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.payload
            };
        case POST_ONBOARDING_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_ONBOARDING_SUCCESS:
            return {
                ...state,
                onboarding: action.payload,
                loading: false
            };
        case GET_ONBOARDING_FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.payload
            };
        case POST_REMOVE_ONBOARDING_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_REMOVE_ONBOARDING_SUCCESS:
            return {
                ...state,
                onboardingRemoved: true,
                loading: false
            };
        case GET_REMOVE_ONBOARDING_FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.payload
            };
        case POST_UPDATE_ONBOARDING_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_UPDATE_ONBOARDING_SUCCESS:
            return {
                ...state,
                onboardingUpdated: true,
                loading: false
            };
        case GET_UPDATE_ONBOARDING_FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.payload
            };
        default:
            return state;
    }
}
