import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Multiselect } from 'multiselect-react-dropdown';
import Header from '../CommonTemplates/Header.js';
import Sidebar from '../CommonTemplates/SideBar.js';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { getCoupons,submitAssignedCoupons,checkAssignedCoupons,getAllUsers } from '../../_actions/couponActions.js';

class AddUserCoupon extends Component { 
    state = {
        readOnly    : true,
        fields      : {},
        errors      : {},
        expiry_date : '',
    }

    async componentDidMount() {
        await this.props.getAllUsers();
    }  
        
    // Input handle  
    handleChange = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({errors:''}); 
        this.setState(fields);
    }

    onSelect = (event,selectedList, selectedItem) => { 
        this.setState({
            multiSelectCategories:event
        });
        console.log(this.state.multiSelectCategories);
    };
     
    onRemove = (event, selectedList, removedItem) => {
        this.setState({
            multiSelectCategories:event
        });
    }

    getFields = (input, field) => {
        var output = [];
        for (var i=0; i < input.length ; ++i)
            output.push(input[i][field])
           /* if(i > 0 ) {
                output = output + ',' + input[i][field];
            } else {
                output = input[i][field];
            }*/
        return output;
    }

    // Validation 
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if(!this.state.multiSelectCategories){
            formIsValid = false;
            errors[ 'user' ] = 'Please enter user.';
        }
        if(!fields[ 'comment' ]){
            formIsValid = false;
            errors[ 'comment' ] = 'Please enter comment.';
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    // Handle Submit 
    handleSubmit = async (e) => {
        e.preventDefault();
        if(this.handleValidation()){
            var result = this.getFields(this.state.multiSelectCategories, 'id');
            const couponData = {
                user_id : result,
                coupon_id:this.props.match.params.couponId,
                comment : this.state.fields[ 'comment' ],
            };
            await this.props.submitAssignedCoupons(couponData, this.props.history);
        }         
    }
    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar/>
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                                <Header/>
                                <div className="main-content-container container-fluid px-4">
                                    <div className="page-header row no-gutters py-4">
                                        <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                                            <span className="text-uppercase page-subtitle">Overview</span>
                                            <h3 className="page-title">Add Coupon</h3>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card card-small mb-4">
                                                <div className="card-header border-bottom">
                                                    <h6 className="m-0">Coupon Details</h6>
                                                </div>
                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item p-3">
                                                        <div className="row">
                                                            <div className="col">
                                                                <form onSubmit={this.handleSubmit} enctype='multipart/form-data'>
                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6">
                                                                            <label for="feCode">User</label>
                                                                            <Multiselect
                                                                                options={this.props.usersData} 
                                                                                selectedValues={this.state.selectedValue}
                                                                                onSelect={this.onSelect}
                                                                                onRemove={this.onRemove}
                                                                                displayValue="name" 
                                                                            />
                                                                            
                                                                            <span style={{color: 'red' }}>{this.state.errors[ 'code' ]}</span>
                                                                        </div>
                                                                        <div className="form-group col-md-6">
                                                                            <label for="feAmount">Comment</label>
                                                                            <input type="text" className="form-control" id="comment" name="comment" placeholder="Enter your comment." value={this.state.fields[ 'comment' ]} onChange={this.handleChange}/> 
                                                                            <span style={{color: 'red' }}>{this.state.errors[ 'comment' ]}</span>
                                                                        </div>
                                                                    </div>
                                                                   
                                                                    <button type="submit" className="btn btn-success">Submit</button>&nbsp;
                                                                    <Link to='/coupons-list' className="btn btn-danger">Cancel</Link>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            <ToastContainer />
                            {/* <Footer/> */}
                        </main>
                    </div>
                </div>
            </React.Fragment>   
        );
    }
}

const mapStateToProps = state => ({
    usersData:state.coupons.usersData
});
const actionCreators = {
    getAllUsers : getAllUsers,
    submitAssignedCoupons  : submitAssignedCoupons,
};

export default connect(mapStateToProps, actionCreators)(AddUserCoupon);