import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MDBDataTable } from 'mdbreact';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { Fab } from 'react-tiny-fab';
import EditIcon from '@material-ui/icons/Edit';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import BlockIcon from '@material-ui/icons/Block';
import SideBar from '../CommonTemplates/SideBar.js';
import Header from '../CommonTemplates/Header.js';
import Moment from 'moment';
import { getCoupons, deleteCoupon } from '../../_actions/couponActions.js';

export class CouponsList extends Component {
    state = {
        readOnly: true,
        showModal: false,
        id: ''
    }
    async componentDidMount() {
        await this.props.getCoupons();
    }

    handleOpenModal = id => {
        this.setState({ showModal: true, id });
    };

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    handleConfirmDelete = async () => {
        this.setState({ showModal: false });
        await this.props.deleteCoupon(this.state.id);
        await this.props.getCoupons();
    };

    addNew = () => {
        this.props.history.push('add-coupon');
    }

    render() {
        const coupons = this.props.coupons.coupons;
        let displayContent = '';
        let i = 1;
        displayContent = coupons.map(rowInfo => ({
            no: i++,
            code: rowInfo.code,
            type: rowInfo.type,
            value: rowInfo.value,
            member: rowInfo.member,
            expiry_date: Moment(rowInfo.expiry_date).format('DD/MM/YYYY'),
            action:
                <div>
                    <button className="btn btn-danger" onClick={() => this.handleOpenModal(rowInfo.id)}><DeleteRoundedIcon /></button>&nbsp;
                    <Link to={`/edit-coupon/${(rowInfo.id)}`} className="btn btn-warning"><EditIcon /></Link>
                    {Moment(rowInfo.expiry_date).format('YYYY-MM-DD') > Moment(new Date()).format('YYYY-MM-DD') ? <Link to={`/assigned-user-coupon/${(rowInfo.id)}`} className="btn btn-warning"><GroupAddIcon /></Link> : <Link className="btn btn-danger"><BlockIcon /></Link>}

                </div>
        }));

        const data = {
            columns: [
                {
                    label: 'S.No',
                    field: 'no',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Code',
                    field: 'code',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Type',
                    field: 'type',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Value',
                    field: 'value',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Member',
                    field: 'member',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Expiry Date',
                    field: 'expiry_date',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Action',
                    field: 'action',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: displayContent
        };

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <SideBar />
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <Header />
                            <div className="main-content-container container-fluid px-4">
                                <div class="page-header row no-gutters py-4">
                                    <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
                                        <span class="text-uppercase page-subtitle">Overview</span>
                                        <h3 class="page-title">Coupon List</h3>
                                    </div>
                                </div>
                                <Fab
                                    icon="+"
                                    alwaysShowTitle={false}
                                    onClick={this.addNew}
                                >
                                </Fab>
                                <div class="row">
                                    <div class="col">
                                        <div class="card card-small mb-4">
                                            <div class="card-header border-bottom">
                                                <h6 class="m-0">Active Coupons</h6>
                                            </div>
                                            <div class="card-body pb-3 text-center">
                                                <MDBDataTable striped bordered hover data={data} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer />
                            {/* <Footer/> */}
                            <ReactModal isOpen={this.state.showModal} contentLabel="onRequestClose Example" onAfterOpen={this.afterOpenModal}
                                onRequestClose={this.handleCloseModal} shouldCloseOnOverlayClick={false} style={customStyles} >
                                <h3>Are you sure delete this coupon ?</h3>
                                <div className="btn-modal-container">
                                    <ul>
                                        <li>
                                            <button
                                                className="btn btn-sm btn-warning btn-modal-close"
                                                onClick={this.handleCloseModal}
                                            >
                                                Cancel
                                            </button>
                                        </li>
                                        <li>
                                            {' '}
                                            <button
                                                className="btn btn-sm btn-danger btn-modal-confirm"
                                                onClick={this.handleConfirmDelete}
                                            >
                                                Delete
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </ReactModal>
                        </main>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const customStyles = {
    content: {
        color: 'darkred',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    },
    overlay: {
        backgroundColor: '#444',
        opacity: '0.9'
    }
};

const mapStateToProps = state => ({
    coupons: state.coupons,
});
const actionCreators = {
    getCoupons: getCoupons,
    deleteCoupon: deleteCoupon,
};

export default connect(mapStateToProps, actionCreators)(CouponsList);
