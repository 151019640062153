import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Header from '../CommonTemplates/Header.js';
import Sidebar from '../CommonTemplates/SideBar.js';
import 'react-toastify/dist/ReactToastify.css';
import { getCategories, addCategory, uploadCategoryImage, getParentCatList } from '../../_actions/catActions.js';

class AddCategory extends Component {
    state = {
        readOnly: true,
        fields: {},
        errors: {},
        catImage: '',
    }

    async componentDidMount() {
        let id = '0';
        await this.props.getCategories();
        await this.props.getParentCatList(id);
    }

    // Input handle  
    handleChange = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ errors: '' });
        this.setState(fields);
    }

    // Validation 
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields['name']) {
            formIsValid = false;
            errors['name'] = 'Please enter name.';
        } else if (!fields['name'].match(/^[a-zA-Z ]+$/)) {
            formIsValid = false;
            errors['name'] = 'Please enter valid name.';
        }
        if (!fields['description']) {
            formIsValid = false;
            errors['description'] = 'Please enter category description.';
        }
        if (!fields['parent_id']) {
            formIsValid = false;
            errors['parent'] = 'Please select perent category.';
        }
        if (!this.state.catImage) {
            formIsValid = false;
            errors['cat_image'] = 'Please select category image.';
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    // Upload category image
    onChangeFile = async (e) => {
        var file = e.target.files[0];
        var formData = new FormData();
        formData.append('catImage', file.name);
        formData.append('photo', file);
        await this.props.uploadCategoryImage(formData);
        this.setState({ catImage: this.props.categories.imagePath });
    }

    // Handle Submit 
    handleSubmit = async (e) => {
        e.preventDefault();
        if (this.handleValidation()) {
            const catData = {
                name: this.state.fields['name'],
                image: this.state.catImage,
                description: this.state.fields['description'],
                parent_id: this.state.fields['parent_id'],
            };
            await this.props.addCategory(catData, this.props.history);
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <Header />
                            <div className="main-content-container container-fluid px-4">
                                <div className="page-header row no-gutters py-4">
                                    <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                                        <span className="text-uppercase page-subtitle">Overview</span>
                                        <h3 className="page-title">Add Category</h3>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card card-small mb-4">
                                            <div className="card-header border-bottom">
                                                <h6 className="m-0">Category Details</h6>
                                            </div>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item p-3">
                                                    <div className="row">
                                                        <div className="col">
                                                            <form onSubmit={this.handleSubmit} enctype='multipart/form-data'>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feFirstName">Category Name</label>
                                                                        <input type="text" className="form-control" id="name" name="name" maxLength='15' placeholder="Category Name" value={this.state.fields['name']} onChange={this.handleChange} />
                                                                        <span style={{ color: 'red' }}>{this.state.errors['name']}</span>
                                                                    </div>

                                                                    <div className="form-group col-md-6">
                                                                        <label for="feFirstName">Image</label>
                                                                        <input type='file' name='catImage' id="catImage" className='form-control' onChange={this.onChangeFile} accept='image/*' />
                                                                        <span style={{ color: 'red' }}>{this.state.errors['cat_image']}</span>
                                                                    </div>
                                                                </div>

                                                                <div className="form-row">
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feDescription">Description</label>
                                                                        <textarea className="form-control" name="description" onChange={this.handleChange} id="description" value={this.state.fields['description']} rows="5"></textarea>
                                                                        <span style={{ color: 'red' }}>{this.state.errors['description']}</span>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feLastName">Parent Category</label>
                                                                        <select id="category" name="parent_id" className="form-control" value={this.state.fields['parent_id']} onChange={this.handleChange}>
                                                                            <option value=''>Select Parent Category</option>
                                                                            <option value='0'>This is Parent Category</option>
                                                                            {this.props.parentCats.map((item, index) => (
                                                                                <option key={index} value={item.id}>{item.name}</option>
                                                                            ))}
                                                                        </select>
                                                                        <span style={{ color: 'red' }}>{this.state.errors['parent']}</span>
                                                                    </div>
                                                                </div>
                                                                <button type="submit" className="btn btn-success">Submit</button>&nbsp;
                                                                <Link to="/categories-list" className="btn btn-danger">Cancel</Link>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer />
                            {/* <Footer/> */}
                        </main>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    categories: state.categories,
    parentCats: state.categories.parentCats
});
const actionCreators = {
    addCategory: addCategory,
    getCategories: getCategories,
    uploadCategoryImage: uploadCategoryImage,
    getParentCatList: getParentCatList
};

export default connect(mapStateToProps, actionCreators)(AddCategory);