import {
  URL_ADMIN_ADD_PANNEL,
  URL_ADMIN_DELETE_PANNEL,
  URL_ADMIN_GET_PANNEL,
} from "../utils/admin-api-url";
import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";
import { toast } from "react-toastify";

export const addPannel = async (panelData, history) => {
  try {
    const response = await axios.post(`${URL_ADMIN_ADD_PANNEL}`, panelData, {
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        Authorization: reactLocalStorage.getObject("access-token"),
      },
    });

    if (response.data.status === true) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      history.push("/panel");
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
  } catch (error) {
    console.log(error);
  }
};

export const deletePanel = async (panelData, history) => {
  try {
    const response = await axios.post(`${URL_ADMIN_DELETE_PANNEL}`, panelData, {
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        Authorization: reactLocalStorage.getObject("access-token"),
      },
    });
    if (response.data.status === true) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      history.push("/panel");
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
  } catch (error) {
    console.log(error);
  }
};

export async function getPanel() {
  try {
    const response = await axios.post(
      `${URL_ADMIN_GET_PANNEL}`,
      {},
      {
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: reactLocalStorage.getObject("access-token"),
        },
      }
    );

    if (response.data.status === true) {
      this.setState({
        listPanel: response.data.data || [],
      });
    } else {
      this.setState({
        listPanel: [],
      });
    }
    console.log(this.state.listPanel);
  } catch (error) {
    console.log(error);
  }
}
