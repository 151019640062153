import {
    POST_UNIT_ADD_REQUEST, GET_UNIT_ADD_SUCCESS, GET_UNIT_ADD_FAILURE,
    POST_UNITS_REQUEST, GET_UNITS_SUCCESS, GET_UNITS_FAILURE,
    POST_UNIT_REQUEST, GET_UNIT_SUCCESS, GET_UNIT_FAILURE,
    POST_REMOVE_UNIT_REQUEST, GET_REMOVE_UNIT_SUCCESS, GET_REMOVE_UNIT_FAILURE,
    POST_UPDATE_UNIT_REQUEST, GET_UPDATE_UNIT_SUCCESS, GET_UPDATE_UNIT_FAILURE,
    POST_ACTIVE_UNITS_REQUEST, GET_ACTIVE_UNITS_SUCCESS, GET_ACTIVE_UNITS_FAILURE,
} from '../_actions/_type';

export const initialState = {
    unitCreated : false,
    unitUpdated : false,
    unitDeleted : false,
    units       : [],
    activeUnits : [],
    unit        : [],
    loading     : false,
    errors      : {},
};
export default function units (state = initialState, action) {
    switch (action.type) {
        case POST_UNIT_ADD_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_UNIT_ADD_SUCCESS:
            return {
                ...state,
                unitCreated     : true,
                loading         : false
            };
        case GET_UNIT_ADD_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_UNITS_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_UNITS_SUCCESS:
            return {
                ...state,
                units           : action.payload,
                loading         : false
            };
        case GET_UNITS_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_UNIT_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_UNIT_SUCCESS:
            return {
                ...state,
                unit            : action.payload,
                loading         : false
            };
        case GET_UNIT_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_UPDATE_UNIT_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_UPDATE_UNIT_SUCCESS:
            return {
                ...state,
                unitUpdated    : true,
                loading         : false
            };
        case GET_UPDATE_UNIT_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_REMOVE_UNIT_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_REMOVE_UNIT_SUCCESS:
            return {
                ...state,
                unitDeleted     : true,
                loading         : false
            };
        case GET_REMOVE_UNIT_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_ACTIVE_UNITS_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_ACTIVE_UNITS_SUCCESS:
            return {
                ...state,
                activeUnits     : action.payload,
                loading         : false
            };
        case GET_ACTIVE_UNITS_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        default:
            return state;
    }
}
