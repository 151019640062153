import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { reactLocalStorage } from 'reactjs-localstorage';
import Header from '../CommonTemplates/Header.js';
import Sidebar from '../CommonTemplates/SideBar.js';
import { getUser } from '../../_actions/userActions.js';
import { changePassword } from '../../_actions/authActions.js';

class ChangePassword extends Component { 
    state = { 
        fields : {},
        errors : {}
    }

    async componentDidMount() {
        const id = (reactLocalStorage.getObject('Auth').id);
        await this.props.getUser(id);
        var userdata = this.props.users.user[0];
        var fields = this.state.fields;
        Object.keys(userdata)
            .forEach(function eachKey(key) {               
                fields[key] = userdata[key];                   
            });
        this.setState(fields);
    }

    handleChange = (e) => { 
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value; 
        this.setState(fields);
    }

    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields[ 'oldPassword' ]) {
            formIsValid = false;
            errors[ 'oldPassword' ] = 'Please enter old password.';
        }
        if (!fields[ 'newPassword' ]) {
            formIsValid = false;
            errors[ 'newPassword' ] = 'Please enter new password.';
        }
        if (!fields[ 'confirmPassword' ]) {
            formIsValid = false;
            errors[ 'confirmPassword' ] = 'Please enter confirm password.';
        }
        if (fields[ 'confirmPassword' ] !== fields[ 'newPassword' ]){
            formIsValid = false;
            errors[ 'confirmPassword' ] = 'Confirm password not matched to new password.';
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    // Handle Fom Submit 
    handleSubmit = async (e) => {
        e.preventDefault();
        if(this.handleValidation()){
            const userData = {
                user_id         : this.state.fields[ 'id' ],
                oldPassword     : this.state.fields[ 'oldPassword' ],
                newPassword     : this.state.fields[ 'newPassword' ],
            };
            await this.props.changePassword(userData, this.props.history);
        }               
    }

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar/>
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                                <Header/>
                                <div className="main-content-container container-fluid px-4">
                                    <div className="page-header row no-gutters py-4">
                                        <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                                            <h3 className="page-title">User Profile</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="card card-small mb-4">
                                                <div className="card-header border-bottom">
                                                    <h6 className="m-0">Change Password</h6>
                                                </div>
                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item p-3">
                                                        <div className="row">
                                                            <div className="col">
                                                                <form onSubmit={this.handleSubmit}>
                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6">
                                                                            <label for="feOldPassword">Old Password</label>
                                                                            <input type="password" className="form-control" id="oldPassword" name="oldPassword" maxLength='10' placeholder="Old Password" value={this.state.fields[ 'oldPassword' ]} onChange={this.handleChange}/> 
                                                                            <span style={ { color : 'red' } }>{this.state.errors[ 'oldPassword' ]}</span>
                                                                        </div>
                                                                        <div className="form-group col-md-6">
                                                                            <label for="fePhone">New Password</label>
                                                                            <input type="password" className="form-control" id="phone" maxLength='10' name="newPassword" placeholder="New Passord" value={this.state.fields[ 'newPassword' ]} onChange={this.handleChange}/> 
                                                                            <span style={ { color : 'red' } }>{this.state.errors[ 'newPassword' ]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-12">
                                                                            <label for="feEmailAddress">Confirm Password</label>
                                                                            <input type="password" className="form-control" id="confirmPassword" name="confirmPassword" placeholder="Confirm Password" value={this.state.fields[ 'confirmPassword' ]} onChange={this.handleChange}/> 
                                                                            <span style={ { color : 'red' } }>{this.state.errors[ 'confirmPassword' ]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <button type="submit" className="btn btn-primary">Change</button>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <ToastContainer />     
                        </main>
                    </div>
                </div>
            </React.Fragment>   
        );
    }
}

const mapStateToProps = state => ({
    users   : state.users,
});
const actionCreators = {
    getUser         : getUser,
    changePassword  : changePassword,
};
export default connect(mapStateToProps, actionCreators )(ChangePassword);
