import {
    POST_PROD_VARIANT_ADD_REQUEST, GET_PROD_VARIANT_ADD_SUCCESS, GET_PROD_VARIANT_ADD_FAILURE,
    POST_PROD_VARIANTS_REQUEST, GET_PROD_VARIANTS_SUCCESS, GET_PROD_VARIANTS_FAILURE,
    POST_PROD_VARIANT_REQUEST, GET_PROD_VARIANT_SUCCESS, GET_PROD_VARIANT_FAILURE,
    POST_REMOVE_PROD_VARIANT_REQUEST, GET_REMOVE_PROD_VARIANT_SUCCESS, GET_REMOVE_PROD_VARIANT_FAILURE,
    POST_UPDATE_PROD_VARIANT_REQUEST, GET_UPDATE_PROD_VARIANT_SUCCESS, GET_UPDATE_PROD_VARIANT_FAILURE
} from '../_actions/_type';
import {
    URL_ADMIN_ADD_PRODUCT_VARIANT, URL_ADMIN_GET_SINGLE_PRODUCT_VARIANT, URL_ADMIN_GET_PRODUCT_VARIANTS_LIST,
    URL_ADMIN_UPDATE_PRODUCT_VARIANT, URL_ADMIN_REMOVE_PRODUCT_VARIANT
} from '../utils/admin-api-url.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';
import axios from 'axios';

// Add product variant
export const addProductVariant = (data, history) => async dispatch => {
    try {
        dispatch({ type: POST_PROD_VARIANT_ADD_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_ADD_PRODUCT_VARIANT, data, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_PROD_VARIANT_ADD_SUCCESS, payload: response.data });
            history.push('/variants-list');
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_PROD_VARIANT_ADD_FAILURE, payload: error });
    }
};

// Get All product variants list
export const getProductVariants = () => async dispatch => {
    try {
        dispatch({ type: POST_PROD_VARIANTS_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_GET_PRODUCT_VARIANTS_LIST, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_PROD_VARIANTS_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_PROD_VARIANTS_FAILURE, payload: error });
    }
};

// Get Single product variant
export const getProductVariant = ID => async dispatch => {
    try {
        dispatch({ type: POST_PROD_VARIANT_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_GET_SINGLE_PRODUCT_VARIANT}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_PROD_VARIANT_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_PROD_VARIANT_FAILURE, payload: error });
    }
};

// Update product variant
export const updateProductVariant = (data, history) => async dispatch => {
    try {
        dispatch({ type: POST_UPDATE_PROD_VARIANT_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_UPDATE_PRODUCT_VARIANT, data, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_UPDATE_PROD_VARIANT_SUCCESS, payload: response.data });
            history.push('/variants-list');
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_UPDATE_PROD_VARIANT_FAILURE, payload: error });
    }
};

// Delete product variant.
export const deleteProductVariant = ID => async dispatch => {
    try {
        dispatch({ type: POST_REMOVE_PROD_VARIANT_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_REMOVE_PRODUCT_VARIANT}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_REMOVE_PROD_VARIANT_SUCCESS, payload: response.data });
            toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_REMOVE_PROD_VARIANT_FAILURE, payload: error });
    }
};
