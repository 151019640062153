import {
    POST_BANNER_ADD_REQUEST, GET_BANNER_ADD_SUCCESS, GET_BANNER_ADD_FAILURE,
    POST_BANNERS_REQUEST, GET_BANNERS_SUCCESS, GET_BANNERS_FAILURE,
    POST_BANNER_REQUEST, GET_BANNER_SUCCESS, GET_BANNER_FAILURE,
    POST_REMOVE_BANNER_REQUEST, GET_REMOVE_BANNER_SUCCESS, GET_REMOVE_BANNER_FAILURE,
    POST_UPDATE_BANNER_REQUEST, GET_UPDATE_BANNER_SUCCESS, GET_UPDATE_BANNER_FAILURE,
    POST_BANNER_IMAGE_REQUEST, GET_BANNER_IMAGE_SUCCESS, GET_BANNER_IMAGE_FAILURE
} from '../_actions/_type';

export const initialState = {
    bannerCreated: false,
    bannerUpdated: false,
    bannerRemoved: false,
    imagePath: '',
    banner: [],
    banners: [],
    errors: {}
};
export default function banners (state = initialState, action) {
    switch (action.type) {
        case POST_BANNER_IMAGE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_BANNER_IMAGE_SUCCESS:
            return {
                ...state,
                imagePath: action.payload.filepath,
                loading: false
            };
        case GET_BANNER_IMAGE_FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.payload
            };
        case POST_BANNER_ADD_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_BANNER_ADD_SUCCESS:
            return {
                ...state,
                bannerCreated: action.payload,
                loading: false
            };
        case GET_BANNER_ADD_FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.payload
            };
        case POST_BANNERS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_BANNERS_SUCCESS:
            return {
                ...state,
                banners: action.payload,
                loading: false
            };
        case GET_BANNERS_FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.payload
            };
        case POST_BANNER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_BANNER_SUCCESS:
            return {
                ...state,
                banner: action.payload,
                loading: false
            };
        case GET_BANNER_FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.payload
            };
        case POST_REMOVE_BANNER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_REMOVE_BANNER_SUCCESS:
            return {
                ...state,
                bannerRemoved: true,
                loading: false
            };
        case GET_REMOVE_BANNER_FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.payload
            };
        case POST_UPDATE_BANNER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_UPDATE_BANNER_SUCCESS:
            return {
                ...state,
                bannerUpdated: true,
                loading: false
            };
        case GET_UPDATE_BANNER_FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.payload
            };
        default:
            return state;
    }
}
