import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Header from '../CommonTemplates/Header.js';
import Sidebar from '../CommonTemplates/SideBar.js';
import { getUser, updateUser, uploadUserImage } from '../../_actions/userActions.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { API_URL, BASEASSETURL, URL_ADMIN_GET_ORDER, } from '../../utils/admin-api-url.js';
import axios from 'axios';
import Moment from 'moment';
import currency from '../../utils/currency.js';

class DetailOrder extends Component {
    state = {
        readOnly: true,
        fields: {},
        errors: {},
        form_data: {},
        userImage: '',
        detailProduct: {}
    }

    async componentDidMount() {
        const id = this.props.match.params.id;
        try {
            const response = await axios.post(`${URL_ADMIN_GET_ORDER}/${id}`, {}, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    'x-access-token': reactLocalStorage.getObject('access-token')
                }
            });
            if (response.data.status === true) {
                this.setState({
                    detailProduct: response.data.data || {}
                })
            } else {
                this.setState({
                    detailProduct: {}
                })
            }
        } catch (error) {
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <Header />
                            <div className="main-content-container container-fluid px-4">
                                <div className="page-header row no-gutters py-4">
                                    <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                                        <span className="text-uppercase page-subtitle">Overview</span>
                                        <h3 className="page-title">Detail Order</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card card-small mb-4">
                                            <div className="card-header border-bottom">
                                                <h6 className="m-0">Order Details</h6>
                                            </div>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <p class="mb-0">No Invoice</p>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <p class="text-muted mb-0">{this.state.detailProduct?.no_invoice}</p>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <p class="mb-0">Resi Number</p>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <p class="text-muted mb-0">{this.state.detailProduct?.no_resi || '--'}</p>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <p class="mb-0">Total Gross</p>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <p class="text-muted mb-0">IDR {currency(this.state.detailProduct?.gross_amount)}</p>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <p class="mb-0">Payment Fee</p>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <p class="text-muted mb-0"> IDR {currency(this.state.detailProduct?.payment_fee)}</p>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <p class="mb-0">Payment Status</p>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <p class="text-muted mb-0">{this.state.detailProduct?.payment_status}</p>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <p class="mb-0">Payment Type</p>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <p class="text-muted mb-0">{this.state.detailProduct?.payment_type}</p>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <p class="mb-0">Shipping Charge</p>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <p class="text-muted mb-0"> IDR {currency(this.state.detailProduct?.shipping_charge)} { }</p>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <p class="mb-0">Shipping Courier</p>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <p class="text-muted mb-0"> {this.state.detailProduct?.shipping_kurir}</p>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <p class="mb-0">Shipping Name</p>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <p class="text-muted mb-0"> {this.state.detailProduct?.shipping_name}</p>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <p class="mb-0">Shipping Phone</p>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <p class="text-muted mb-0"> {this.state.detailProduct?.shipping_phone}</p>
                                                    </div>
                                                </div>
                                                <hr></hr>

                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <p class="mb-0">Shipping Address</p>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <p class="text-muted mb-0">{this.state.detailProduct?.shipping_address}</p>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <p class="mb-0">Status</p>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <p class="text-muted mb-0">{this.state.detailProduct?.status}</p>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <p class="mb-0">Tax</p>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <p class="text-muted mb-0">{currency(this.state.detailProduct?.total_tax)}</p>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <p class="mb-0">Sub Total</p>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <p class="text-muted mb-0">IDR {currency(this.state.detailProduct?.sub_total)}</p>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <p class="mb-0">Total</p>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <p class="text-muted mb-0"> IDR {currency(this.state.detailProduct?.total)}</p>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <p class="mb-0">Product Order</p>
                                                    </div>
                                                    <div class="col-sm-9 ">

                                                        <div style={{ overflow: "auto" }} className='w-100'>
                                                            <table className='table'>
                                                                <thead className='thead-dark'>
                                                                    <tr>
                                                                        <th scope="col">#</th>
                                                                        <th scope="col">Image</th>
                                                                        <th scope="col">Name</th>
                                                                        <th scope="col">Brand</th>
                                                                        <th scope="col">Price</th>
                                                                        <th scope="col">Quantity</th>
                                                                        <th scope="col">Weight</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.detailProduct.product_details?.map((item, idx) => (
                                                                        <tr>
                                                                            <th scope="row">{idx + 1}</th>
                                                                            <td><img src={BASEASSETURL + item.image} className='mb-3' height='50' width='50' alt='' /></td>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.brand}</td>
                                                                            <td>{currency(item.price)}</td>
                                                                            <td>{item.quantity}</td>
                                                                            <td>{item.weight}  {item.unit}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <Footer/> */}
                        </main>
                    </div>
                </div>
                <ToastContainer />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user: state.users.user,
    imagePath: state.users.imagePath,
});
const actionCreators = {
    getUser: getUser,
    updateUser: updateUser,
    uploadUserImage: uploadUserImage,
};

export default connect(mapStateToProps, actionCreators)(DetailOrder);
