import {
    POST_ADD_CATEGORY_REQUEST, GET_ADD_CATEGORY_SUCCESS, GET_ADD_CATEGORY_FAILURE,
    POST_CATEGORIES_REQUEST, GET_CATEGORIES_SUCCESS, GET_CATEGORIES_FAILURE,
    POST_CATEGORY_DELETE_REQUEST, GET_CATEGORY_DELETE_SUCCESS, GET_CATEGORY_DELETE_FAILURE,
    POST_CATEGORY_GET_REQUEST, GET_CATEGORY_GET_SUCCESS, GET_CATEGORY_GET_FAILURE,
    POST_UPDATE_CATEGORY_REQUEST, GET_UPDATE_CATEGORY_SUCCESS, GET_UPDATE_CATEGORY_FAILURE,
    POST_CATEGORY_IMAGE_REQUEST, GET_CATEGORY_IMAGE_SUCCESS, GET_CATEGORY_IMAGE_FAILURE,
    POST_PARENT_CATEGORIES_REQUEST, GET_PARENT_CATEGORIES_SUCCESS, GET_PARENT_CATEGORIES_FAILURE,
    POST_SUB_CATEGORIES_REQUEST, GET_SUB_CATEGORIES_SUCCESS, GET_SUB_CATEGORIES_FAILURE
} from '../_actions/_type';
import {
    URL_ADMIN_GET_CATEGORIES_LIST, URL_ADMIN_GET_SUB_CATS_LIST, URL_ADMIN_GET_PARENT_CAT_LIST,
    URL_ADMIN_ADD_CATEGORY, URL_ADMIN_DELETE_CATEGORY, URL_ADMIN_GET_SINGLE_CATEGORY,
    URL_ADMIN_UPDATE_CATEGORY, URL_ADMIN_UPLOAD_CATEGORY_IMAGE
} from '../utils/admin-api-url.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';
import axios from 'axios';

// Get sub categories
export const getSubCatList = () => async dispatch => {
    try {
        dispatch({ type: POST_SUB_CATEGORIES_REQUEST, payload: true });
        const responseCat = await axios.post(URL_ADMIN_GET_SUB_CATS_LIST, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (responseCat.data.status === true) {
            dispatch({ type: GET_SUB_CATEGORIES_SUCCESS, payload: responseCat.data.data });
        } else {
            toast.error(responseCat.message, { position: toast.POSITION.TOP_RIGHT });
        }
    } catch (error) {
        dispatch({ type: GET_SUB_CATEGORIES_FAILURE, payload: error });
    }
};

// Get parent categories.
export const getParentCatList = (Id) => async dispatch => {
    try {
        dispatch({ type: POST_PARENT_CATEGORIES_REQUEST, payload: true });
        const responseCat = await axios.post(`${URL_ADMIN_GET_PARENT_CAT_LIST}/${Id}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (responseCat.data.status === true) {
            dispatch({ type: GET_PARENT_CATEGORIES_SUCCESS, payload: responseCat.data.data });
        } else {
            toast.error(responseCat.message, { position: toast.POSITION.TOP_RIGHT });
        }
    } catch (error) {
        dispatch({ type: GET_PARENT_CATEGORIES_FAILURE, payload: error });
    }
};

// Upload category Image
export const uploadCategoryImage = (catImage) => async dispatch => {
    try {
        dispatch({ type: POST_CATEGORY_IMAGE_REQUEST, payload: true });
        const responseImage = await axios.post(URL_ADMIN_UPLOAD_CATEGORY_IMAGE, catImage, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (responseImage.data.status === true) {
            dispatch({ type: GET_CATEGORY_IMAGE_SUCCESS, payload: responseImage.data });
        } else {
            toast.error(responseImage.data.message, { position: toast.POSITION.TOP_RIGHT });
        }
    } catch (error) {
        dispatch({ type: GET_CATEGORY_IMAGE_FAILURE, payload: error });
    }
};

// Update Category
export const addCategory = (catData, history) => async dispatch => {
    try {
        dispatch({ type: POST_ADD_CATEGORY_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_ADD_CATEGORY, catData, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_ADD_CATEGORY_SUCCESS, payload: response.data });
            history.push('/categories-list');
            toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_ADD_CATEGORY_FAILURE, payload: error });
    }
};

// Get All users list
export const getCategories = () => async dispatch => {
    try {
        dispatch({ type: POST_CATEGORIES_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_GET_CATEGORIES_LIST, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_CATEGORIES_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            // //localStorage.clear('Auth')
            // //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_CATEGORIES_FAILURE, payload: error });
    }
};

// Delete Category
export const deleteCategory = ID => async dispatch => {
    try {
        dispatch({ type: POST_CATEGORY_DELETE_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_DELETE_CATEGORY}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_CATEGORY_DELETE_SUCCESS, payload: response.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_CATEGORY_DELETE_FAILURE, payload: error });
    }
};

// Get Single Category.
export const getCategory = ID => async dispatch => {
    try {
        dispatch({ type: POST_CATEGORY_GET_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_GET_SINGLE_CATEGORY}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_CATEGORY_GET_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_CATEGORY_GET_FAILURE, payload: error });
    }
};

// Update Category
export const updateCategory = (catData, history) => async dispatch => {
    try {
        dispatch({ type: POST_UPDATE_CATEGORY_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_UPDATE_CATEGORY, catData, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_UPDATE_CATEGORY_SUCCESS, payload: response.data });
            history.push('/categories-list');
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_UPDATE_CATEGORY_FAILURE, payload: error });
    }
};
