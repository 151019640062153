import {
    POST_ORDERS_REQUEST, GET_ORDERS_SUCCESS, GET_ORDERS_FAILURE,
    POST_ORDER_REQUEST, GET_ORDER_SUCCESS, GET_ORDER_FAILURE,
    POST_REMOVE_ORDER_REQUEST, GET_REMOVE_ORDER_SUCCESS, GET_REMOVE_ORDER_FAILURE,
    POST_UPDATE_ORDER_REQUEST, GET_UPDATE_ORDER_SUCCESS, GET_UPDATE_ORDER_FAILURE
} from '../_actions/_type';

export const initialState = {
    orderUpdated: false,
    orderDeleted: false,
    orders: [],
    order: [],
    loading: false,
    errors: {},
};
export default function orders(state = initialState, action) {
    switch (action.type) {
        case POST_ORDERS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_ORDERS_SUCCESS:
            return {
                ...state,
                orders: action.payload,
                loading: false
            };
        case GET_ORDERS_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        case POST_ORDER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_ORDER_SUCCESS:
            return {
                ...state,
                order: action.payload,
                loading: false
            };
        case GET_ORDER_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        case POST_UPDATE_ORDER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_UPDATE_ORDER_SUCCESS:
            return {
                ...state,
                orderUpdated: true,
                loading: false
            };
        case GET_UPDATE_ORDER_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        case POST_REMOVE_ORDER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_REMOVE_ORDER_SUCCESS:
            return {
                ...state,
                orderDeleted: true,
                loading: false
            };
        case GET_REMOVE_ORDER_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        default:
            return state;
    }
}
