import {
    POST_ADMIN_LOGIN_REQUEST, GET_ADMIN_LOGIN_SUCCESS, GET_ADMIN_LOGIN_FAILURE,
    POST_CHANGE_PASSWORD_REQUEST, GET_CHANGE_PASSWORD_SUCCESS, GET_CHANGE_PASSWORD_FAILURE,
    POST_UPLOAD_PHOTO_REQUEST, GET_UPLOAD_PHOTO_SUCCESS, GET_UPLOAD_PHOTO_FAILURE,
    POST_FORGET_PASSWORD_REQUEST, POST_FORGET_PASSWORD_SUCCESS, POST_FORGET_PASSWORD_FAILURE
} from './_type';
import {
    URL_ADMIN_LOGIN, URL_ADMIN_CHANGE_USER_PASSWORD, URL_ADMIN_UPLOAD_USER_PHOTO, URL_ADMIN_RESET_PASSWORD
} from '../utils/admin-api-url.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';
import axios from 'axios';

// Admin User login
export const forgetPassword = (userData, history) => async dispatch => {
    try {
        dispatch({ type: POST_FORGET_PASSWORD_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_RESET_PASSWORD, userData, {
            headers: { 'Content-Type': 'application/json' }
        });
        if (response.data.status === true) {
            dispatch({ type: POST_FORGET_PASSWORD_SUCCESS, payload: response.data });
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        }
    } catch (error) {
        dispatch({ type: POST_FORGET_PASSWORD_FAILURE, payload: error });
    }
};

// Admin User login
export const login = (userData, history) => async dispatch => {
    try {
        dispatch({ type: POST_ADMIN_LOGIN_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_LOGIN, userData, {
            headers: { 'Content-Type': 'application/json' }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_ADMIN_LOGIN_SUCCESS, payload: response.data });
            reactLocalStorage.setObject('access-token', response.data.token);
            reactLocalStorage.setObject('Auth', response.data.data);
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
            history.push('/dashboard');
        } else {
            toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        }
    } catch (error) {
        dispatch({ type: GET_ADMIN_LOGIN_FAILURE, payload: error });
    }
};

// Upload User Image
export const uploadUserImage = (userImage) => async dispatch => {
    try {
        dispatch({ type: POST_UPLOAD_PHOTO_REQUEST, payload: true });
        const responseImage = await axios.post(URL_ADMIN_UPLOAD_USER_PHOTO, userImage, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (responseImage.data.status === true) {
            dispatch({ type: GET_UPLOAD_PHOTO_SUCCESS, payload: responseImage.data });
        } else {
            toast.error(responseImage.data.message, { position: toast.POSITION.TOP_RIGHT });
        }
    } catch (error) {
        dispatch({ type: GET_UPLOAD_PHOTO_FAILURE, payload: error });
    }
};

// Change Password.
export const changePassword = (data, history) => async dispatch => {
    try {
        dispatch({ type: POST_CHANGE_PASSWORD_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_CHANGE_USER_PASSWORD, data, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_CHANGE_PASSWORD_SUCCESS, payload: response.data });
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
            localStorage.removeItem('access-token');
            localStorage.removeItem('Auth');
            history.push('/');
        } else {
            toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT });
            
        }
    } catch (error) {
        dispatch({ type: GET_CHANGE_PASSWORD_FAILURE, payload: error });
    }
};
