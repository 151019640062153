import {
    POST_SHIPPING_CHARGE_ADD_REQUEST, GET_SHIPPING_CHARGE_ADD_SUCCESS, GET_SHIPPING_CHARGE_ADD_FAILURE,
    POST_SHIPPING_CHARGES_REQUEST, GET_SHIPPING_CHARGES_SUCCESS, GET_SHIPPING_CHARGES_FAILURE,
    POST_SHIPPING_CHARGE_REQUEST, GET_SHIPPING_CHARGE_SUCCESS, GET_SHIPPING_CHARGE_FAILURE,
    POST_REMOVE_SHIPPING_CHARGE_REQUEST, GET_REMOVE_SHIPPING_CHARGE_SUCCESS, GET_REMOVE_SHIPPING_CHARGE_FAILURE,
    POST_UPDATE_SHIPPING_CHARGE_REQUEST, GET_UPDATE_SHIPPING_CHARGE_SUCCESS, GET_UPDATE_SHIPPING_CHARGE_FAILURE
} from '../_actions/_type';

export const initialState = {
    shippingCreated : false,
    shippingUpdated : false,
    shippingDeleted : false,
    shippings : [],
    activeshippings : [],
    shipping : [],
    loading : false,
    errors : {},
};
export default function shippings (state = initialState, action) {
    switch (action.type) {
        case POST_SHIPPING_CHARGE_ADD_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_SHIPPING_CHARGE_ADD_SUCCESS:
            return {
                ...state,
                shippingCreated : true,
                loading         : false
            };
        case GET_SHIPPING_CHARGE_ADD_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_SHIPPING_CHARGES_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_SHIPPING_CHARGES_SUCCESS:
            return {
                ...state,
                shippings       : action.payload,
                loading         : false
            };
        case GET_SHIPPING_CHARGES_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_SHIPPING_CHARGE_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_SHIPPING_CHARGE_SUCCESS:
            return {
                ...state,
                shipping        : action.payload,
                loading         : false
            };
        case GET_SHIPPING_CHARGE_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_UPDATE_SHIPPING_CHARGE_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_UPDATE_SHIPPING_CHARGE_SUCCESS:
            return {
                ...state,
                shippingUpdated    : true,
                loading         : false
            };
        case GET_UPDATE_SHIPPING_CHARGE_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_REMOVE_SHIPPING_CHARGE_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_REMOVE_SHIPPING_CHARGE_SUCCESS:
            return {
                ...state,
                shippingDeleted     : true,
                loading         : false
            };
        case GET_REMOVE_SHIPPING_CHARGE_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        default:
            return state;
    }
}
