import {
    POST_PROD_VARIANT_ADD_REQUEST, GET_PROD_VARIANT_ADD_SUCCESS, GET_PROD_VARIANT_ADD_FAILURE,
    POST_PROD_VARIANTS_REQUEST, GET_PROD_VARIANTS_SUCCESS, GET_PROD_VARIANTS_FAILURE,
    POST_PROD_VARIANT_REQUEST, GET_PROD_VARIANT_SUCCESS, GET_PROD_VARIANT_FAILURE,
    POST_REMOVE_PROD_VARIANT_REQUEST, GET_REMOVE_PROD_VARIANT_SUCCESS, GET_REMOVE_PROD_VARIANT_FAILURE,
    POST_UPDATE_PROD_VARIANT_REQUEST, GET_UPDATE_PROD_VARIANT_SUCCESS, GET_UPDATE_PROD_VARIANT_FAILURE,
} from '../_actions/_type';

export const initialState = {
    variantCreated : false,
    variantUpdated : false,
    variantDeleted : false,
    variants       : [],
    variant        : [],
    loading        : false,
    errors         : {},
};
export default function productVariants (state = initialState, action) {
    switch (action.type) {
        case POST_PROD_VARIANT_ADD_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_PROD_VARIANT_ADD_SUCCESS:
            return {
                ...state,
                variantCreated  : true,
                loading         : false
            };
        case GET_PROD_VARIANT_ADD_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_PROD_VARIANTS_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_PROD_VARIANTS_SUCCESS:
            return {
                ...state,
                variants        : action.payload,
                loading         : false
            };
        case GET_PROD_VARIANTS_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_PROD_VARIANT_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_PROD_VARIANT_SUCCESS:
            return {
                ...state,
                variant         : action.payload,
                loading         : false
            };
        case GET_PROD_VARIANT_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_UPDATE_PROD_VARIANT_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_UPDATE_PROD_VARIANT_SUCCESS:
            return {
                ...state,
                variantUpdated  : true,
                loading         : false
            };
        case GET_UPDATE_PROD_VARIANT_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_REMOVE_PROD_VARIANT_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_REMOVE_PROD_VARIANT_SUCCESS:
            return {
                ...state,
                variantDeleted  : true,
                loading         : false
            };
        case GET_REMOVE_PROD_VARIANT_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        default:
            return state;
    }
}
