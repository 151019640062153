import React, { Component } from "react";
import { Link } from "react-router-dom";
import PeopleIcon from "@material-ui/icons/People";
import CategoryIcon from "@material-ui/icons/Category";
import PaymentIcon from "@material-ui/icons/Payment";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import RateReviewIcon from "@material-ui/icons/RateReview";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import ForumIcon from "@material-ui/icons/Forum";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import BrandingWatermarkIcon from "@material-ui/icons/BrandingWatermark";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import StyleIcon from "@material-ui/icons/Style";
import AddCommentIcon from "@material-ui/icons/AddComment";
import ReorderIcon from "@material-ui/icons/Reorder";
import PersonIcon from "@material-ui/icons/Person";
import AppsIcon from "@material-ui/icons/Apps";
import AssessmentIcon from "@material-ui/icons/Assessment";
import MoneyIcon from "@material-ui/icons/Money";
import SettingsIcon from "@material-ui/icons/Settings";

export class Sidebar extends Component {
  state = {
    sideNavLeft: false,
  };
  async componentDidMount() {}
  sidenavToggle = (sidenavId) => () => {
    const sidenavNr = `sideNav${sidenavId}`;
    this.setState({
      [sidenavNr]: !this.state[sidenavNr],
    });
  };

  render() {
    return (
      <React.Fragment>
        <aside class="main-sidebar col-12 col-md-3 col-lg-2 px-0">
          <div className="main-navbar">
            <nav className="navbar align-items-stretch navbar-light bg-white flex-md-nowrap p-0">
              <a className="navbar-brand w-100 mr-0" href="/">
                <div className="w-100 px-4 d-flex align-items-center">
                  <img
                    id="main-logo"
                    width="25px"
                    className="d-inline-block align-top mr-1"
                    src="../assets/images/GS-logo.png"
                    alt="Shards Dashboard"
                  />
                  <span className="ml-1">Pitenxlm Panel</span>
                </div>
              </a>
              <a
                className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
                href="/"
              >
                <i className="material-icons">&#xE5C4;</i>
              </a>
            </nav>
          </div>

          <div className="nav-wrapper" id="sidebar">
            <ul className="nav flex-column flex-nowrap overflow-hidden">
              <li className="nav-item">
                <a
                  className="nav-link collapsed text-truncate"
                  href="#submenu1"
                  data-toggle="collapse"
                  data-target="#submenu1"
                >
                  <PersonIcon />
                  <span>User Management</span>
                </a>
                <div className="collapse" id="submenu1" aria-expanded="false">
                  <ul className="flex-column pl-2 nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/users-list">
                        <PeopleIcon />
                        <span className="ml-1">Users</span>
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                                            <Link className="nav-link" to='/reviews-list'>
                                                <RateReviewIcon />
                                                <span className='ml-1'>Reviews</span>
                                            </Link>
                                        </li> */}
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link collapsed text-truncate"
                  href="#submenu2"
                  data-toggle="collapse"
                  data-target="#submenu2"
                >
                  <AppsIcon /> <span>Catalog Management</span>
                </a>
                <div className="collapse" id="submenu2" aria-expanded="false">
                  <ul className="flex-column pl-2 nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/categories-list">
                        <CategoryIcon />
                        <span className="ml-1">Categories</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/products-list">
                        <LocalOfferIcon />
                        <span className="ml-1">Products</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/variants-list">
                        <StyleIcon />
                        <span className="ml-1">Product Variants</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/units-list">
                        <AcUnitIcon />
                        <span className="ml-1">Units</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/packets-list">
                        <MarkunreadMailboxIcon />
                        <span className="ml-1">Packets</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/notifications-list">
                        <MarkunreadMailboxIcon />
                        <span className="ml-1">Notifications</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link collapsed text-truncate"
                  href="#submenu3"
                  data-toggle="collapse"
                  data-target="#submenu3"
                >
                  <ReorderIcon /> <span>Order Management</span>
                </a>
                <div className="collapse" id="submenu3" aria-expanded="false">
                  <ul className="flex-column pl-2 nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/orders-list">
                        <LocalMallIcon />
                        <span className="ml-1">Orders</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/customer-queries">
                        <ForumIcon />
                        <span className="ml-1">Queries</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/help-options-list">
                        <AddCommentIcon />
                        <span className="ml-1">help Options</span>
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                                            <Link className="nav-link" to='/shippings-list'>
                                                <LocalShippingIcon />
                                                <span className='ml-1'>Shippings Charge</span>
                                            </Link>
                                        </li> */}
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link collapsed text-truncate"
                  href="#submenu4"
                  data-toggle="collapse"
                  data-target="#submenu4"
                >
                  <BrandingWatermarkIcon /> <span>Brand Management</span>
                </a>
                <div className="collapse" id="submenu4" aria-expanded="false">
                  <ul className="flex-column pl-2 nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/brands-list">
                        <BrandingWatermarkIcon />
                        <span className="ml-1">Brands</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/banners-list">
                        <ViewCarouselIcon />
                        <span className="ml-1">Banners</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/onboarding-list">
                        <ViewCarouselIcon />
                        <span className="ml-1">On Boarding</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/coupons-list">
                        <MoneyOffIcon />
                        <span className="ml-1">Coupons</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/panel">
                        <SettingsIcon />
                        <span className="ml-1">Panel</span>
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                                            <Link className="nav-link" to='/taxes-list'>
                                                <PaymentIcon />
                                                <span className='ml-1'>Taxes</span>
                                            </Link>
                                        </li> */}
                    <li className="nav-item">
                      <Link className="nav-link" to="/articles-list">
                        <PaymentIcon />
                        <span className="ml-1">Articles</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link collapsed text-truncate"
                  href="#submenu5"
                  data-toggle="collapse"
                  data-target="#submenu5"
                >
                  <AssessmentIcon /> <span>Report Management</span>
                </a>
                <div className="collapse" id="submenu5" aria-expanded="false">
                  <ul className="flex-column pl-2 nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/earning-reports">
                        <MoneyIcon />
                        <span className="ml-1">Earning Reports</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </aside>
      </React.Fragment>
    );
  }
}

export default Sidebar;
