import {  
    POST_ADD_CATEGORY_REQUEST, GET_ADD_CATEGORY_SUCCESS, GET_ADD_CATEGORY_FAILURE,
    POST_CATEGORIES_REQUEST, GET_CATEGORIES_SUCCESS, GET_CATEGORIES_FAILURE,
    POST_CATEGORY_DELETE_REQUEST, GET_CATEGORY_DELETE_SUCCESS, GET_CATEGORY_DELETE_FAILURE,
    POST_CATEGORY_GET_REQUEST, GET_CATEGORY_GET_SUCCESS, GET_CATEGORY_GET_FAILURE,
    POST_UPDATE_CATEGORY_REQUEST, GET_UPDATE_CATEGORY_SUCCESS, GET_UPDATE_CATEGORY_FAILURE,
    POST_CATEGORY_IMAGE_REQUEST, GET_CATEGORY_IMAGE_SUCCESS, GET_CATEGORY_IMAGE_FAILURE,
    POST_PARENT_CATEGORIES_REQUEST, GET_PARENT_CATEGORIES_SUCCESS, GET_PARENT_CATEGORIES_FAILURE,
    POST_SUB_CATEGORIES_REQUEST, GET_SUB_CATEGORIES_SUCCESS, GET_SUB_CATEGORIES_FAILURE,
} from '../_actions/_type';

export const initialState = {
    imagePath           : '',
    categoryCreated     : false,
    categoryUpdate      : false,
    categoryDeleted     : false,
    category            : [],
    categories          : [],
    parentCats          : [],
    subCats             : [],
    errors              : {},
};
export default function categories (state = initialState, action) {
    switch (action.type) {
        case POST_SUB_CATEGORIES_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_SUB_CATEGORIES_SUCCESS:
            return {
                ...state,
                subCats         : action.payload,
                loading         : false
            };
        case GET_SUB_CATEGORIES_FAILURE:
            return {
                ...state,
                loading         : false,
                errors          : action.payload
            };
        case POST_PARENT_CATEGORIES_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_PARENT_CATEGORIES_SUCCESS:
            return {
                ...state,
                parentCats      : action.payload,
                loading         : false
            };
        case GET_PARENT_CATEGORIES_FAILURE:
            return {
                ...state,
                loading         : false,
                errors          : action.payload
            };
        case POST_ADD_CATEGORY_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_ADD_CATEGORY_SUCCESS:
            return {
                ...state,
                categoryCreated : true,
                loading         : false
            };
        case GET_ADD_CATEGORY_FAILURE:
            return {
                ...state,
                loading         : false
            };
        case POST_CATEGORY_IMAGE_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_CATEGORY_IMAGE_SUCCESS:
            return {
                ...state,
                imagePath       : action.payload.filepath,
                loading         : false
            };
        case GET_CATEGORY_IMAGE_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_UPDATE_CATEGORY_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_UPDATE_CATEGORY_SUCCESS:
            return {
                ...state,
                categoryUpdate  : true,
                loading         : false
            };
        case GET_UPDATE_CATEGORY_FAILURE:
            return {
                ...state,
                loading         : false
            };
        case POST_CATEGORIES_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories      : action.payload,
                loading         : false
            };
        case GET_CATEGORIES_FAILURE:
            return {
                ...state,
                loading         : false
            };
        case POST_CATEGORY_DELETE_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_CATEGORY_DELETE_SUCCESS:
            return {
                ...state,
                categories      : action.payload,
                categoryDeleted : true,
                loading         : false
            };
        case GET_CATEGORY_DELETE_FAILURE:
            return {
                ...state,
                loading         : false
            };
        case POST_CATEGORY_GET_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_CATEGORY_GET_SUCCESS:
            return {
                ...state,
                category        : action.payload,
                loading         : false
            };
        case GET_CATEGORY_GET_FAILURE:
            return {
                ...state,
                loading         : false
            };
        default:
            return state;
    }
}
