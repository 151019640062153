import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Header from '../CommonTemplates/Header.js';
import { API_URL, BASEASSETURL } from '../../utils/admin-api-url.js';
import Sidebar from '../CommonTemplates/SideBar.js';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { getOnBoarding, uploadOnboardingImage, updateOnBoarding } from '../../_actions/onBoardingActions.js';

class EditOnBoarding extends Component {
    state = {
        readOnly: true,
        fields: {},
        errors: {},
        imagePath: '',
    }

    async componentDidMount() {
        const id = this.props.match.params.id;
        await this.props.getOnBoarding(id);
        var onboarding = this.props.onboardings.onboarding;
        var fields = this.state.fields;
        Object.keys(onboarding)
            .forEach(function eachKey(key) {
                fields[key] = onboarding[key];
            });
        this.setState(fields);
        this.setState({ imagePath: fields['image'] });
    }

    // Input handle  
    handleChange = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ errors: '' });
        this.setState(fields);
    }

    // Validation 
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields['name']) {
            formIsValid = false;
            errors['name'] = 'Please enter name.';
        }
        if (this.state.imagePath === '') {
            formIsValid = false;
            errors['image'] = 'Please select image.';
        }
        if (!fields['description']) {
            formIsValid = false;
            errors['description'] = 'Please enter description.';
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    // Upload Brand Image.
    onChangeFile = async (e) => {
        var file = e.target.files[0];
        var formData = new FormData();
        formData.append('image', file.name);
        formData.append('image', file);
        await this.props.uploadOnBoardingImage(formData);
        this.setState({ imagePath: this.props.onboardings.imagePath });
    }

    // Handle Submit 
    handleSubmit = async (e) => {
        e.preventDefault();
        if (this.handleValidation()) {
            const onboardingData = {
                onboarding_id: this.state.fields['id'],
                name: this.state.fields['name'],
                image: this.state.imagePath,
                description: this.state.fields['description'],
            };
            await this.props.updateOnBoarding(onboardingData, this.props.history);
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <Header />
                            <div className="main-content-container container-fluid px-4">
                                <div className="page-header row no-gutters py-4">
                                    <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                                        <span className="text-uppercase page-subtitle">Overview</span>
                                        <h3 className="page-title">Edit OnBoarding</h3>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card card-small">
                                            <div className="card-header border-bottom">
                                                <h6 className="m-0">OnBoarding Details</h6>
                                            </div>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item p-3">
                                                    <div className="row">
                                                        <div className="col">
                                                            <form onSubmit={this.handleSubmit} enctype='multipart/form-data'>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feCode">Name</label>
                                                                        <input type="text" className="form-control" id="name" name="name" maxLength='35' placeholder="Enter onboarding name." value={this.state.fields['name']} onChange={this.handleChange} />
                                                                        <span style={{ color: 'red' }}>{this.state.errors['name']}</span>
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label for="feFirstName">Image</label>
                                                                        <input type='file' name='image' id="image" className='form-control' onChange={this.onChangeFile} accept='image/*' />
                                                                        <span style={{ color: 'red' }}>{this.state.errors['image']}</span>
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <img src={BASEASSETURL + this.state.fields['image']} alt='' height='50' width='50' />
                                                                    </div>
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <label for="feDescription">Description</label>
                                                                        <textarea className="form-control" name="description" placeholder='Enter onboarding description' onChange={this.handleChange} id="description" value={this.state.fields['description']} rows='5'></textarea>
                                                                        <span style={{ color: 'red' }}>{this.state.errors['description']}</span>
                                                                    </div>
                                                                </div>
                                                                <button type="submit" className="btn btn-success">Submit</button>&nbsp;
                                                                <Link to="/onboardings-list" className="btn btn-danger">Cancel</Link>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer />
                            {/* <Footer/> */}
                        </main>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    onboardings: state.onboardings,
});
const actionCreators = {
    getOnBoarding: getOnBoarding,
    uploadOnboardingImage: uploadOnboardingImage,
    updateOnBoarding: updateOnBoarding,
};

export default connect(mapStateToProps, actionCreators)(EditOnBoarding);