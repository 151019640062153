import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MDBDataTable } from 'mdbreact';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { Fab } from 'react-tiny-fab';
import EditIcon from '@material-ui/icons/Edit';
import SideBar from '../CommonTemplates/SideBar.js';
import Header from '../CommonTemplates/Header.js';
import { getTaxes, deleteTax } from '../../_actions/taxActions.js';


export class TaxesList extends Component {
    state = {
        readOnly    : true,
        showModal   : false,
        id          : ''
    }
    async componentDidMount() {
        await this.props.getTaxes();
    }

    handleOpenModal = id => {
        this.setState({ showModal: true, id });
    };

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    handleConfirmDelete = async () => {
        this.setState({ showModal: false });
        await this.props.deleteTax(this.state.id);
        await this.props.getTaxes();
    };

    addNew = () => {
        this.props.history.push('add-tax');
    }

    render() {
        const taxes = this.props.taxes;
        let displayContent = '';
        let i = 1;
        displayContent = taxes.map(rowInfo => ( { 
            no: i++,
            name: rowInfo.name,
            description: rowInfo.description,
            value: rowInfo.value,
            action:
                <div>
                    <button className="btn btn-danger" onClick={() => this.handleOpenModal(rowInfo.id)}><DeleteRoundedIcon /></button>&nbsp;
                    <Link to={`/edit-tax/${(rowInfo.id)}`} className="btn btn-warning"><EditIcon /></Link>
                </div>  
        } ));

        const data = {
            columns: [
                {
                    label: 'S.No',
                    field: 'no',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Slab Name',
                    field: 'name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Description',
                    field: 'description',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Value In Percentage',
                    field: 'value',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Action',
                    field: 'action',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: displayContent
        };

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <SideBar/>
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                                <Header/>
                                <div className="main-content-container container-fluid px-4">
                                    <div class="page-header row no-gutters py-4">
                                        <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
                                            <span class="text-uppercase page-subtitle">Overview</span>
                                            <h3 class="page-title">Tax List</h3>
                                        </div>
                                    </div>
                                    <Fab
                                        icon="+"
                                        alwaysShowTitle = {false}
                                        onClick={this.addNew}
                                        >
                                    </Fab>
                                    <div class="row">
                                        <div class="col">
                                            <div class="card card-small mb-4">
                                                <div class="card-header border-bottom">
                                                    <h6 class="m-0">Taxes List</h6>
                                                </div>
                                                <div class="card-body pb-3 text-center">
                                                    <MDBDataTable  striped bordered hover data={data}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <ToastContainer />
                            {/* <Footer/> */}
                            <ReactModal isOpen={this.state.showModal} contentLabel="onRequestClose Example" onAfterOpen={this.afterOpenModal}
                                onRequestClose={this.handleCloseModal} shouldCloseOnOverlayClick={false} style={customStyles} >
                                <h3>Are you sure delete this user ?</h3>
                                <div className="btn-modal-container">
                                    <ul>
                                        <li>
                                            <button
                                                className="btn btn-sm btn-warning btn-modal-close"
                                                onClick={this.handleCloseModal}
                                            >
                                                Cancel
                                            </button>
                                        </li>
                                        <li>
                                            {' '}
                                            <button
                                                className="btn btn-sm btn-danger btn-modal-confirm"
                                                onClick={this.handleConfirmDelete}
                                            >
                                                Delete
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </ReactModal>
                        </main>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const customStyles = {
    content: {
        color: 'darkred',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    },
    overlay: {
        backgroundColor: '#444',
        opacity: '0.9'
    }
};

const mapStateToProps = state => ({
    taxes: state.taxes.taxes,
});
const actionCreators = {
    deleteTax : deleteTax,
    getTaxes: getTaxes,
};

export default connect(mapStateToProps, actionCreators )(TaxesList);
