function currency(data) {
    if (data === "NaN") return data;
    data = Number(data);
    let DecimalSeparator = Number("1.2").toLocaleString().substr(1, 1);
    let AmountWithCommas = data.toLocaleString();
    let arParts = String(AmountWithCommas).split(DecimalSeparator);
    let intPart = arParts[0];
    let decPart = arParts.length > 1 ? arParts[1] : "";
    decPart = (decPart + "00").substr(0, 2);

    return intPart + DecimalSeparator + decPart;
}

export default currency