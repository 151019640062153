import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MDBDataTable } from 'mdbreact';
import SideBar from '../CommonTemplates/SideBar.js';
import Header from '../CommonTemplates/Header.js';
import { getQueriesList } from '../../_actions/queryActions.js';
export class QueryList extends Component {
    state = {
        readOnly: true,
        showModal: false,
        id: ''
    }
    async componentDidMount() {
        await this.props.getQueriesList();
    }

    render() {
        const queries = this.props.queries;
        let displayContent = '';
        let i = 1;
        displayContent = queries.map(rowInfo => ({
            no: i++,
            name: rowInfo.name,
            user_name: rowInfo.user_name,
            option: rowInfo.option_name || '--',
            orderId:
                <div>
                    <Link to={`/edit-order/${(rowInfo.order_id)}`}>{'#' + rowInfo.order_id}</Link>
                </div>,
            subject: rowInfo.subject === 'null' ? '--' : rowInfo.subject,
            action:
                <div>
                    <Link to={`/order-queries/${(rowInfo.order_id)}`} className="btn btn-warning"><i className="fa fa-reply"></i></Link>
                </div>
        }));

        const data = {
            columns: [
                {
                    label: 'S.No',
                    field: 'no',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Order Id',
                    field: 'orderId',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'User Name',
                    field: 'user_name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Option',
                    field: 'option',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Subject',
                    field: 'subject',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Reply',
                    field: 'action',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: displayContent
        };

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <SideBar />
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <Header />
                            <div className="main-content-container container-fluid px-4">
                                <div class="page-header row no-gutters py-4">
                                    <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
                                        <span class="text-uppercase page-subtitle">Overview</span>
                                        <h3 class="page-title">Customer Queries List</h3>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="card card-small mb-4">
                                            <div class="card-header border-bottom">
                                                <h6 class="m-0">Active Queries</h6>
                                            </div>
                                            <div class="card-body pb-3 text-center">
                                                <MDBDataTable striped bordered hover data={data} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer />
                            {/* <Footer/> */}
                        </main>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    queries: state.queries.queries,
});
const actionCreators = {
    getQueriesList: getQueriesList,
};

export default connect(mapStateToProps, actionCreators)(QueryList);
