import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Header from '../CommonTemplates/Header.js';
import Sidebar from '../CommonTemplates/SideBar.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import 'react-toastify/dist/ReactToastify.css';
import Moment from 'moment';
import { getQueriesOnOrder, postResponse } from '../../_actions/queryActions.js';

class OrderQueries extends Component {
    state = {
        readOnly: true,
        fields: {},
        errors: {},
        user: [],
        adminId: '',
    }

    async componentDidMount() {
        const id = this.props.match.params.id;
        await this.props.getQueriesOnOrder(id);
        this.setState({adminId : (reactLocalStorage.getObject('Auth').id)})
        this.setState({user:this.props.user});
        console.log(this.props.query);
    }  
        
    // Input handle  
    handleChange = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({errors:''}); 
        this.setState(fields);
    }

    // Validation 
    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if(!fields[ 'name' ]) {
            formIsValid = false;
            errors[ 'name' ] = 'Please enter response.';
        }
        if(!fields[ 'status' ]) {
            formIsValid = false;
            errors[ 'status' ] = 'Please select status.';
        } else if (fields[ 'status' ] === ''){
            formIsValid = false;
            errors[ 'status' ] = 'Please select status.';
        }
        this.setState({errors: errors});
        return formIsValid;
    }
    changeDateFormat(dt){
        var date = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(dt);
        return date;
    }

    //Handle Submit 
    handleSubmit = async (e) => {
        e.preventDefault();
        if(this.handleValidation()){
            const data = {
                order_id: this.state.user.order_id,
                query: this.state.fields[ 'name' ],
                user_id: this.state.adminId,
                status: this.state.fields[ 'status' ],
            };
            await this.props.postResponse(data, this.props.history);
            window.location.reload(true);
        }              
    }
    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar/>
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                                <Header/>
                                <div className="main-content-container container-fluid px-4">
                                    <div className="page-header row no-gutters py-4">
                                        <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                                            <span className="text-uppercase page-subtitle">Overview</span>
                                            <h3 className="page-title">Order Queries Chat</h3>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card card-small mb-4">
                                                <div className="card-header border-bottom">
                                                    <h6 className="m-0">Order Details</h6>
                                                </div>
                                                <div className="card-body">

                                                    <div className="form-row">
                                                        <div className="form-group col-md-12" style={{overflow: 'auto', maxHeight: '300px'}}>
                                                            {this.props.query.map((index)=>{
                                                                if(index.user_id != this.state.user.id){
                                                                    return(
                                                                        <div className="form-row">
                                                                            <div className="form-group offset-2 col-md-10">
                                                                                <div className='d-flex' style={{float: 'right'}}>
                                                                                    <div className='mr-2' style={{backgroundColor: '#3ccd7b', fontSize: '13px', float:'right', borderRadius: '5px', padding:'4px 10px', color: '#fff'}}>
                                                                                        <p className='bg-muted m-0'>{index.message}</p>
                                                                                        <p style={{fontSize: '9px', marginBottom: '0px', marginTop: '5px', textAlign: 'end'}}>{Moment(index.created_at).format('LLLL')}</p>
                                                                                    </div>
                                                                                    <i className='fa fa-user' style={{fontSize: '20px'}}></i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return(
                                                                        <div className="form-row">
                                                                            <div className="form-group col-md-12">
                                                                                <div className='d-flex'>
                                                                                    <i className='fa fa-user' style={{fontSize: '20px'}}></i>
                                                                                    <div className='ml-2' style={{fontSize: '13px', backgroundColor: '#3d5170', borderRadius: '5px', padding:'4px 10px', color: '#fff'}}>
                                                                                        <p className='bg-muted m-0' >{index.message}</p>
                                                                                        <p style={{fontSize: '9px', marginBottom: '0px', marginTop: '5px', textAlign: 'end'}}>{Moment(index.created_at).format('LLLL')}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-footer border-top">
                                                    <form onSubmit={this.handleSubmit} enctype='multipart/form-data'>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <input type="text" className="form-control" id="name" name="name" placeholder="Enter response" onChange={this.handleChange}/> 
                                                                <span style={{color: 'red' }}>{this.state.errors[ 'name' ]}</span>
                                                            </div>
                                                            <div className="form-group col-md-2">
                                                                <select id="status" name="status" className="form-control" value={this.state.fields[ 'status' ]} onChange={this.handleChange}>
                                                                    <option value=''>Select</option>
                                                                    <option value='1'>Resolved</option>
                                                                    <option value='0'>Continue</option>
                                                                </select>
                                                                <span style={{color: 'red' }}>{this.state.errors[ 'status' ]}</span>
                                                            </div>
                                                            <div className='form-group col-md-4'>
                                                                <button type="submit" className="btn btn-success">Send</button>&nbsp;
                                                                <Link to="/customer-queries" className="btn btn-danger">Cancel</Link>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            <ToastContainer />
                            {/* <Footer/> */}
                        </main>
                    </div>
                </div>
            </React.Fragment>   
        );
    }
}

const mapStateToProps = state => ({
    query: state.queries.query,
    user: state.queries.user,
});
const actionCreators = {
    getQueriesOnOrder: getQueriesOnOrder,
    postResponse: postResponse
};
export default connect(mapStateToProps, actionCreators)(OrderQueries);