import {
    POST_USERS_REQUEST, GET_USERS_SUCCESS, GET_USERS_FAILURE,
    POST_USER_DELEET_REQUEST, GET_USER_DELETE_SUCCESS, GET_USER_DELETE_FAILURE,
    POST_USER_DETAILS_REQUEST, GET_USER_DETAILS_SUCCESS, GET_USER_DETAILS_FAILURE,
    POST_USER_UPDATE_REQUEST, GET_USER_UPDATE_SUCCESS, GET_USER_UPDATE_FAILURE,
    POST_ADD_USER_REQUEST, GET_ADD_USER_SUCCESS, GET_ADD_USER_FAILURE,
    POST_USER_PHOTO_REQUEST, GET_USER_PHOTO_SUCCESS, GET_USER_PHOTO_FAILURE
} from '../_actions/_type';
import {
    URL_ADMIN_GET_USERS_LIST, URL_ADMIN_UPLOAD_USER_PHOTO, URL_ADMIN_DELETE_USER,
    URL_ADMIN_GET_SINGLE_USER, URL_ADMIN_UPDATE_USER, URL_ADMIN_ADD_USER
} from '../utils/admin-api-url.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';
import axios from 'axios';

// Upload User Image
export const uploadUserImage = (userImage) => async dispatch => {
    try {
        dispatch({ type: POST_USER_PHOTO_REQUEST, payload: true });
        const responseImage = await axios.post(URL_ADMIN_UPLOAD_USER_PHOTO, userImage, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (responseImage.data.status === true) {
            dispatch({ type: GET_USER_PHOTO_SUCCESS, payload: responseImage.data });
        } else {
            toast.error(responseImage.data.message, { position: toast.POSITION.TOP_RIGHT });
        }
    } catch (error) {
        dispatch({ type: GET_USER_PHOTO_FAILURE, payload: error });
    }
};

// add User
export const addUser = (userData, history) => async dispatch => {
    try {
        dispatch({ type: POST_ADD_USER_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_ADD_USER, userData, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_ADD_USER_SUCCESS, payload: response.data });
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
            history.push('/users-list');
        } else {
            toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        }
    } catch (error) {
        dispatch({ type: GET_ADD_USER_FAILURE, payload: error });
    }
};

// Get All users list
export const getUsers = () => async dispatch => {
    try {
        dispatch({ type: POST_USERS_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_GET_USERS_LIST, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_USERS_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_USERS_FAILURE, payload: error });
    }
};

// Delete User
export const deleteUser = (ID, history) => async dispatch => {
    try {
        dispatch({ type: POST_USER_DELEET_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_DELETE_USER}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_USER_DELETE_SUCCESS, payload: response.data });
            toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
            history.push('/users-list');
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_USER_DELETE_FAILURE, payload: error });
    }
};

// Get Single User
export const getUser = ID => async dispatch => {
    try {
        dispatch({ type: POST_USER_DETAILS_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_GET_SINGLE_USER}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_USER_DETAILS_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        }
    } catch (error) {
        dispatch({ type: GET_USER_DETAILS_FAILURE, payload: error });
    }
};

// Update User
export const updateUser = (userData, history) => async dispatch => {
    try {
        dispatch({ type: POST_USER_UPDATE_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_UPDATE_USER, userData, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_USER_UPDATE_SUCCESS, payload: response.data });
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
            history.push('/users-list');
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_USER_UPDATE_FAILURE, payload: error });
    }
};
