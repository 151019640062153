import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Header from '../CommonTemplates/Header.js';
import Sidebar from '../CommonTemplates/SideBar.js';
import 'react-toastify/dist/ReactToastify.css';
import { getActiveUnits } from '../../_actions/unitActions.js';
import { updatePacket, getPacket } from '../../_actions/packetActions.js';

class EditPacket extends Component { 
    state = {
        readOnly    : true,
        fields      : {},
        errors      : {},
    }

    async componentDidMount(){
        await this.props.getActiveUnits();
        const id = this.props.match.params.id;
        await this.props.getPacket(id);
        var data = this.props.packet[0];
        var fields = this.state.fields;
        Object.keys(data)
            .forEach(function eachKey(key) {               
                fields[key] = data[key];                   
            });
        this.setState(fields);
    }  
        
    //Input handle  
    handleChange = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({errors:''}); 
        this.setState(fields);
    }

    //Validation 
    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if(!fields[ 'name' ]) {
            formIsValid = false;
            errors[ 'name' ] = 'Please enter packet name.';
        }
        if(!fields[ 'weight' ]) {
            formIsValid = false;
            errors[ 'weight' ] = 'Please enter packet weight.';
        }
        if(!fields[ 'unit_id' ]) {
            formIsValid = false;
            errors[ 'unit' ] = 'Please select unit.';
        } else if(fields[ 'unit_id' ] === ''){
            formIsValid = false;
            errors[ 'unit' ] = 'Please select unit.';
        }
        if(!fields[ 'status' ]) {
            formIsValid = false;
            errors[ 'status' ] = 'Please select status.';
        } else if (fields[ 'status' ] === ''){
            formIsValid = false;
            errors[ 'status' ] = 'Please select status.';
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    //Handle Submit 
    handleSubmit = async (e) => {
        e.preventDefault();
        if(this.handleValidation()){
            const data = {
                packet_id: this.state.fields[ 'id' ],
                unit_id  : this.state.fields[ 'unit_id' ],
                name     : this.state.fields[ 'name' ],
                weight   : this.state.fields[ 'weight' ],
                status   : this.state.fields[ 'status' ],
            };
            await this.props.updatePacket(data, this.props.history);
        }              
    }
    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar/>
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                                <Header/>
                                <div className="main-content-container container-fluid px-4">
                                    <div className="page-header row no-gutters py-4">
                                        <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                                            <span className="text-uppercase page-subtitle">Overview</span>
                                            <h3 className="page-title">Edit Packet</h3>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card card-small mb-4">
                                                <div className="card-header border-bottom">
                                                    <h6 className="m-0">Packet Details</h6>
                                                </div>
                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item p-3">
                                                        <div className="row">
                                                            <div className="col">
                                                                <form onSubmit={this.handleSubmit} enctype='multipart/form-data'>
                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6">
                                                                            <label for="feName">Name</label>
                                                                            <input type="text" className="form-control" id="name" name="name" placeholder="Enter packet name" onInput={ (e) => { e.target.value = e.target.value.replace(/[^a-zA-Z0-9/ ]/g, ''); } } value={this.state.fields[ 'name' ]} onChange={this.handleChange}/> 
                                                                            <span style={{color: 'red' }}>{this.state.errors[ 'name' ]}</span>
                                                                        </div>
                                                                        <div className="form-group col-md-6">
                                                                            <label for="feWeight">Weight</label>
                                                                            <input type="text" className="form-control" id="weight" name="weight" placeholder="Enter packet weight" onInput={ (e) => { e.target.value = e.target.value.replace(/[^0-9]/g, ''); } } value={this.state.fields[ 'weight' ]} onChange={this.handleChange}/> 
                                                                            <span style={{color: 'red' }}>{this.state.errors[ 'weight' ]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='form-row'>
                                                                        <div className="form-group col-md-6">
                                                                            <label for="feUnit">Unit</label>
                                                                            <select id="unit_id" name="unit_id" className="form-control" value={this.state.fields[ 'unit_id' ]} onChange={this.handleChange}>
                                                                                <option  value=''>Select</option>
                                                                                {this.props.units.map((item, index) => (
                                                                                    <option key ={index}  value={item.id}>{item.name}</option>
                                                                                ))}
                                                                            </select>
                                                                            <span style={ { color : 'red' } }>{this.state.errors[ 'unit' ]}</span>
                                                                        </div>
                                                                        <div className="form-group col-md-6">
                                                                            <label for="feStatus">Status</label>
                                                                            <select id="status" name="status" className="form-control" value={this.state.fields[ 'status' ]} onChange={this.handleChange}>
                                                                                <option value=''>Select</option>
                                                                                <option value='1'>Active</option>
                                                                                <option value='0'>Inactive</option>
                                                                            </select>
                                                                            <span style={{color: 'red' }}>{this.state.errors[ 'status' ]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <button type="submit" className="btn btn-success">Submit</button>&nbsp;
                                                                    <Link to="/packets-list" className="btn btn-danger">Cancel</Link>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            <ToastContainer />
                            {/* <Footer/> */}
                        </main>
                    </div>
                </div>
            </React.Fragment>   
        );
    }
}

const mapStateToProps = state => ({
    units  : state.units.activeUnits,
    packet : state.packets.packet,
});
const actionCreators = {
    getActiveUnits  : getActiveUnits,
    updatePacket    : updatePacket,
    getPacket       : getPacket,
};
export default connect(mapStateToProps, actionCreators)(EditPacket);