import {
    POST_ADD_PRODUCT_REQUEST, GET_ADD_PRODUCT_SUCCESS, GET_ADD_PRODUCT_FAILURE,
    POST_PRODUCT_REQUEST, GET_PRODUCT_SUCCESS, GET_PRODUCT_FAILURE,
    POST_PRODUCTS_REQUEST, GET_PRODUCTS_SUCCESS, GET_PRODUCTS_FAILURE,
    POST_ACTIVE_PRODUCTS_REQUEST, GET_ACTIVE_PRODUCTS_SUCCESS, GET_ACTIVE_PRODUCTS_FAILURE,
    POST_UPDATE_PRODUCT_REQUEST, GET_UPDATE_PRODUCT_SUCCESS, GET_UPDATE_PRODUCT_FAILURE,
    POST_REMOVE_PRODUCT_REQUEST, GET_REMOVE_PRODUCT_SUCCESS, GET_REMOVE_PRODUCT_FAILURE,
    POST_PRODUCT_IMAGE_REQUEST, GET_PRODUCT_IMAGE_SUCCESS, GET_PRODUCT_IMAGE_FAILURE,
    POST_POPULAR_PRODUCT_REQUEST, GET_POPULAR_PRODUCT_SUCCESS, GET_POPULAR_PRODUCT_FAILURE,
    POST_REMOVE_PRO_IMG_REQUEST, GET_REMOVE_PRO_IMG_SUCCESS, GET_REMOVE_PRO_IMG_FAILURE,
} from '../_actions/_type';

export const initialState = {
    productCreated  : false,
    productUpdated  : false,
    productRemoved  : false,
    removeProImg    : false,
    loading         : false,
    activeProducts  : [],
    products        : [],
    product         : [],
    errors          : {},
    images          : [],
    imagePath       : '',
};
export default function products (state = initialState, action) {
    switch (action.type) {
        case POST_ACTIVE_PRODUCTS_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_ACTIVE_PRODUCTS_SUCCESS:
            return {
                ...state,
                activeProducts  : action.payload,
                loading         : false
            };
        case GET_ACTIVE_PRODUCTS_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_REMOVE_PRO_IMG_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_REMOVE_PRO_IMG_SUCCESS:
            return {
                ...state,
                removeProImg    : true,
                loading         : false
            };
        case GET_REMOVE_PRO_IMG_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_POPULAR_PRODUCT_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_POPULAR_PRODUCT_SUCCESS:
            return {
                ...state,
                productUpdated  : true,
                loading         : false
            };
        case GET_POPULAR_PRODUCT_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_ADD_PRODUCT_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_ADD_PRODUCT_SUCCESS:
            return {
                ...state,
                productCreated  : true,
                loading         : false
            };
        case GET_ADD_PRODUCT_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_PRODUCT_IMAGE_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_PRODUCT_IMAGE_SUCCESS:
            return {
                ...state,
                imagePath       : action.payload.filepath,
                loading         : false
            };
        case GET_PRODUCT_IMAGE_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_PRODUCTS_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                products        : action.payload,
                loading         : false
            };
        case GET_PRODUCTS_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_PRODUCT_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_PRODUCT_SUCCESS:
            return {
                ...state,
                product         : action.payload.data,
                images          : action.payload.images,
                loading         : false
            };
        case GET_PRODUCT_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };

        case POST_UPDATE_PRODUCT_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                productUpdated  : true,
                loading         : false
            };
        case GET_UPDATE_PRODUCT_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_REMOVE_PRODUCT_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_REMOVE_PRODUCT_SUCCESS:
            return {
                ...state,
                productRemoved  : true,
                loading         : false
            };
        case GET_REMOVE_PRODUCT_FAILURE:
            return {
                ...state,
                errors           : action.payload,
                loading          : false
            };
        default:
            return state;
    }
}
