import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, BASEASSETURL } from "../../utils/admin-api-url";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import { MDBDataTable } from "mdbreact";
import SideBar from "../CommonTemplates/SideBar.js";
import { Fab } from "react-tiny-fab";
import Header from "../CommonTemplates/Header.js";
import { getUsers, deleteUser } from "../../_actions/userActions.js";

export class UserList extends Component {
  state = {
    readOnly: true,
    showModal: false,
    id: "",
  };
  async componentDidMount() {
    await this.props.getAllUsers();
  }

  handleOpenModal = (id) => {
    this.setState({ showModal: true, id });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleConfirmDelete = async () => {
    this.setState({ showModal: false });
    await this.props.deleteUser(this.state.id, this.props.history);
    await this.props.getAllUsers();
  };

  addNew = () => {
    this.props.history.push("add-user");
  };

  render() {
    const { users } = this.props.users;
    let displayContent = "";
    let i = 1;
    displayContent = users.map((rowInfo) => ({
      no: i++,
      name: rowInfo.name,
      email: rowInfo.email,
      phone: rowInfo.phone,
      image: (
        <div>
          {rowInfo.profile == null ? (
            ""
          ) : (
            <img
              src={rowInfo.profile.includes('googleusercontent') ? rowInfo.profile : BASEASSETURL + rowInfo.profile}
              height="50"
              width="50"
              alt=""
            />
            // <img
            //   src={
            //     this.state.fields["profile"]?.includes('googleusercontent') ? this.state.fields["profile"] : BASEASSETURL + this.state.fields["profile"]
            //   }
            //   alt="wlw"
            //   height="50"
            //   width="50"
            // />
          )}
        </div>
      ),
      status: (
        <div>
          {rowInfo.status === "1" ? (
            <button className="btn btn-success">Active</button>
          ) : (
            <button className="btn btn-warning">Inactive</button>
          )}
        </div>
      ),
      action: (
        <div>
          <button
            className="btn btn-danger"
            onClick={() => this.handleOpenModal(rowInfo.id)}
          >
            <DeleteRoundedIcon />
          </button>
          &nbsp;
          <Link to={`/edit-user/${rowInfo.id}`} className="btn btn-warning">
            <EditIcon />
          </Link>
          <Link to={`/detail-user/${rowInfo.id}`} className="btn btn-primary">
            <InfoIcon />
          </Link>
        </div>
      ),
    }));

    const data = {
      columns: [
        {
          label: "S.No",
          field: "no",
          sort: "disabled",
          width: 50,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 100,
        },
        {
          label: "Email",
          field: "email",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Phone",
          field: "phone",
          sort: "disabled",
          width: 120,
        },
        {
          label: "Image",
          field: "image",
          sort: "disabled",
          width: 50,
        },
        // {
        //     label: 'Status',
        //     field: 'status',
        //     sort: 'disabled',
        //     width: 50
        // },
        {
          label: "Action",
          field: "action",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: displayContent,
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
              <Header />
              <div className="main-content-container container-fluid px-4">
                <div className="page-header row no-gutters py-4">
                  <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                    <span className="text-uppercase page-subtitle">
                      Overview
                    </span>
                    <h3 className="page-title">User List</h3>
                  </div>
                </div>

                <Fab icon="+" alwaysShowTitle={false} onClick={this.addNew}>
                  {" "}
                </Fab>

                <div className="row">
                  <div className="col">
                    <div className="card card-small mb-4">
                      <div className="card-header border-bottom">
                        <h6 className="m-0">Active Users</h6>
                      </div>
                      <div className="card-body text-center">
                        <MDBDataTable striped bordered hover data={data} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ToastContainer />
              {/* <Footer/> */}
              <ReactModal
                isOpen={this.state.showModal}
                contentLabel="onRequestClose Example"
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.handleCloseModal}
                shouldCloseOnOverlayClick={false}
                style={customStyles}
              >
                <h3>Are you sure delete this user ?</h3>
                <div className="btn-modal-container">
                  <ul>
                    <li>
                      <button
                        className="btn btn-sm btn-warning btn-modal-close"
                        onClick={this.handleCloseModal}
                      >
                        Cancel
                      </button>
                    </li>
                    <li>
                      {" "}
                      <button
                        className="btn btn-sm btn-danger btn-modal-confirm"
                        onClick={this.handleConfirmDelete}
                      >
                        Delete
                      </button>
                    </li>
                  </ul>
                </div>
              </ReactModal>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const customStyles = {
  content: {
    color: "darkred",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "#444",
    opacity: "0.9",
  },
};

const mapStateToProps = (state) => ({
  users: state.users,
});
const actionCreators = {
  getAllUsers: getUsers,
  deleteUser: deleteUser,
};

export default connect(mapStateToProps, actionCreators)(UserList);
