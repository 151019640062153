import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import "./App.css";
import Users from "../src/components/Users/UsersList.js";
import UserProfile from "../src/components/Users/UserProfile.js";
import AddUser from "../src/components/Users/AddUser.js";
import Login from "../src/components/Auth/Login.js";
import Dashboard from "../src/components/Templates/Dashboard.js";
import EditUser from "../src/components/Users/EditUser.js";
import AddCategory from "../src/components/Categories/AddCategory.js";
import CategoriesList from "../src/components/Categories/CategoriesList.js";
import EditCategory from "../src/components/Categories/EditCategory.js";
import ProductList from "../src/components/Products/ProductsList.js";
import AddProduct from "../src/components/Products/AddProduct.js";
import UpdateProduct from "../src/components/Products/EditProduct.js";
import ChangePassword from "../src/components/Users/ChangePassword.js";
// import AddAddress from '../src/components/Addresses/AddAddress.js';
// import AddressesList from '../src/components/Addresses/AddressesList.js';
// import EditAddress from '../src/components/Addresses/EditAddress.js';
// import AddDelivery from '../src/components/deliveries/AddDelivery.js';
// import DeliveriesList from '../src/components/deliveries/DeliveriesList.js';
// import EditDelivery from '../src/components/deliveries/EditDelivery.js';
import AddReview from "../src/components/Reviews/AddReview.js";
import ReviewsList from "../src/components/Reviews/ReviewsList.js";
import EditReview from "../src/components/Reviews/EditReview.js";
import AddCoupon from "../src/components/Coupons/AddCoupon.js";
import CouponsList from "../src/components/Coupons/CouponsList.js";
import AssignedCoupon from "../src/components/Coupons/AssignedCoupon.js";
import AddUserCoupon from "../src/components/Coupons/AddUserCoupon.js";
import EditCoupon from "../src/components/Coupons/EditCoupon.js";
import AddBrand from "../src/components/Brands/AddBrand.js";
import BrandsList from "../src/components/Brands/BrandsList.js";
import EditBrand from "../src/components/Brands/EditBrand.js";
import AddBanner from "../src/components/Banners/AddBanner.js";
import BannersList from "../src/components/Banners/BannersList.js";
import EditBanner from "../src/components/Banners/EditBanner.js";
import AddUnit from "../src/components/Units/AddUnit.js";
import UnitList from "../src/components/Units/UnitList.js";
import EditUnit from "../src/components/Units/EditUnit.js";
import AddPacket from "../src/components/Packets/AddPacket.js";
import PacketList from "../src/components/Packets/PacketList.js";
import EditPacket from "../src/components/Packets/EditPacket.js";
import AddVariant from "../src/components/ProductVariants/AddVariant.js";
import VariantList from "../src/components/ProductVariants/VariantList.js";
import EditVariant from "../src/components/ProductVariants/EditVariant.js";
import AddShipping from "../src/components/Shippings/AddShipping.js";
import ShippingList from "../src/components/Shippings/ShippingList.js";
import EditShipping from "../src/components/Shippings/EditShipping.js";
import AddHelpOption from "../src/components/HelpOptions/AddHelpOption.js";
import HelpOptionList from "../src/components/HelpOptions/HelpOptionList.js";
import EditHelpOption from "../src/components/HelpOptions/EditHelpOption.js";
import OrderList from "../src/components/Orders/OrderList.js";
import EditOrder from "../src/components/Orders/EditOrder.js";
import QueryList from "../src/components/Queries/QueryList";
import OrderQueries from "../src/components/Queries/OrderQueries.js";
import EditTax from "../src/components/Taxes/EditTax.js";
import AddTax from "../src/components/Taxes/AddTax.js";
import TaxesList from "../src/components/Taxes/TaxesList.js";
import TotalEarnings from "../src/components/Reports/TotalEarnings.js";
import ForgetPassword from "../src/components/Auth/ForgetPassword.js";
import EditNotifications from "./components/Notifications/EditNotifications";
import AddNotifications from "./components/Notifications/AddNotifications";
import ListNotifications from "./components/Notifications/ListNotifications";
import { ListArticles } from "./components/Articles/ListArticles";
import EditArticles from "./components/Articles/EditArticles";
import AddArticles from "./components/Articles/AddArticles";
import DetailUser from "./components/Users/DetailUser";
import DetailOrder from "./components/Orders/DetailOrder";
import AddOnBoarding from "./components/OnBoarding/AddOnBoarding";
import OnBoardingList, {
  OnBoardingsList,
} from "./components/OnBoarding/OnBoardingList";
import EditOnBoarding from "./components/OnBoarding/EditOnBoarding";
import DetailProduct from "./components/Products/DetailProduct";
import Panel from "./components/Panel/Panel";
import AddPanel from "./components/Panel/AddPanel";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  let token = localStorage.getItem("tokenUser");

  // const isAuthenticated = false;
  // console.log("this", isAuthenticated);
  // console.log(isLogin, 'login');
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        !token ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}

function App() {
  const token = localStorage.getItem("access-token");
  console.log(window.location);
  useEffect(() => {
    if (!token && window.location.pathname !== "/") {
      window.location.href = "/";
    }
  }, [token]);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/reset-password" component={ForgetPassword} />
          <Route path="/user-profile" component={UserProfile} />
          <Route path="/change-password" component={ChangePassword} />
          <Route path="/dashboard" component={Dashboard} />

          <Route path="/add-user" component={AddUser} />
          <Route path="/users-list" component={Users} />
          <Route path="/edit-user/:id" component={EditUser} />
          <Route path="/detail-user/:id" component={DetailUser} />

          <Route path="/add-category" component={AddCategory} />
          <Route path="/categories-list" component={CategoriesList} />
          <Route path="/edit-category/:id" component={EditCategory} />
          <Route path="/products-list" component={ProductList} />
          <Route path="/add-product" component={AddProduct} />
          <Route path="/edit-product/:id" component={UpdateProduct} />
          <Route path="/detail-product/:id" component={DetailProduct} />

          {/* <Route path ='/add-address' component={AddAddress} />
                    <Route path ='/addresses-list' component={AddressesList} />
                    <Route path ='/edit-address/:id' component={EditAddress} />
                    <Route path ='/add-delivery' component={AddDelivery} />
                    <Route path ='/deliveries-list' component={DeliveriesList} />
                    <Route path ='/edit-delivery/:id' component={EditDelivery} /> */}
          <Route path="/add-review" component={AddReview} />
          <Route path="/reviews-list" component={ReviewsList} />
          <Route path="/edit-review/:id" component={EditReview} />
          <Route path="/add-coupon" component={AddCoupon} />
          <Route path="/coupons-list" component={CouponsList} />
          <Route path="/edit-coupon/:id" component={EditCoupon} />
          <Route path="/add-brand" component={AddBrand} />
          <Route path="/brands-list" component={BrandsList} />
          <Route path="/edit-brand/:id" component={EditBrand} />

          <Route path="/add-banner" component={AddBanner} />
          <Route path="/banners-list" component={BannersList} />
          <Route path="/edit-banner/:id" component={EditBanner} />

          <Route path="/add-onboarding" component={AddOnBoarding} />
          <Route path="/onboarding-list" component={OnBoardingList} />
          <Route path="/panel" component={Panel} />
          <Route path="/add-panel" component={AddPanel} />
          <Route path="/edit-onboarding/:id" component={EditOnBoarding} />

          <Route path="/add-unit" component={AddUnit} />
          <Route path="/units-list" component={UnitList} />
          <Route path="/edit-unit/:id" component={EditUnit} />
          <Route path="/add-packet" component={AddPacket} />
          <Route path="/packets-list" component={PacketList} />
          <Route path="/edit-packet/:id" component={EditPacket} />
          <Route path="/add-variant" component={AddVariant} />
          <Route path="/variants-list" component={VariantList} />
          <Route path="/edit-variant/:id" component={EditVariant} />
          <Route path="/add-shipping" component={AddShipping} />
          <Route path="/shippings-list" component={ShippingList} />
          <Route path="/edit-shipping/:id" component={EditShipping} />
          <Route path="/add-help-option" component={AddHelpOption} />
          <Route path="/help-options-list" component={HelpOptionList} />
          <Route path="/edit-help-option/:id" component={EditHelpOption} />

          <Route path="/orders-list" component={OrderList} />
          <Route path="/edit-order/:id" component={EditOrder} />
          <Route path="/detail-order/:id" component={DetailOrder} />

          <Route path="/customer-queries" component={QueryList} />
          <Route path="/order-queries/:id" component={OrderQueries} />
          <Route path="/edit-tax/:id" component={EditTax} />
          <Route path="/taxes-list" component={TaxesList} />
          <Route path="/add-tax/" component={AddTax} />
          <Route path="/earning-reports" component={TotalEarnings} />
          <Route path="/assigned-coupon-list" component={AssignedCoupon} />
          <Route
            path="/assigned-user-coupon/:couponId"
            component={AddUserCoupon}
          />

          <Route path="/notifications-list" component={ListNotifications} />
          <Route
            path="/clone-notifications/:id"
            component={EditNotifications}
          />
          <Route path="/add-notifications" component={AddNotifications} />

          <Route path="/articles-list" component={ListArticles} />
          <Route path="/edit-articles/:id" component={EditArticles} />
          <Route path="/add-articles" component={AddArticles} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
