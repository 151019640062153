import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import Header from "../CommonTemplates/Header.js";
import Sidebar from "../CommonTemplates/SideBar.js";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { addBanner, uploadBannerImage } from "../../_actions/bannerActions.js";
import { addPannel } from "../../_actions/addPanel.js";

class AddPannel extends Component {
  state = {
    readOnly: true,
    fields: {},
    errors: {},
    imagePath: "",
  };

  async componentDidMount() {}

  // Input handle
  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ errors: "" });
    this.setState(fields);
  };

  // Validation
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["title"]) {
      formIsValid = false;
      errors["title"] = "Please enter Title.";
    }
    if (!fields["key"]) {
      formIsValid = false;
      errors["key"] = "Please enter key.";
    }
    if (!fields["value"]) {
      formIsValid = false;
      errors["value"] = "Please enter value.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  // Upload Product Image.
  onChangeFile = async (e) => {
    var file = e.target.files[0];
    var formData = new FormData();
    formData.append("brandLogo", file.name);
    formData.append("image", file);
    await this.props.uploadBannerImage(formData);
    this.setState({ imagePath: this.props.banners.imagePath });
  };

  // Handle Submit
  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      const bannerData = {
        title: this.state.fields["title"],
        key: this.state.fields["key"],
        value: this.state.fields["value"],
      };
      await addPannel(bannerData, this.props.history);
    }
  };
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <Sidebar />
            <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
              <Header />
              <div className="main-content-container container-fluid px-4">
                <div className="page-header row no-gutters py-4">
                  <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                    <span className="text-uppercase page-subtitle">
                      Overview
                    </span>
                    <h3 className="page-title">Add Pannel</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card card-small">
                      <div className="card-header border-bottom">
                        <h6 className="m-0">Panel Details</h6>
                      </div>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <div className="row">
                            <div className="col">
                              <form
                                onSubmit={this.handleSubmit}
                                enctype="multipart/form-data"
                              >
                                <div className="form-row">
                                  <div className="form-group col-md-6">
                                    <label for="feCode">Title</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="title"
                                      name="title"
                                      maxLength="35"
                                      placeholder="Enter Title"
                                      value={this.state.fields["titile"]}
                                      onChange={this.handleChange}
                                    />
                                    <span style={{ color: "red" }}>
                                      {this.state.errors["title"]}
                                    </span>
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label for="feFirstName">Key</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="key"
                                      name="key"
                                      maxLength="35"
                                      placeholder="Enter key."
                                      value={this.state.fields["key"]}
                                      onChange={this.handleChange}
                                    />
                                    <span style={{ color: "red" }}>
                                      {this.state.errors["key"]}
                                    </span>
                                  </div>
                                </div>
                                <div className="form-row">
                                  <div className="form-group col-md-6">
                                    <label for="feDescription">Value</label>
                                    <input
                                      className="form-control"
                                      name="value"
                                      placeholder="Enter panel value"
                                      onChange={this.handleChange}
                                      id="value"
                                      value={this.state.fields["value"]}
                                      rows="5"
                                    ></input>
                                    <span style={{ color: "red" }}>
                                      {this.state.errors["value"]}
                                    </span>
                                  </div>
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  Submit
                                </button>
                                &nbsp;
                                <Link to="/panel" className="btn btn-danger">
                                  Cancel
                                </Link>
                              </form>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <ToastContainer />
              {/* <Footer/> */}
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  banners: state.banners,
});
const actionCreators = {
  addBanner: addBanner,
  uploadBannerImage: uploadBannerImage,
};

export default connect(mapStateToProps, actionCreators)(AddPannel);
