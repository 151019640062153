
// Admin Auth constants.
export const POST_ADMIN_LOGIN_REQUEST = 'POST_ADMIN_LOGIN_REQUEST';
export const GET_ADMIN_LOGIN_SUCCESS = 'GET_ADMIN_LOGIN_SUCCESS';
export const GET_ADMIN_LOGIN_FAILURE = 'GET_ADMIN_LOGIN_FAILURE';
export const POST_CHANGE_PASSWORD_REQUEST = 'POST_CHANGE_PASSWORD_REQUEST';
export const GET_CHANGE_PASSWORD_SUCCESS = 'GET_CHANGE_PASSWORD_SUCCESS';
export const GET_CHANGE_PASSWORD_FAILURE = 'GET_CHANGE_PASSWORD_FAILURE';
export const POST_UPLOAD_PHOTO_REQUEST = 'POST_UPLOAD_PHOTO_REQUEST';
export const GET_UPLOAD_PHOTO_SUCCESS = 'GET_UPLOAD_PHOTO_SUCCESS';
export const GET_UPLOAD_PHOTO_FAILURE = 'GET_UPLOAD_PHOTO_FAILURE';
export const POST_FORGET_PASSWORD_REQUEST = 'POST_FORGET_PASSWORD_REQUEST';
export const POST_FORGET_PASSWORD_SUCCESS = 'POST_FORGET_PASSWORD_SUCCESS';
export const POST_FORGET_PASSWORD_FAILURE = 'POST_FORGET_PASSWORD_FAILURE';


// User Constants.
export const POST_ADD_USER_REQUEST = 'POST_ADD_USER_REQUEST';
export const GET_ADD_USER_SUCCESS = 'GET_ADD_USER_SUCCESS';
export const GET_ADD_USER_FAILURE = 'GET_ADD_USER_FAILURE';
export const POST_USERS_REQUEST = 'POST_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const POST_USER_DELEET_REQUEST = 'POST_USER_DELEET_REQUEST';
export const GET_USER_DELETE_SUCCESS = 'GET_USER_DELETE_SUCCESS';
export const GET_USER_DELETE_FAILURE = 'GET_USER_DELETE_FAILURE';
export const POST_USER_DETAILS_REQUEST = 'POST_USER_DETAILS_REQUEST';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILURE = 'GET_USER_DETAILS_FAILURE';
export const POST_USER_UPDATE_REQUEST = 'POST_USER_UPDATE_REQUEST';
export const GET_USER_UPDATE_SUCCESS = 'GET_USER_UPDATE_SUCCESS';
export const GET_USER_UPDATE_FAILURE = 'GET_USER_UPDATE_FAILURE';
export const POST_USER_PHOTO_REQUEST = 'POST_USER_PHOTO_REQUEST';
export const GET_USER_PHOTO_SUCCESS = 'GET_USER_PHOTO_SUCCESS';
export const GET_USER_PHOTO_FAILURE = 'GET_USER_PHOTO_FAILURE';

// Category Constants.
export const POST_ADD_CATEGORY_REQUEST = 'POST_ADD_CATEGORY_REQUEST';
export const GET_ADD_CATEGORY_SUCCESS = 'GET_ADD_CATEGORY_SUCCESS';
export const GET_ADD_CATEGORY_FAILURE = 'GET_ADD_CATEGORY_FAILURE';
export const POST_CATEGORIES_REQUEST = 'POST_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE';
export const POST_CATEGORY_GET_REQUEST = 'POST_CATEGORY_GET_REQUEST';
export const GET_CATEGORY_GET_SUCCESS = 'GET_CATEGORY_GET_SUCCESS';
export const GET_CATEGORY_GET_FAILURE = 'GET_CATEGORY_GET_FAILURE';
export const POST_UPDATE_CATEGORY_REQUEST = 'POST_UPDATE_CATEGORY_REQUEST';
export const GET_UPDATE_CATEGORY_SUCCESS = 'GET_UPDATE_CATEGORY_SUCCESS';
export const GET_UPDATE_CATEGORY_FAILURE = 'GET_UPDATE_CATEGORY_FAILURE';
export const POST_CATEGORY_DELETE_REQUEST = 'POST_CATEGORY_DELETE_REQUEST';
export const GET_CATEGORY_DELETE_SUCCESS = 'GET_CATEGORY_DELETE_SUCCESS';
export const GET_CATEGORY_DELETE_FAILURE = 'GET_CATEGORY_DELETE_FAILURE';
export const POST_CATEGORY_IMAGE_REQUEST = 'POST_CATEGORY_IMAGE_REQUEST';
export const GET_CATEGORY_IMAGE_SUCCESS = 'GET_CATEGORY_IMAGE_SUCCESS';
export const GET_CATEGORY_IMAGE_FAILURE = 'GET_CATEGORY_IMAGE_FAILURE';
export const POST_PARENT_CATEGORIES_REQUEST = 'POST_PARENT_CATEGORIES_REQUEST';
export const GET_PARENT_CATEGORIES_SUCCESS = 'GET_PARENT_CATEGORIES_SUCCESS';
export const GET_PARENT_CATEGORIES_FAILURE = 'GET_PARENT_CATEGORIES_FAILURE';
export const POST_SUB_CATEGORIES_REQUEST = 'POST_SUB_CATEGORIES_REQUEST';
export const GET_SUB_CATEGORIES_SUCCESS = 'GET_SUB_CATEGORIES_SUCCESS';
export const GET_SUB_CATEGORIES_FAILURE = 'GET_SUB_CATEGORIES_FAILURE';

// Product Constants.
export const POST_PRODUCTS_REQUEST = 'POST_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';
export const POST_ACTIVE_PRODUCTS_REQUEST = 'POST_ACTIVE_PRODUCTS_REQUEST';
export const GET_ACTIVE_PRODUCTS_SUCCESS = 'GET_ACTIVE_PRODUCTS_SUCCESS';
export const GET_ACTIVE_PRODUCTS_FAILURE = 'GET_ACTIVE_PRODUCTS_FAILURE';
export const POST_ADD_PRODUCT_REQUEST = 'POST_ADD_PRODUCT_REQUEST';
export const GET_ADD_PRODUCT_SUCCESS = 'GET_ADD_PRODUCT_SUCCESS';
export const GET_ADD_PRODUCT_FAILURE = 'GET_ADD_PRODUCT_FAILURE';
export const POST_PRODUCT_REQUEST = 'POST_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';
export const POST_REMOVE_PRODUCT_REQUEST = 'POST_REMOVE_PRODUCT_REQUEST';
export const GET_REMOVE_PRODUCT_SUCCESS = 'GET_REMOVE_PRODUCT_SUCCESS';
export const GET_REMOVE_PRODUCT_FAILURE = 'GET_REMOVE_PRODUCT_FAILURE';
export const POST_UPDATE_PRODUCT_REQUEST = 'POST_UPDATE_PRODUCT_REQUEST';
export const GET_UPDATE_PRODUCT_SUCCESS = 'GET_UPDATE_PRODUCT_SUCCESS';
export const GET_UPDATE_PRODUCT_FAILURE = 'GET_UPDATE_PRODUCT_FAILURE';
export const POST_PRODUCT_IMAGE_REQUEST = 'POST_PRODUCT_IMAGE_REQUEST';
export const GET_PRODUCT_IMAGE_SUCCESS = 'GET_PRODUCT_IMAGE_SUCCESS';
export const GET_PRODUCT_IMAGE_FAILURE = 'GET_PRODUCT_IMAGE_FAILURE';
export const POST_POPULAR_PRODUCT_REQUEST = 'POST_POPULAR_PRODUCT_REQUEST';
export const GET_POPULAR_PRODUCT_SUCCESS = 'GET_POPULAR_PRODUCT_SUCCESS';
export const GET_POPULAR_PRODUCT_FAILURE = 'GET_POPULAR_PRODUCT_FAILURE';
export const POST_REMOVE_PRO_IMG_REQUEST = 'POST_REMOVE_PRO_IMG_REQUEST';
export const GET_REMOVE_PRO_IMG_SUCCESS = 'GET_REMOVE_PRO_IMG_SUCCESS';
export const GET_REMOVE_PRO_IMG_FAILURE = 'GET_REMOVE_PRO_IMG_FAILURE';

// Address Constants.
export const POST_ADD_ADDRESS_REQUEST = 'POST_ADD_ADDRESS_REQUEST';
export const GET_ADD_ADDRESS_SUCCESS = 'GET_ADD_ADDRESS_SUCCESS';
export const GET_ADD_ADDRESS_FAILURE = 'GET_ADD_ADDRESS_FAILURE';
export const POST_ADDRESSES_REQUEST = 'POST_ADDRESSES_REQUEST';
export const GET_ADDRESSES_SUCCESS = 'GET_ADDRESSES_SUCCESS';
export const GET_ADDRESSES_FAILURE = 'GET_ADDRESSES_FAILURE';
export const POST_ADDRESS_REQUEST = 'POST_ADDRESS_REQUEST';
export const GET_ADDRESS_SUCCESS = 'GET_ADDRESS_SUCCESS';
export const GET_ADDRESS_FAILURE = 'GET_ADDRESS_FAILURE';
export const POST_REMOVE_ADDRESS_REQUEST = 'POST_REMOVE_ADDRESS_REQUEST';
export const GET_REMOVE_ADDRESS_SUCCESS = 'GET_REMOVE_ADDRESS_SUCCESS';
export const GET_REMOVE_ADDRESS_FAILURE = 'GET_REMOVE_ADDRESS_FAILURE';
export const POST_UPDATE_ADDRESS_REQUEST = 'POST_UPDATE_PRODUCT_REQUEST';
export const GET_UPDATE_ADDRESS_SUCCESS = 'GET_UPDATE_PRODUCT_SUCCESS';
export const GET_UPDATE_ADDRESS_FAILURE = 'GET_UPDATE_PRODUCT_FAILURE';

// Review Constants.
export const POST_ADD_REVIEW_REQUEST = 'POST_ADD_REVIEW_REQUEST';
export const GET_ADD_REVIEW_SUCCESS = 'GET_ADD_REVIEW_SUCCESS';
export const GET_ADD_REVIEW_FAILURE = 'GET_ADD_REVIEW_FAILURE';
export const POST_REVIEWS_REQUEST = 'POST_REVIEWS_REQUEST';
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS';
export const GET_REVIEWS_FAILURE = 'GET_REVIEWS_FAILURE';
export const POST_REVIEW_REQUEST = 'POST_REVIEW_REQUEST';
export const GET_REVIEW_SUCCESS = 'GET_REVIEW_SUCCESS';
export const GET_REVIEW_FAILURE = 'GET_REVIEW_FAILURE';
export const POST_UPDATE_REVIEW_REQUEST = 'POST_UPDATE_REVIEW_REQUEST';
export const GET_UPDATE_REVIEW_SUCCESS = 'GET_UPDATE_REVIEW_SUCCESS';
export const GET_UPDATE_REVIEW_FAILURE = 'GET_UPDATE_REVIEW_FAILURE';
export const POST_REMOVE_REVIEW_REQUEST = 'POST_REMOVE_REVIEW_REQUEST';
export const GET_REMOVE_REVIEW_SUCCESS = 'GET_REMOVE_REVIEW_SUCCESS';
export const GET_REMOVE_REVIEW_FAILURE = 'GET_REMOVE_REVIEW_FAILURE';

// Delivery Constants.
export const POST_ADD_DELIVERY_REQUEST = 'POST_ADD_DELIVERY_REQUEST';
export const GET_ADD_DELIVERY_SUCCESS = 'GET_ADD_DELIVERY_SUCCESS';
export const GET_ADD_DELIVERY_FAILURE = 'GET_ADD_DELIVERY_FAILURE';
export const POST_DELIVERIES_REQUEST = 'POST_DELIVERIES_REQUEST';
export const GET_DELIVERIES_SUCCESS = 'GET_DELIVERIES_SUCCESS';
export const GET_DELIVERIES_FAILURE = 'GET_DELIVERIES_FAILURE';
export const POST_DELIVERY_REQUEST = 'POST_DELIVERY_REQUEST';
export const GET_DELIVERY_SUCCESS = 'GET_DELIVERY_SUCCESS';
export const GET_DELIVERY_FAILURE = 'GET_DELIVERY_FAILURE';
export const POST_UPDATE_DELIVERY_REQUEST = 'POST_UPDATE_DELIVERY_REQUEST';
export const GET_UPDATE_DELIVERY_SUCCESS = 'GET_UPDATE_DELIVERY_SUCCESS';
export const GET_UPDATE_DELIVERY_FAILURE = 'GET_UPDATE_DELIVERY_FAILURE';
export const POST_REMOVE_DELIVERY_REQUEST = 'POST_REMOVE_DELIVERY_REQUEST';
export const GET_REMOVE_DELIVERY_SUCCESS = 'GET_REMOVE_DELIVERY_SUCCESS';
export const GET_REMOVE_DELIVERY_FAILURE = 'GET_REMOVE_DELIVERY_FAILURE';

// Coupon Constants.
export const POST_ADD_COUPON_REQUEST = 'POST_ADD_COUPON_REQUEST';
export const GET_ADD_COUPON_SUCCESS = 'GET_ADD_COUPON_SUCCESS';
export const GET_ADD_COUPON_FAILURE = 'GET_ADD_COUPON_FAILURE';
export const POST_COUPONS_REQUEST = 'POST_COUPONS_REQUEST';
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS';
export const GET_COUPONS_FAILURE = 'GET_COUPONS_FAILURE';
export const POST_COUPON_REQUEST = 'POST_COUPON_REQUEST';
export const GET_COUPON_SUCCESS = 'GET_COUPON_SUCCESS';
export const GET_COUPON_FAILURE = 'GET_COUPON_FAILURE';
export const POST_REMOVE_COUPON_REQUEST = 'POST_REMOVE_COUPON_REQUEST';
export const GET_REMOVE_COUPON_SUCCESS = 'GET_REMOVE_COUPON_SUCCESS';
export const GET_REMOVE_COUPON_FAILURE = 'GET_REMOVE_COUPON_FAILURE';
export const POST_UPDATE_COUPON_REQUEST = 'POST_UPDATE_COUPON_REQUEST';
export const GET_UPDATE_COUPON_SUCCESS = 'GET_UPDATE_COUPON_SUCCESS';
export const GET_UPDATE_COUPON_FAILURE = 'GET_UPDATE_COUPON_FAILURE';

// Brand Constants.
export const POST_ADD_BRAND_REQUEST = 'POST_ADD_BRAND_REQUEST';
export const GET_ADD_BRAND_SUCCESS = 'GET_ADD_BRAND_SUCCESS';
export const GET_ADD_BRAND_FAILURE = 'GET_ADD_BRAND_FAILURE';
export const POST_BRANDS_REQUEST = 'POST_BRANDS_REQUEST';
export const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS';
export const GET_BRANDS_FAILURE = 'GET_BRANDS_FAILURE';
export const POST_BRAND_REQUEST = 'POST_BRAND_REQUEST';
export const GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS';
export const GET_BRAND_FAILURE = 'GET_BRAND_FAILURE';
export const POST_UPDATE_BRAND_REQUEST = 'POST_UPDATE_BRAND_REQUEST';
export const GET_UPDATE_BRAND_SUCCESS = 'GET_UPDATE_BRAND_SUCCESS';
export const GET_UPDATE_BRAND_FAILURE = 'GET_UPDATE_BRAND_FAILURE';
export const POST_REMOVE_BRAND_REQUEST = 'POST_REMOVE_BRAND_REQUEST';
export const GET_REMOVE_BRAND_SUCCESS = 'GET_REMOVE_BRAND_SUCCESS';
export const GET_REMOVE_BRAND_FAILURE = 'GET_REMOVE_BRAND_FAILURE';
export const POST_BRAND_LOGO_REQUEST = 'POST_BRAND_LOGO_REQUEST';
export const GET_BRAND_LOGO_SUCCESS = 'GET_BRAND_LOGO_SUCCESS';
export const GET_BRAND_LOGO_FAILURE = 'GET_BRAND_LOGO_FAILURE';

// Banner constants
export const POST_BANNER_ADD_REQUEST = 'POST_BANNER_ADD_REQUEST';
export const GET_BANNER_ADD_SUCCESS = 'POST_BANNER_ADD_SUCCESS';
export const GET_BANNER_ADD_FAILURE = 'POST_BANNER_ADD_FAILURE';
export const POST_BANNERS_REQUEST = 'POST_BANNERS_REQUEST';
export const GET_BANNERS_SUCCESS = 'GET_BANNERS_SUCCESS';
export const GET_BANNERS_FAILURE = 'GET_BANNERS_FAILURE';
export const POST_BANNER_REQUEST = 'POST_BANNER_REQUEST';
export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
export const GET_BANNER_FAILURE = 'GET_BANNER_FAILURE';
export const POST_REMOVE_BANNER_REQUEST = 'POST_REMOVE_BANNER_REQUEST';
export const GET_REMOVE_BANNER_SUCCESS = 'GET_REMOVE_BANNER_SUCCESS';
export const GET_REMOVE_BANNER_FAILURE = 'GET_REMOVE_BANNER_FAILURE';
export const POST_UPDATE_BANNER_REQUEST = 'POST_UPDATE_BANNER_REQUEST';
export const GET_UPDATE_BANNER_SUCCESS = 'GET_UPDATE_BANNER_SUCCESS';
export const GET_UPDATE_BANNER_FAILURE = 'GET_UPDATE_BANNER_FAILURE';
export const POST_BANNER_IMAGE_REQUEST = 'POST_BANNER_IMAGE_REQUEST';
export const GET_BANNER_IMAGE_SUCCESS = 'GET_BANNER_IMAGE_SUCCESS';
export const GET_BANNER_IMAGE_FAILURE = 'GET_BANNER_IMAGE_FAILURE';


// Banner constants
export const POST_ONBOARDING_ADD_REQUEST = 'POST_ONBOARDING_ADD_REQUEST';
export const GET_ONBOARDING_ADD_SUCCESS = 'POST_ONBOARDING_ADD_SUCCESS';
export const GET_ONBOARDING_ADD_FAILURE = 'POST_ONBOARDING_ADD_FAILURE';
export const POST_ONBOARDINGS_REQUEST = 'POST_ONBOARDINGS_REQUEST';
export const GET_ONBOARDINGS_SUCCESS = 'GET_ONBOARDINGS_SUCCESS';
export const GET_ONBOARDINGS_FAILURE = 'GET_ONBOARDINGS_FAILURE';
export const POST_ONBOARDING_REQUEST = 'POST_ONBOARDING_REQUEST';
export const GET_ONBOARDING_SUCCESS = 'GET_ONBOARDING_SUCCESS';
export const GET_ONBOARDING_FAILURE = 'GET_ONBOARDING_FAILURE';
export const POST_REMOVE_ONBOARDING_REQUEST = 'POST_REMOVE_ONBOARDING_REQUEST';
export const GET_REMOVE_ONBOARDING_SUCCESS = 'GET_REMOVE_ONBOARDING_SUCCESS';
export const GET_REMOVE_ONBOARDING_FAILURE = 'GET_REMOVE_ONBOARDING_FAILURE';
export const POST_UPDATE_ONBOARDING_REQUEST = 'POST_UPDATE_ONBOARDING_REQUEST';
export const GET_UPDATE_ONBOARDING_SUCCESS = 'GET_UPDATE_ONBOARDING_SUCCESS';
export const GET_UPDATE_ONBOARDING_FAILURE = 'GET_UPDATE_ONBOARDING_FAILURE';
export const POST_ONBOARDING_IMAGE_REQUEST = 'POST_ONBOARDING_IMAGE_REQUEST';
export const GET_ONBOARDING_IMAGE_SUCCESS = 'GET_ONBOARDING_IMAGE_SUCCESS';
export const GET_ONBOARDING_IMAGE_FAILURE = 'GET_ONBOARDING_IMAGE_FAILURE';



// Unit constants
export const POST_UNIT_ADD_REQUEST = 'POST_UNIT_ADD_REQUEST';
export const GET_UNIT_ADD_SUCCESS = 'GET_UNIT_ADD_SUCCESS';
export const GET_UNIT_ADD_FAILURE = 'GET_UNIT_ADD_FAILURE';
export const POST_UNITS_REQUEST = 'POST_UNITS_REQUEST';
export const GET_UNITS_SUCCESS = 'GET_UNITS_SUCCESS';
export const GET_UNITS_FAILURE = 'GET_UNITS_FAILURE';
export const POST_ACTIVE_UNITS_REQUEST = 'POST_ACTIVE_UNITS_REQUEST';
export const GET_ACTIVE_UNITS_SUCCESS = 'GET_ACTIVE_UNITS_SUCCESS';
export const GET_ACTIVE_UNITS_FAILURE = 'GET_ACTIVE_UNITS_FAILURE';
export const POST_UNIT_REQUEST = 'POST_UNIT_REQUEST';
export const GET_UNIT_SUCCESS = 'GET_UNIT_SUCCESS';
export const GET_UNIT_FAILURE = 'GET_UNIT_FAILURE';
export const POST_REMOVE_UNIT_REQUEST = 'POST_REMOVE_UNIT_REQUEST';
export const GET_REMOVE_UNIT_SUCCESS = 'GET_REMOVE_UNIT_SUCCESS';
export const GET_REMOVE_UNIT_FAILURE = 'GET_REMOVE_UNIT_FAILURE';
export const POST_UPDATE_UNIT_REQUEST = 'POST_UPDATE_UNIT_REQUEST';
export const GET_UPDATE_UNIT_SUCCESS = 'GET_UPDATE_UNIT_SUCCESS';
export const GET_UPDATE_UNIT_FAILURE = 'GET_UPDATE_UNIT_FAILURE';

// Packet constants
export const POST_PACKET_ADD_REQUEST = 'POST_PACKET_ADD_REQUEST';
export const GET_PACKET_ADD_SUCCESS = 'GET_PACKET_ADD_SUCCESS';
export const GET_PACKET_ADD_FAILURE = 'GET_PACKET_ADD_FAILURE';
export const POST_ACTIVE_PACKETS_REQUEST = 'POST_ACTIVE_PACKETS_REQUEST';
export const GET_ACTIVE_PACKETS_SUCCESS = 'GET_ACTIVE_PACKETS_SUCCESS';
export const GET_ACTIVE_PACKETS_FAILURE = 'GET_ACTIVE_PACKETS_FAILURE';
export const POST_PACKETS_REQUEST = 'POST_PACKETS_REQUEST';
export const GET_PACKETS_SUCCESS = 'GET_PACKETS_SUCCESS';
export const GET_PACKETS_FAILURE = 'GET_PACKETS_FAILURE';
export const POST_PACKET_REQUEST = 'POST_PACKET_REQUEST';
export const GET_PACKET_SUCCESS = 'GET_PACKET_SUCCESS';
export const GET_PACKET_FAILURE = 'GET_PACKET_FAILURE';
export const POST_REMOVE_PACKET_REQUEST = 'POST_REMOVE_PACKET_REQUEST';
export const GET_REMOVE_PACKET_SUCCESS = 'GET_REMOVE_PACKET_SUCCESS';
export const GET_REMOVE_PACKET_FAILURE = 'GET_REMOVE_PACKET_FAILURE';
export const POST_UPDATE_PACKET_REQUEST = 'POST_UPDATE_PACKET_REQUEST';
export const GET_UPDATE_PACKET_SUCCESS = 'GET_UPDATE_PACKET_SUCCESS';
export const GET_UPDATE_PACKET_FAILURE = 'GET_UPDATE_PACKET_FAILURE';

// Product variants constants
export const POST_PROD_VARIANT_ADD_REQUEST = 'POST_PROD_VARIANT_ADD_REQUEST';
export const GET_PROD_VARIANT_ADD_SUCCESS = 'GET_PROD_VARIANT_ADD_SUCCESS';
export const GET_PROD_VARIANT_ADD_FAILURE = 'GET_PROD_VARIANT_ADD_FAILURE';
export const POST_PROD_VARIANTS_REQUEST = 'POST_PROD_VARIANTS_REQUEST';
export const GET_PROD_VARIANTS_SUCCESS = 'GET_PROD_VARIANTS_SUCCESS';
export const GET_PROD_VARIANTS_FAILURE = 'GET_PROD_VARIANTS_FAILURE';
export const POST_PROD_VARIANT_REQUEST = 'POST_PROD_VARIANT_REQUEST';
export const GET_PROD_VARIANT_SUCCESS = 'GET_PROD_VARIANT_SUCCESS';
export const GET_PROD_VARIANT_FAILURE = 'GET_PROD_VARIANT_FAILURE';
export const POST_REMOVE_PROD_VARIANT_REQUEST = 'POST_REMOVE_PROD_VARIANT_REQUEST';
export const GET_REMOVE_PROD_VARIANT_SUCCESS = 'GET_REMOVE_PROD_VARIANT_SUCCESS';
export const GET_REMOVE_PROD_VARIANT_FAILURE = 'GET_REMOVE_PROD_VARIANT_FAILURE';
export const POST_UPDATE_PROD_VARIANT_REQUEST = 'POST_UPDATE_PROD_VARIANT_REQUEST';
export const GET_UPDATE_PROD_VARIANT_SUCCESS = 'GET_UPDATE_PROD_VARIANT_SUCCESS';
export const GET_UPDATE_PROD_VARIANT_FAILURE = 'GET_UPDATE_PROD_VARIANT_FAILURE';

// Shipping charges constants.
export const POST_SHIPPING_CHARGE_ADD_REQUEST = 'POST_SHIPPING_CHARGE_ADD_REQUEST';
export const GET_SHIPPING_CHARGE_ADD_SUCCESS = 'GET_SHIPPING_CHARGE_ADD_SUCCESS';
export const GET_SHIPPING_CHARGE_ADD_FAILURE = 'GET_SHIPPING_CHARGE_ADD_FAILURE';
export const POST_SHIPPING_CHARGES_REQUEST = 'POST_SHIPPING_CHARGES_REQUEST';
export const GET_SHIPPING_CHARGES_SUCCESS = 'GET_SHIPPING_CHARGES_SUCCESS';
export const GET_SHIPPING_CHARGES_FAILURE = 'GET_SHIPPING_CHARGES_FAILURE';
export const POST_SHIPPING_CHARGE_REQUEST = 'POST_SHIPPING_CHARGE_REQUEST';
export const GET_SHIPPING_CHARGE_SUCCESS = 'GET_SHIPPING_CHARGE_SUCCESS';
export const GET_SHIPPING_CHARGE_FAILURE = 'GET_SHIPPING_CHARGE_FAILURE';
export const POST_REMOVE_SHIPPING_CHARGE_REQUEST = 'POST_REMOVE_SHIPPING_CHARGE_REQUEST';
export const GET_REMOVE_SHIPPING_CHARGE_SUCCESS = 'GET_REMOVE_SHIPPING_CHARGE_SUCCESS';
export const GET_REMOVE_SHIPPING_CHARGE_FAILURE = 'GET_REMOVE_SHIPPING_CHARGE_FAILURE';
export const POST_UPDATE_SHIPPING_CHARGE_REQUEST = 'POST_UPDATE_SHIPPING_CHARGE_REQUEST';
export const GET_UPDATE_SHIPPING_CHARGE_SUCCESS = 'GET_UPDATE_SHIPPING_CHARGE_SUCCESS';
export const GET_UPDATE_SHIPPING_CHARGE_FAILURE = 'GET_UPDATE_SHIPPING_CHARGE_FAILURE';

// Customer's help option constants.
export const POST_CUSTOMER_HELP_OPTION_ADD_REQUEST = 'POST_CUSTOMER_HELP_OPTION_ADD_REQUEST';
export const GET_CUSTOMER_HELP_OPTION_ADD_SUCCESS = 'GET_CUSTOMER_HELP_OPTION_ADD_SUCCESS';
export const GET_CUSTOMER_HELP_OPTION_ADD_FAILURE = 'GET_CUSTOMER_HELP_OPTION_ADD_FAILURE';
export const POST_CUSTOMER_HELP_OPTIONS_REQUEST = 'POST_CUSTOMER_HELP_OPTIONS_REQUEST';
export const GET_CUSTOMER_HELP_OPTIONS_SUCCESS = 'GET_CUSTOMER_HELP_OPTIONS_SUCCESS';
export const GET_CUSTOMER_HELP_OPTIONS_FAILURE = 'GET_CUSTOMER_HELP_OPTIONS_FAILURE';
export const POST_CUSTOMER_HELP_OPTION_REQUEST = 'POST_CUSTOMER_HELP_OPTION_REQUEST';
export const GET_CUSTOMER_HELP_OPTION_SUCCESS = 'GET_CUSTOMER_HELP_OPTION_SUCCESS';
export const GET_CUSTOMER_HELP_OPTION_FAILURE = 'GET_CUSTOMER_HELP_OPTION_FAILURE';
export const POST_REMOVE_CUSTOMER_HELP_OPTION_REQUEST = 'POST_REMOVE_CUSTOMER_HELP_OPTION_REQUEST';
export const GET_REMOVE_CUSTOMER_HELP_OPTION_SUCCESS = 'GET_REMOVE_CUSTOMER_HELP_OPTION_SUCCESS';
export const GET_REMOVE_CUSTOMER_HELP_OPTION_FAILURE = 'GET_REMOVE_CUSTOMER_HELP_OPTION_FAILURE';
export const POST_UPDATE_CUSTOMER_HELP_OPTION_REQUEST = 'POST_UPDATE_CUSTOMER_HELP_OPTION_REQUEST';
export const GET_UPDATE_CUSTOMER_HELP_OPTION_SUCCESS = 'GET_UPDATE_CUSTOMER_HELP_OPTION_SUCCESS';
export const GET_UPDATE_CUSTOMER_HELP_OPTION_FAILURE = 'GET_UPDATE_CUSTOMER_HELP_OPTION_FAILURE';

// Order's constants.
export const POST_ORDERS_REQUEST = 'POST_ORDERS_REQUEST';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE';
export const POST_ORDER_REQUEST = 'POST_ORDER_REQUEST';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE';
export const POST_REMOVE_ORDER_REQUEST = 'GET_ORDER_FAILURE';
export const GET_REMOVE_ORDER_SUCCESS = 'POST_REMOVE_ORDER_REQUEST';
export const GET_REMOVE_ORDER_FAILURE = 'GET_REMOVE_ORDER_FAILURE';
export const POST_UPDATE_ORDER_REQUEST = 'POST_UPDATE_ORDER_REQUEST';
export const GET_UPDATE_ORDER_SUCCESS = 'GET_UPDATE_ORDER_SUCCESS';
export const GET_UPDATE_ORDER_FAILURE = 'GET_UPDATE_ORDER_FAILURE';

// Customer query's constants.
export const POST_CUSTOMER_QUERIES_REQUEST = 'POST_CUSTOMER_QUERIES_REQUEST';
export const GET_CUSTOMER_QUERIES_SUCCESS = 'GET_CUSTOMER_QUERIES_SUCCESS';
export const GET_CUSTOMER_QUERIES_FAILURE = 'GET_CUSTOMER_QUERIES_FAILURE';
export const POST_CUSTOMER_QUERY_REQUEST = 'POST_CUSTOMER_QUERY_REQUEST';
export const GET_CUSTOMER_QUERY_SUCCESS = 'GET_CUSTOMER_QUERY_SUCCESS';
export const GET_CUSTOMER_QUERY_FAILURE = 'GET_CUSTOMER_QUERY_FAILURE';
export const POST_QUERY_RESPONSE_REQUEST = 'POST_QUERY_RESPONSE_REQUEST';
export const GET_QUERY_RESPONSE_SUCCESS = 'GET_QUERY_RESPONSE_SUCCESS';
export const GET_QUERY_RESPONSE_FAILURE = 'GET_QUERY_RESPONSE_FAILURE';

// Taxes constants.
export const POST_ADD_TAX_REQUEST = 'POST_ADD_TAX_REQUEST';
export const GET_ADD_TAX_SUCCESS = 'GET_ADD_TAX_SUCCESS';
export const GET_ADD_TAX_FAILURE = 'GET_ADD_TAX_FAILURE';
export const POST_TAX_REQUEST = 'POST_TAX_REQUEST';
export const GET_TAX_SUCCESS = 'GET_TAX_SUCCESS';
export const GET_TAX_FAILURE = 'GET_TAX_FAILURE';
export const POST_TAXES_REQUEST = 'POST_TAXES_REQUEST';
export const GET_TAXES_SUCCESS = 'GET_TAXES_SUCCESS';
export const GET_TAXES_FAILURE = 'GET_TAXES_FAILURE';
export const POST_REMOVE_TAX_REQUEST = 'POST_REMOVE_TAX_REQUEST';
export const GET_REMOVE_TAX_SUCCESS = 'GET_REMOVE_TAX_SUCCESS';
export const GET_REMOVE_TAX_FAILURE = 'GET_REMOVE_TAX_FAILURE';
export const POST_UPDATE_TAX_REQUEST = 'POST_UPDATE_TAX_REQUEST';
export const GET_UPDATE_TAX_SUCCESS = 'GET_UPDATE_TAX_SUCCESS';
export const GET_UPDATE_TAX_FAILURE = 'GET_UPDATE_TAX_FAILURE';
export const POST_ACTIVE_TAXES_REQUEST = 'POST_ACTIVE_TAXES_REQUEST';
export const GET_ACTIVE_TAXES_SUCCESS = 'GET_ACTIVE_TAXES_SUCCESS';
export const GET_ACTIVE_TAXES_FAILURE = 'GET_ACTIVE_TAXES_FAILURE';

// Dashboard constants.
export const POST_DASHBOARD_REQUEST = 'POST_DASHBOARD_REQUEST';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE';

// Report constants.
export const POST_EARNING_REPORT_REQUEST = 'POST_EARNING_REPORT_REQUEST';
export const GET_EARNING_REPORT_SUCCESS = 'GET_EARNING_REPORT_SUCCESS';
export const GET_EARNING_REPORT_FAILURE = 'GET_EARNING_REPORT_FAILURE';

// check coupon assigned.
export const POST_CHECK_COUPON_REQUEST = 'POST_CHECK_COUPON_REQUEST';
export const POST_CHECK_COUPON_SUCCESS = 'POST_CHECK_COUPON_SUCCESS';
export const POST_CHECK_COUPON_FAILURE = 'POST_CHECK_COUPON_FAILURE';

// coupon assigned user list.
export const GET_ASSIGNED_COUPON_REQUEST = 'GET_ASSIGNED_COUPON_REQUEST';
export const GET_ASSIGNED_COUPON_SUCCESS = 'GET_ASSIGNED_COUPON_SUCCESS';
export const GET_ASSIGNED_COUPON_FAILURE = 'GET_ASSIGNED_COUPON_FAILURE';


// coupon assigned submit
export const POST_ASSIGNED_COUPON_REQUEST = 'POST_ASSIGNED_COUPON_REQUEST';
export const POST_ASSIGNED_COUPON_SUCCESS = 'POST_ASSIGNED_COUPON_SUCCESS';
export const POST_ASSIGNED_COUPON_FAILURE = 'POST_ASSIGNED_COUPON_FAILURE';


// all coupen list coupon assigned 
export const GET_ASSIGNED_ALL_COUPON_REQUEST = 'GET_ASSIGNED_ALL_COUPON_REQUEST';
export const GET_ASSIGNED_ALL_COUPON_SUCCESS = 'GET_ASSIGNED_ALL_COUPON_SUCCESS';
export const GET_ASSIGNED_ALL_COUPON_FAILURE = 'GET_ASSIGNED_ALL_COUPON_FAILURE';

// get all users request
export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';




