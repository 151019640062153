import {
    POST_ADD_ADDRESS_REQUEST, GET_ADD_ADDRESS_SUCCESS, GET_ADD_ADDRESS_FAILURE,
    POST_ADDRESSES_REQUEST, GET_ADDRESSES_SUCCESS, GET_ADDRESSES_FAILURE,
    POST_ADDRESS_REQUEST, GET_ADDRESS_SUCCESS, GET_ADDRESS_FAILURE,
    POST_UPDATE_ADDRESS_REQUEST, GET_UPDATE_ADDRESS_SUCCESS, GET_UPDATE_ADDRESS_FAILURE,
    POST_REMOVE_ADDRESS_REQUEST, GET_REMOVE_ADDRESS_SUCCESS, GET_REMOVE_ADDRESS_FAILURE
} from '../_actions/_type';
export const initialState = {
    addressUpdated: false,
    addressCreated: false,
    addressDeleted: false,
    address: [],
    addresses: [],
    errors: {}
};
export default function addresses (state = initialState, action) {
    switch (action.type) {
        case POST_ADD_ADDRESS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_ADD_ADDRESS_SUCCESS:
            return {
                ...state,
                addressCreated: true,
                loading: false
            };
        case GET_ADD_ADDRESS_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        case POST_ADDRESSES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_ADDRESSES_SUCCESS:
            return {
                ...state,
                addresses: action.payload,
                loading: false
            };
        case GET_ADDRESSES_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        case POST_ADDRESS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_ADDRESS_SUCCESS:
            return {
                ...state,
                address: action.payload,
                loading: false
            };
        case GET_ADDRESS_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        case POST_REMOVE_ADDRESS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_REMOVE_ADDRESS_SUCCESS:
            return {
                ...state,
                addressDeleted: true,
                loading: false
            };
        case GET_REMOVE_ADDRESS_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        case POST_UPDATE_ADDRESS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_UPDATE_ADDRESS_SUCCESS:
            return {
                ...state,
                addressUpdated: true,
                loading: false
            };
        case GET_UPDATE_ADDRESS_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        default:
            return state;
    }
}
