import {
    POST_ADD_COUPON_REQUEST, GET_ADD_COUPON_SUCCESS, GET_ADD_COUPON_FAILURE,
    POST_COUPONS_REQUEST, GET_COUPONS_SUCCESS, GET_COUPONS_FAILURE,
    POST_COUPON_REQUEST, GET_COUPON_SUCCESS, GET_COUPON_FAILURE,
    POST_REMOVE_COUPON_REQUEST, GET_REMOVE_COUPON_SUCCESS, GET_REMOVE_COUPON_FAILURE,
    POST_UPDATE_COUPON_REQUEST, GET_UPDATE_COUPON_SUCCESS, GET_UPDATE_COUPON_FAILURE,
    POST_CHECK_COUPON_REQUEST, POST_CHECK_COUPON_SUCCESS, POST_CHECK_COUPON_FAILURE,
    GET_ALL_USERS_REQUEST, GET_ALL_USERS_SUCCESS, GET_ALL_USERS_FAILURE,
    POST_ASSIGNED_COUPON_REQUEST, POST_ASSIGNED_COUPON_SUCCESS, POST_ASSIGNED_COUPON_FAILURE,
    GET_ASSIGNED_COUPON_REQUEST, GET_ASSIGNED_COUPON_SUCCESS, GET_ASSIGNED_COUPON_FAILURE

} from '../_actions/_type';
import {
    URL_ADMIN_ADD_COUPON, URL_ADMIN_GET_SINGLE_COUPON, URL_ADMIN_GET_COUPONS_LIST,
    URL_ADMIN_UPDATE_COUPON, URL_ADMIN_DELETE_COUPON, URL_ADMIN_ASSIGNED_COUPON_LIST, URL_ADMIN_CHECK_COUPON, URL_ADMIN_SUBMIT_COUPON, URL_ADMIN_GET_ALL_USERS_LIST
} from '../utils/admin-api-url.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';
import axios from 'axios';

// Add Coupon
export const addCoupon = (couponData, history) => async dispatch => {
    try {
        dispatch({ type: POST_ADD_COUPON_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_ADD_COUPON, couponData, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_ADD_COUPON_SUCCESS, payload: response.data });
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
            history.push('/coupons-list');
        } else {
            toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        }
    } catch (error) {
        dispatch({ type: GET_ADD_COUPON_FAILURE, payload: error });
    }
};

// Get Single Coupon
export const getCoupon = ID => async dispatch => {
    try {
        dispatch({ type: POST_COUPON_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_GET_SINGLE_COUPON}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_COUPON_SUCCESS, payload: response.data.data });

        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_COUPON_FAILURE, payload: error });
    }
};

// Get All Coupons list
export const getCoupons = () => async dispatch => {
    try {
        dispatch({ type: POST_COUPONS_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_GET_COUPONS_LIST, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_COUPONS_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_COUPONS_FAILURE, payload: error });
    }
};

// Delete Coupon
export const deleteCoupon = ID => async dispatch => {
    try {
        dispatch({ type: POST_REMOVE_COUPON_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_DELETE_COUPON}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_REMOVE_COUPON_SUCCESS, payload: response.data });
            toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_REMOVE_COUPON_FAILURE, payload: error });
    }
};

// Update coupon
export const updateCoupon = (couponData, history) => async dispatch => {
    try {
        dispatch({ type: POST_UPDATE_COUPON_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_UPDATE_COUPON, couponData, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_UPDATE_COUPON_SUCCESS, payload: response.data });
            history.push('/coupons-list');
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_UPDATE_COUPON_FAILURE, payload: error });
    }
};

// Get All Coupons list
export const getAssignedCoupons = () => async dispatch => {
    try {
        dispatch({ type: GET_ASSIGNED_COUPON_REQUEST, payload: true });
        const response = await axios.get(URL_ADMIN_ASSIGNED_COUPON_LIST, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_ASSIGNED_COUPON_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_ASSIGNED_COUPON_FAILURE, payload: error });
    }
};



// Get All users list
export const getAllUsers = () => async dispatch => {
    try {
        dispatch({ type: GET_ALL_USERS_REQUEST, payload: true });
        const response = await axios.get(URL_ADMIN_GET_ALL_USERS_LIST, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_ALL_USERS_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_ALL_USERS_FAILURE, payload: error });
    }
};



// Get All Coupons list
export const submitAssignedCoupons = (data, history) => async dispatch => {
    try {
        dispatch({ type: POST_ASSIGNED_COUPON_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_SUBMIT_COUPON, data, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: POST_ASSIGNED_COUPON_SUCCESS, payload: response.data.data });
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
            history.push("/assigned-coupon-list");
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: POST_ASSIGNED_COUPON_FAILURE, payload: error });
    }
};

// Get All Coupons list
export const checkAssignedCoupons = (data, history) => async dispatch => {
    try {
        dispatch({ type: POST_CHECK_COUPON_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_SUBMIT_COUPON, data, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: POST_CHECK_COUPON_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: POST_CHECK_COUPON_FAILURE, payload: error });
    }
};



