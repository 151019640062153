import {
    POST_CUSTOMER_HELP_OPTION_ADD_REQUEST, GET_CUSTOMER_HELP_OPTION_ADD_SUCCESS, GET_CUSTOMER_HELP_OPTION_ADD_FAILURE,
    POST_CUSTOMER_HELP_OPTIONS_REQUEST, GET_CUSTOMER_HELP_OPTIONS_SUCCESS, GET_CUSTOMER_HELP_OPTIONS_FAILURE,
    POST_CUSTOMER_HELP_OPTION_REQUEST, GET_CUSTOMER_HELP_OPTION_SUCCESS, GET_CUSTOMER_HELP_OPTION_FAILURE,
    POST_REMOVE_CUSTOMER_HELP_OPTION_REQUEST, GET_REMOVE_CUSTOMER_HELP_OPTION_SUCCESS, GET_REMOVE_CUSTOMER_HELP_OPTION_FAILURE,
    POST_UPDATE_CUSTOMER_HELP_OPTION_REQUEST, GET_UPDATE_CUSTOMER_HELP_OPTION_SUCCESS, GET_UPDATE_CUSTOMER_HELP_OPTION_FAILURE
} from '../_actions/_type';
import {
    URL_ADMIN_ADD_CUST_HELP_OPTION, URL_ADMIN_GET_SINGLE_CUST_HELP_OPTION, URL_ADMIN_GET_CUST_HELP_OPTIONS_LIST,
    URL_ADMIN_UPDATE_CUST_HELP_OPTION, URL_ADMIN_REMOVE_CUST_HELP_OPTION,
} from '../utils/admin-api-url.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';
import axios from 'axios';

// Add customer help option.
export const addCustoHelpOption = (data, history) => async dispatch => {
    try {
        dispatch({ type: POST_CUSTOMER_HELP_OPTION_ADD_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_ADD_CUST_HELP_OPTION, data, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_CUSTOMER_HELP_OPTION_ADD_SUCCESS, payload: response.data });
            history.push('/help-options-list');
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_CUSTOMER_HELP_OPTION_ADD_FAILURE, payload: error });
    }
};

// Get all customer help options list.
export const getCustoHelpOptions = () => async dispatch => {
    try {
        dispatch({ type: POST_CUSTOMER_HELP_OPTIONS_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_GET_CUST_HELP_OPTIONS_LIST, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_CUSTOMER_HELP_OPTIONS_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_CUSTOMER_HELP_OPTIONS_FAILURE, payload: error });
    }
};

// Get single customer help option.
export const getCustoHelpOption = ID => async dispatch => {
    try {
        dispatch({ type: POST_CUSTOMER_HELP_OPTION_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_GET_SINGLE_CUST_HELP_OPTION}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_CUSTOMER_HELP_OPTION_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_CUSTOMER_HELP_OPTION_FAILURE, payload: error });
    }
};

// Update customer help option.
export const updateCustoHelpOption = (data, history) => async dispatch => {
    try {
        dispatch({ type: POST_UPDATE_CUSTOMER_HELP_OPTION_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_UPDATE_CUST_HELP_OPTION, data, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_UPDATE_CUSTOMER_HELP_OPTION_SUCCESS, payload: response.data });
            history.push('/help-options-list');
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_UPDATE_CUSTOMER_HELP_OPTION_FAILURE, payload: error });
    }
};

// Delete customer help option.
export const deleteCustoHelpOption = ID => async dispatch => {
    try {
        dispatch({ type: POST_REMOVE_CUSTOMER_HELP_OPTION_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_REMOVE_CUST_HELP_OPTION}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_REMOVE_CUSTOMER_HELP_OPTION_SUCCESS, payload: response.data });
            toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_REMOVE_CUSTOMER_HELP_OPTION_FAILURE, payload: error });
    }
};
