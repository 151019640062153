import {
    POST_PACKET_ADD_REQUEST, GET_PACKET_ADD_SUCCESS, GET_PACKET_ADD_FAILURE,
    POST_PACKETS_REQUEST, GET_PACKETS_SUCCESS, GET_PACKETS_FAILURE,
    POST_PACKET_REQUEST, GET_PACKET_SUCCESS, GET_PACKET_FAILURE,
    POST_REMOVE_PACKET_REQUEST, GET_REMOVE_PACKET_SUCCESS, GET_REMOVE_PACKET_FAILURE,
    POST_UPDATE_PACKET_REQUEST, GET_UPDATE_PACKET_SUCCESS, GET_UPDATE_PACKET_FAILURE,
    POST_ACTIVE_PACKETS_REQUEST, GET_ACTIVE_PACKETS_SUCCESS, GET_ACTIVE_PACKETS_FAILURE,
} from '../_actions/_type';

export const initialState = {
    packetCreated : false,
    packetUpdated : false,
    packetDeleted : false,
    packets       : [],
    packet        : [],
    activePackets : [],
    loading       : false,
    errors        : {},
};
export default function packets (state = initialState, action) {
    switch (action.type) {
        case POST_PACKET_ADD_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_PACKET_ADD_SUCCESS:
            return {
                ...state,
                packetCreated   : true,
                loading         : false
            };
        case GET_PACKET_ADD_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_PACKETS_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_PACKETS_SUCCESS:
            return {
                ...state,
                packets         : action.payload,
                loading         : false
            };
        case GET_PACKETS_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_PACKET_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_PACKET_SUCCESS:
            return {
                ...state,
                packet          : action.payload,
                loading         : false
            };
        case GET_PACKET_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_UPDATE_PACKET_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_UPDATE_PACKET_SUCCESS:
            return {
                ...state,
                packetUpdated   : true,
                loading         : false
            };
        case GET_UPDATE_PACKET_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_REMOVE_PACKET_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_REMOVE_PACKET_SUCCESS:
            return {
                ...state,
                packetDeleted   : true,
                loading         : false
            };
        case GET_REMOVE_PACKET_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_ACTIVE_PACKETS_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_ACTIVE_PACKETS_SUCCESS:
            return {
                ...state,
                activePackets    : action.payload,
                loading          : false
            };
        case GET_ACTIVE_PACKETS_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        default:
            return state;
    }
}
