import {
    POST_ORDERS_REQUEST, GET_ORDERS_SUCCESS, GET_ORDERS_FAILURE,
    POST_ORDER_REQUEST, GET_ORDER_SUCCESS, GET_ORDER_FAILURE,
    POST_REMOVE_ORDER_REQUEST, GET_REMOVE_ORDER_SUCCESS, GET_REMOVE_ORDER_FAILURE,
    POST_UPDATE_ORDER_REQUEST, GET_UPDATE_ORDER_SUCCESS, GET_UPDATE_ORDER_FAILURE
} from '../_actions/_type';
import {
    URL_ADMIN_GET_ORDER, URL_ADMIN_GET_ORDERS_LIST, URL_ADMIN_UPDATE_ORDER,
    URL_ADMIN_REMOVE_ORDER,
} from '../utils/admin-api-url.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';
import axios from 'axios';

// Get all customer help options list.
export const getOrders = () => async dispatch => {
    try {
        dispatch({ type: POST_ORDERS_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_GET_ORDERS_LIST, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_ORDERS_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_ORDERS_FAILURE, payload: error });
    }
};

// Get single customer help option.
export const getOrder = ID => async dispatch => {
    try {
        dispatch({ type: POST_ORDER_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_GET_ORDER}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_ORDER_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_ORDER_FAILURE, payload: error });
    }
};

// Update customer help option.
export const updateOrder = (data, history) => async dispatch => {
    try {
        dispatch({ type: POST_UPDATE_ORDER_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_UPDATE_ORDER, data, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_UPDATE_ORDER_SUCCESS, payload: response.data });
            history.push('/orders-list');
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_UPDATE_ORDER_FAILURE, payload: error });
    }
};

// Delete customer help option.
export const deleteOrder = ID => async dispatch => {
    try {
        dispatch({ type: POST_REMOVE_ORDER_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_REMOVE_ORDER}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_REMOVE_ORDER_SUCCESS, payload: response.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_REMOVE_ORDER_FAILURE, payload: error });
    }
};
