import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MDBDataTable } from 'mdbreact';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { Fab } from 'react-tiny-fab';
import EditIcon from '@material-ui/icons/Edit';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import BlockIcon from '@material-ui/icons/Block';
import SideBar from '../CommonTemplates/SideBar.js';
import Header from '../CommonTemplates/Header.js';
import Moment from 'moment';
import { URL_ADMIN_GET_NOTIFICATION } from '../../utils/admin-api-url.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';
import axios from 'axios';

export class ListNotifications extends Component {

    constructor(props) {
        super(props)
        this.state = {
            notifications: [],
            readOnly: true,
            showModal: false,
            id: ''
        }
    }
    async componentDidMount() {
        await this.getNotifications.bind(this)()
    }

    async getNotifications() {
        try {
            const response = await axios.post(URL_ADMIN_GET_NOTIFICATION, {}, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    'x-access-token': reactLocalStorage.getObject('access-token')
                }
            });
            if (response.data.status === true) {
                this.setState({
                    notifications: response.data.data
                })
            } else {
                toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            console.log(error);
        }
    }

    handleOpenModal = id => {
        this.setState({ showModal: true, id });
    };

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    handleConfirmDelete = async () => {
        this.setState({ showModal: false });
        await this.props.deleteCoupon(this.state.id);
        await this.props.getCoupons();
    };

    addNew = () => {
        this.props.history.push('add-notifications');
    }

    render() {
        const coupons = this.state.notifications;
        let displayContent = [];
        // let i = 1;
        console.log(coupons);
        displayContent = coupons.map((rowInfo, index) => ({
            no: index++ + 1,
            name: rowInfo.name,
            title: rowInfo.title,
            messages: rowInfo.message,
            type: rowInfo.type,
            // created_at: Moment(rowInfo.created_at).format('YYYY-MM-DD'),
            action:
                <div>
                    {/* <button className="btn btn-danger" onClick={() => this.handleOpenModal(rowInfo.id)}><DeleteRoundedIcon /></button>&nbsp; */}
                    <Link to={`/clone-notifications/${(rowInfo.id)}`} className="btn btn-warning"><EditIcon /></Link>
                </div>
        }));
        // this.setState({
        //     displayContent
        // })
        // console.log(displayContent);
        const data = {
            columns: [
                {
                    label: 'S.No',
                    field: 'no',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Title',
                    field: 'title',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Message',
                    field: 'messages',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Type',
                    field: 'type',
                    sort: 'asc',
                    width: 150
                },
                // {
                //     label: 'Createdat',
                //     field: 'created_at',
                //     sort: 'asc',
                //     width: 150
                // },
                {
                    label: 'Action',
                    field: 'action',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: displayContent
        };

        console.log(data);
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <SideBar />
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <Header />
                            <div className="main-content-container container-fluid px-4">
                                <div class="page-header row no-gutters py-4">
                                    <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
                                        <span class="text-uppercase page-subtitle">Overview</span>
                                        <h3 class="page-title">List Notifications</h3>
                                    </div>
                                </div>
                                <Fab
                                    icon="+"
                                    alwaysShowTitle={false}
                                    onClick={this.addNew}
                                >
                                </Fab>
                                <div class="row">
                                    <div class="col">
                                        <div class="card card-small mb-4">
                                            {/* <div class="card-header border-bottom">
                                                <h6 class="m-0">Active Coupons</h6>
                                            </div> */}
                                            <div class="card-body pb-3 text-center">
                                                <MDBDataTable striped bordered hover data={data} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer />
                            {/* <Footer/> */}
                        </main>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default ListNotifications
