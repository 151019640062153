import { 
    POST_USERS_REQUEST, GET_USERS_SUCCESS, GET_USERS_FAILURE,
    POST_USER_DELEET_REQUEST, GET_USER_DELETE_SUCCESS, GET_USER_DELETE_FAILURE,
    POST_USER_DETAILS_REQUEST, GET_USER_DETAILS_SUCCESS, GET_USER_DETAILS_FAILURE,
    POST_USER_UPDATE_REQUEST, GET_USER_UPDATE_SUCCESS, GET_USER_UPDATE_FAILURE,
    POST_ADD_USER_REQUEST, GET_ADD_USER_SUCCESS, GET_ADD_USER_FAILURE,
    POST_USER_PHOTO_REQUEST, GET_USER_PHOTO_SUCCESS, GET_USER_PHOTO_FAILURE,
} from '../_actions/_type';

export const initialState = {
    userDeleted     : [],
    imagePath       : '',
    user            : [],
    users           : [],
    loading         : false,
    errors          : {},
};
export default function users (state = initialState, action) {
    switch (action.type) {
        case POST_USERS_REQUEST:
            return {
                ...state,
                loading : true
            };
        case GET_USERS_SUCCESS:
            return {
                ...state,
                users   : action.payload,
                loading : false
            };
        case GET_USERS_FAILURE:
            return {
                ...state,
                errors  : action.payload,
                loading : false
            };
        case POST_USER_DELEET_REQUEST:
            return {
                ...state,
                loading : true
            };
        case GET_USER_DELETE_SUCCESS:
            return {
                ...state,
                loading : false
            };
        case GET_USER_DELETE_FAILURE:
            return {
                ...state,
                loading : false
            };
        case POST_USER_DETAILS_REQUEST:
            return {
                ...state,
                loading : true
            };
        case GET_USER_DETAILS_SUCCESS:
            return {
                ...state,
                user    : action.payload,
                loading : false
            };
        case GET_USER_DETAILS_FAILURE:
            return {
                ...state,
                loading : false
            };
        case POST_USER_UPDATE_REQUEST:
            return {
                ...state,
                loading : true
            };
        case GET_USER_UPDATE_SUCCESS:
            return {
                ...state,
                userDeleted : action.payload,
                loading     : false
            };
        case GET_USER_UPDATE_FAILURE:
            return {
                ...state,
                loading : false
            };
        case POST_ADD_USER_REQUEST:
            return {
                ...state,
                loading : true
            };
        case GET_ADD_USER_SUCCESS:
            return {
                ...state,
                userCreated : action.payload,
                loading     : false
            };
        case GET_ADD_USER_FAILURE:
            return {
                ...state,
                loading : false
            };
        case POST_USER_PHOTO_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_USER_PHOTO_SUCCESS:
            return {
                ...state,
                imagePath       : action.payload.filepath,
                loading         : false
            };
        case GET_USER_PHOTO_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        default:
            return state;
    }
}
