import {
    POST_CUSTOMER_HELP_OPTION_ADD_REQUEST, GET_CUSTOMER_HELP_OPTION_ADD_SUCCESS, GET_CUSTOMER_HELP_OPTION_ADD_FAILURE,
    POST_CUSTOMER_HELP_OPTIONS_REQUEST, GET_CUSTOMER_HELP_OPTIONS_SUCCESS, GET_CUSTOMER_HELP_OPTIONS_FAILURE,
    POST_CUSTOMER_HELP_OPTION_REQUEST, GET_CUSTOMER_HELP_OPTION_SUCCESS, GET_CUSTOMER_HELP_OPTION_FAILURE,
    POST_REMOVE_CUSTOMER_HELP_OPTION_REQUEST, GET_REMOVE_CUSTOMER_HELP_OPTION_SUCCESS, GET_REMOVE_CUSTOMER_HELP_OPTION_FAILURE,
    POST_UPDATE_CUSTOMER_HELP_OPTION_REQUEST, GET_UPDATE_CUSTOMER_HELP_OPTION_SUCCESS, GET_UPDATE_CUSTOMER_HELP_OPTION_FAILURE
} from '../_actions/_type';

export const initialState = {
    optionCreated: false,
    optionUpdated: false,
    optionDeleted: false,
    options: [],
    option: [],
    loading: false,
    errors: {},
};
export default function options(state = initialState, action) {
    switch (action.type) {
        case POST_CUSTOMER_HELP_OPTION_ADD_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_CUSTOMER_HELP_OPTION_ADD_SUCCESS:
            return {
                ...state,
                optionCreated: true,
                loading: false
            };
        case GET_CUSTOMER_HELP_OPTION_ADD_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        case POST_CUSTOMER_HELP_OPTIONS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_CUSTOMER_HELP_OPTIONS_SUCCESS:
            return {
                ...state,
                options: action.payload,
                loading: false
            };
        case GET_CUSTOMER_HELP_OPTIONS_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        case POST_CUSTOMER_HELP_OPTION_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_CUSTOMER_HELP_OPTION_SUCCESS:
            return {
                ...state,
                option: action.payload,
                loading: false
            };
        case GET_CUSTOMER_HELP_OPTION_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        case POST_UPDATE_CUSTOMER_HELP_OPTION_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_UPDATE_CUSTOMER_HELP_OPTION_SUCCESS:
            return {
                ...state,
                optionUpdated: true,
                loading: false
            };
        case GET_UPDATE_CUSTOMER_HELP_OPTION_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        case POST_REMOVE_CUSTOMER_HELP_OPTION_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_REMOVE_CUSTOMER_HELP_OPTION_SUCCESS:
            return {
                ...state,
                optionDeleted: true,
                loading: false
            };
        case GET_REMOVE_CUSTOMER_HELP_OPTION_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        default:
            return state;
    }
}
