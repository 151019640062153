import React, { Component } from "react";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Header from "../CommonTemplates/Header.js";
import Sidebar from "../CommonTemplates/SideBar.js";
import {
  getUser,
  updateUser,
  uploadUserImage,
} from "../../_actions/userActions.js";
import { reactLocalStorage } from "reactjs-localstorage";
import {
  API_URL,
  BASEASSETURL,
  URL_ADMIN_GET_SINGLE_USER,
} from "../../utils/admin-api-url.js";
import axios from "axios";
import Moment from "moment";

class DetailUser extends Component {
  state = {
    readOnly: true,
    fields: {},
    errors: {},
    form_data: {},
    userImage: "",
    detailUser: {},
  };

  async componentDidMount() {
    const id = this.props.match.params.id;
    try {
      // dispatch({ type: POST_USER_DETAILS_REQUEST, payload: true });
      const response = await axios.post(
        `${URL_ADMIN_GET_SINGLE_USER}/${id}`,
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            "x-access-token": reactLocalStorage.getObject("access-token"),
          },
        }
      );
      if (response.data.status === true) {
        this.setState({
          detailUser: response.data.data[0] || {},
        });
      } else {
        this.setState({
          detailUser: {},
        });
      }
    } catch (error) { }
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <Sidebar />
            <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
              <Header />
              <div className="main-content-container container-fluid px-4">
                <div className="page-header row no-gutters py-4">
                  <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                    <span className="text-uppercase page-subtitle">
                      Overview
                    </span>
                    <h3 className="page-title">Detail User</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card card-small mb-4">
                      <div className="card-header border-bottom">
                        <h6 className="m-0">Account Details</h6>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Photo</p>
                          </div>
                          <div class="col-sm-9">
                            <img
                              src={this.state.detailUser?.profile.includes('googleusercontent') ? this.state.detailUser?.profile : BASEASSETURL + this.state.detailUser?.profile}
                              className="mb-3"
                              height="50"
                              width="50"
                              alt=""
                            />
                            {/* <p class="text-muted mb-0">{}</p> */}
                          </div>
                        </div>
                        <hr></hr>
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Name</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">
                              {this.state.detailUser?.name}
                            </p>
                          </div>
                        </div>
                        <hr></hr>
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Email</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">
                              {this.state.detailUser?.email}
                            </p>
                          </div>
                        </div>
                        <hr></hr>
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Phone Number</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">
                              {this.state.detailUser?.phone}
                            </p>
                          </div>
                        </div>
                        <hr></hr>
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Status</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">
                              {this.state.detailUser?.status === "0"
                                ? "Deactive"
                                : "Active"}
                            </p>
                          </div>
                        </div>
                        <hr></hr>
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Role</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">
                              {this.state.detailUser?.type}
                            </p>
                          </div>
                        </div>
                        <hr></hr>
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Member</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">
                              {this.state.detailUser?.member}
                            </p>
                          </div>
                        </div>
                        <hr></hr>
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Registered Date</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">
                              {Moment(this.state.detailUser?.created_at).format(
                                "LLL"
                              )}
                            </p>
                          </div>
                        </div>
                        <hr></hr>
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">Address</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">
                              {this.state.detailUser?.address}
                            </p>
                          </div>
                        </div>
                        <hr></hr>
                        <div class="row">
                          <div class="col-sm-3">
                            <p class="mb-0">City</p>
                          </div>
                          <div class="col-sm-9">
                            <p class="text-muted mb-0">
                              {this.state.detailUser?.city}
                            </p>
                          </div>
                        </div>
                        <hr></hr>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Footer/> */}
            </main>
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.users.user,
  imagePath: state.users.imagePath,
});
const actionCreators = {
  getUser: getUser,
  updateUser: updateUser,
  uploadUserImage: uploadUserImage,
};

export default connect(mapStateToProps, actionCreators)(DetailUser);
