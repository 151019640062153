import {  
    POST_DASHBOARD_REQUEST, GET_DASHBOARD_SUCCESS, GET_DASHBOARD_FAILURE,
    POST_EARNING_REPORT_REQUEST, GET_EARNING_REPORT_SUCCESS, GET_EARNING_REPORT_FAILURE
} from '../_actions/_type'; 

export const initialState = {
    dashboards: [],
    earnings: [],
    errors: {}
};
export default function dashboards (state = initialState, action) {
    switch (action.type) {
        case POST_DASHBOARD_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_DASHBOARD_SUCCESS:
            return {
                ...state,
                dashboards: action.payload,
                loading: false
            };
        case GET_DASHBOARD_FAILURE:
            return {
                ...state,
                loading: false
            };
        case POST_EARNING_REPORT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_EARNING_REPORT_SUCCESS:
            return {
                ...state,
                earnings: action.payload,
                loading: false
            };
        case GET_EARNING_REPORT_FAILURE:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}
