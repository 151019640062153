import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Header from '../CommonTemplates/Header.js';
import Sidebar from '../CommonTemplates/SideBar.js';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { getCoupon, updateCoupon } from '../../_actions/couponActions.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';
import axios from 'axios';
import { BASEASSETURL, URL_ADMIN_ADD_NOTIFICATION, URL_ADMIN_ARTICLE, URL_ADMIN_GET_ARTICLE, URL_ADMIN_GET_NOTIFICATION, URL_ADMIN_UPDATE_ARTICLE, URL_ADMIN_UPDATE_NOTIFICATION } from '../../utils/admin-api-url.js';
import ReactModal from 'react-modal';

class EditArticles extends Component {
    state = {
        readOnly: true,
        fields: {},
        errors: {},
        expiry_date: '',
        images: null,
        loading: false
    }

    async componentDidMount() {
        const id = this.props.match.params.id;
        try {
            const response = await axios.post(`${URL_ADMIN_GET_ARTICLE}/${id}`, {}, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    'x-access-token': reactLocalStorage.getObject('access-token')
                }
            });
            if (response.data.status === true) {
                var couponData = response.data.data
                var fields = this.state.fields;
                Object.keys(couponData)
                    .forEach(function eachKey(key) {
                        fields[key] = couponData[key];
                    });
                this.setState(fields);
            } else {
                toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {

        }
    }
    // Input handle  
    handleChange = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ errors: '' });
        this.setState(fields);
    }

    // Validation 
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields['title']) {
            formIsValid = false;
            errors['title'] = 'Please enter title.';
        }
        if (!fields['description']) {
            formIsValid = false;
            errors['description'] = 'Please select message.';
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    //Handle Submit 
    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        if (this.handleValidation()) {
            delete this.state.fields.created_by
            delete this.state.fields.created_at
            delete this.state.fields.status

            let formData = new FormData();
            formData.append('images', this.state.images);
            formData.append('title', this.state.fields['title']);
            formData.append('description', this.state.fields['description']);
            formData.append('id', this.state.fields['id']);
            const response = await axios.post(URL_ADMIN_UPDATE_ARTICLE, formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    'x-access-token': reactLocalStorage.getObject('access-token')
                }
            });
            this.setState({ loading: false })
            if (response.data.status === true) {
                toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
                this.props.history.push('/articles-list');
            } else {
                toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }
    onChangeFile = async (e) => {
        this.setState({ images: e.target.files[0], showimg: URL.createObjectURL(e.target.files[0]) });
    }

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    handleOpenModal = () => {
        this.setState({ showModal: true, });
    };
    render() {
        // console.log(this.state);
        // console.log(this.state.images ? URL.createObjectURL(this.state.images) : 'dsd');
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <Header />
                            <div className="main-content-container container-fluid px-4">
                                <div className="page-header row no-gutters py-4">
                                    <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                                        <span className="text-uppercase page-subtitle">Overview</span>
                                        <h3 className="page-title">Clone Article</h3>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card card-small mb-4">
                                            <div className="card-header border-bottom">
                                                <h6 className="m-0">Article Details</h6>
                                            </div>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item p-3">
                                                    <div className="row">
                                                        <div className="col">
                                                            <form onSubmit={this.state.loading ? null : this.handleSubmit} enctype='multipart/form-data'>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feFirstName">Image</label>
                                                                        <div className=''>
                                                                            <img onClick={() => this.handleOpenModal()} style={{ width: 150, cursor: 'pointer' }} src={this.state.showimg ? this.state.showimg : BASEASSETURL + this.state.fields.images} />
                                                                            <input type='file' name='image' className='form-control' onChange={this.onChangeFile} accept='image/*' />
                                                                        </div>
                                                                        <span style={{ color: 'red' }}>{this.state.errors['image']}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feValue">Title</label>
                                                                        <input type="text" className="form-control" id="title" name="title" placeholder="Enter Title." value={this.state.fields['title']} onChange={this.handleChange} />
                                                                        <span style={{ color: 'red' }}>{this.state.errors['title']}</span>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feValue">description</label>
                                                                        <textarea rows={5} type="text" className="form-control" id="description" name="description" placeholder="Enter description." value={this.state.fields['description']} onChange={this.handleChange} />
                                                                        {/* <input  /> */}
                                                                        <span style={{ color: 'red' }}>{this.state.errors['description']}</span>
                                                                    </div>
                                                                </div>




                                                                <button type="submit" className="btn btn-success" disabled={this.state.loading}>{this.state.loading ? 'Please Wait...' : 'Update'}</button>&nbsp;
                                                                <Link to="/notifications-list" className="btn btn-danger">Cancel</Link>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer />
                            <ReactModal isOpen={this.state.showModal} contentLabel="onRequestClose Example" onAfterOpen={this.afterOpenModal}
                                onRequestClose={this.handleCloseModal} shouldCloseOnOverlayClick={false} style={customStyles} >
                                <h3>Image Preview</h3>
                                <div className="">
                                    <img src={this.state.showimg ? this.state.showimg : BASEASSETURL + this.state.fields.images} />
                                </div>
                                <button
                                    className="btn btn-sm btn-warning btn-modal-close"
                                    onClick={this.handleCloseModal}
                                >
                                    Exit
                                </button>

                            </ReactModal>
                            {/* <Footer/> */}
                        </main>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const customStyles = {
    content: {
        color: 'darkred',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    },
    overlay: {
        backgroundColor: '#444',
        opacity: '0.9',
        zIndex: 9999
    }
};

export default EditArticles;