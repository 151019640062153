import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { forgetPassword } from "../../_actions/authActions.js";

export class ForgetPassword extends Component {
  state = {
    errors: {},
    fields: {},
  };

  onChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState(fields);
  };

  handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Please enter email address.";
    } else if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Please enter valid email.";
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (this.handleValidation()) {
      const userData = {
        email: this.state.fields["email"],
      };
      this.props.forgetPassword(userData, this.props.history);
    }
  };
  render() {
    return (
      <React.Fragment>
        <main className="main-content col">
          <div className="main-content-container container-fluid px-4 my-auto h-100">
            <div className="row no-gutters h-100">
              <div className="col-lg-3 col-md-5 auth-form mx-auto my-auto my-5">
                <div className="card">
                  <div className="card-body">
                    <img
                      className="auth-form__logo d-table mx-auto mb-3"
                      src="../assets/images/PITENXLM_logo.png"
                      alt="Pitenxlm Panel"
                      height="100"
                      width="100"
                    />
                    <h5 className="auth-form__title text-center mb-0">
                      Pitenxlm Panel
                    </h5>
                    <spam className="text-center" style={{ fontSize: "10px" }}>
                      Your new password will be send in your email.
                    </spam>
                    <form onSubmit={this.onSubmit}>
                      <div className="form-group">
                        {/* <label for="Email">Email address</label> */}
                        <input
                          type="email"
                          className="form-control"
                          onChange={this.onChange}
                          id="email"
                          name="email"
                          aria-describedby="emailHelp"
                          placeholder="Enter email"
                          value={this.state.fields["email"]}
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["email"]}
                        </span>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-pill btn-accent d-table mx-auto"
                      >
                        Reset password
                      </button>
                    </form>
                  </div>
                </div>
                <div className="auth-form__meta text-center mt-3">
                  <Link className="btn btn-sm btn-accent" to="/">
                    Click to login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { forgetPassword })(ForgetPassword);
