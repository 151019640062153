import {  
    POST_ADD_COUPON_REQUEST, GET_ADD_COUPON_SUCCESS, GET_ADD_COUPON_FAILURE,
    POST_COUPONS_REQUEST, GET_COUPONS_SUCCESS, GET_COUPONS_FAILURE,
    POST_COUPON_REQUEST, GET_COUPON_SUCCESS, GET_COUPON_FAILURE,
    POST_REMOVE_COUPON_REQUEST, GET_REMOVE_COUPON_SUCCESS, GET_REMOVE_COUPON_FAILURE,
    POST_UPDATE_COUPON_REQUEST, GET_UPDATE_COUPON_SUCCESS, GET_UPDATE_COUPON_FAILURE,
    POST_CHECK_COUPON_REQUEST,POST_CHECK_COUPON_SUCCESS,POST_CHECK_COUPON_FAILURE,
    GET_ASSIGNED_COUPON_REQUEST,GET_ASSIGNED_COUPON_SUCCESS,GET_ASSIGNED_COUPON_FAILURE,
    POST_ASSIGNED_COUPON_REQUEST,POST_ASSIGNED_COUPON_SUCCESS,POST_ASSIGNED_COUPON_FAILURE,
    GET_ASSIGNED_ALL_COUPON_REQUEST,GET_ASSIGNED_ALL_COUPON_SUCCESS,GET_ASSIGNED_ALL_COUPON_FAILURE,
    GET_ALL_USERS_REQUEST,GET_ALL_USERS_SUCCESS,GET_ALL_USERS_FAILURE
} from '../_actions/_type'; 

export const initialState = {
    couponCreated       : false,
    couponUpdated       : false,
    couponDeleted       : false,
    coupon              : [],
    coupons             : [],
    couponsData         : [],
    assignedData        : [],
    usersData           : [],
    couponDetails       : {},
    errors              : {},
};
export default function coupons (state = initialState, action) {
    switch (action.type) {
        case POST_ADD_COUPON_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_ADD_COUPON_SUCCESS:
            return {
                ...state,
                couponCreated   : true,
                loading         : false
            };
        case GET_ADD_COUPON_FAILURE:
            return {
                ...state,
                loading         : false
            };
        case POST_COUPONS_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_COUPONS_SUCCESS:
            return {
                ...state,
                coupons : action.payload,
                loading         : false
            };
        case GET_COUPONS_FAILURE:
            return {
                ...state,
                loading         : false
            };
        case GET_ASSIGNED_ALL_COUPON_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_ASSIGNED_ALL_COUPON_SUCCESS:
            return {
                ...state,
                assignedData : action.payload,
                loading         : false
            };
        case GET_ASSIGNED_ALL_COUPON_FAILURE:
            return {
                ...state,
                loading         : false
            }; 
        case GET_ALL_USERS_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                usersData : action.payload,
                loading         : false
            };
        case GET_ALL_USERS_FAILURE:
            return {
                ...state,
                loading         : false
            };        
        case POST_COUPON_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_COUPON_SUCCESS:
            return {
                ...state,
                coupon          : action.payload,
                loading         : false
            };
        case GET_COUPON_FAILURE:
            return {
                ...state,
                loading         : false
            };
        case POST_REMOVE_COUPON_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_REMOVE_COUPON_SUCCESS:
            return {
                ...state,
                couponDeleted   : true,
                loading         : false
            };
        case GET_REMOVE_COUPON_FAILURE:
            return {
                ...state,
                loading         : false
            };
        case POST_UPDATE_COUPON_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_UPDATE_COUPON_SUCCESS:
            return {
                ...state,
                couponUpdated   : true,
                loading         : false
            };
        case GET_UPDATE_COUPON_FAILURE:
            return {
                ...state,
                loading         : false
            };
        case GET_ASSIGNED_COUPON_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_ASSIGNED_COUPON_SUCCESS:
           return {
                ...state,
                assignedData   : action.payload,
                loading       : false
            };
        case GET_ASSIGNED_COUPON_FAILURE:
            return { 
                ...state,
                loading         : false
            };
        case POST_ASSIGNED_COUPON_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case POST_ASSIGNED_COUPON_SUCCESS:
            return {
                ...state,
                couponsData   : action.payload,
                loading       : false
            };
        case POST_ASSIGNED_COUPON_FAILURE:
            return {
                ...state,
                loading         : false
            };
        case POST_CHECK_COUPON_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case POST_CHECK_COUPON_SUCCESS:
            return {
                ...state,
                couponsData   : action.payload,
                loading       : false
            };
        case POST_CHECK_COUPON_FAILURE:
            return {
                ...state,
                loading         : false
            };            
        default:
            return state;
    }
}
