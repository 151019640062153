import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { MDBDataTable } from 'mdbreact';
import SideBar from '../CommonTemplates/SideBar.js';
import Header from '../CommonTemplates/Header.js';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { getEarings } from '../../_actions/dashboardActions.js';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import currency from '../../utils/currency';


export class TotalEarnings extends Component {
    // state = {
    //     readOnly    : true,
    //     showModal   : false,
    //     id          : ''
    // }
    async componentDidMount() {
        await this.props.getEarings('data');
    }

    render() {
        let displayContent = '';
        let i = 1;
        displayContent = this.props.earnings.map(rowInfo => ({
            no: i++,
            name: rowInfo.user_name,
            order_id: '#' + rowInfo.id,
            order_date: rowInfo.created_date,
            total: 'IDR ' + currency(rowInfo.total)
        }));

        const data = {
            columns: [
                {
                    label: 'S.No',
                    field: 'no',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'OrderID',
                    field: 'order_id',
                },
                {
                    label: 'Name',
                    field: 'name',
                    width: 150
                },
                {
                    label: 'Amount',
                    field: 'total',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Order Date',
                    field: 'order_date',
                }
            ],
            rows: displayContent
        };

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <SideBar />
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <Header />
                            <div className="main-content-container container-fluid px-4">
                                <div class="page-header row no-gutters py-4 justify-content-between">
                                    <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
                                        <span class="text-uppercase page-subtitle">Overview</span>
                                        <h3 class="page-title">Earning Records</h3>
                                    </div>
                                    <ReactHTMLTableToExcel
                                        className="btn btn-info"
                                        table="earnings"
                                        filename="ReportExcel"
                                        sheet="Sheet"
                                        buttonText="Export excel" />
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="card card-small mb-4">
                                            <div class="card-header border-bottom">
                                                <h6 class="m-0">Delivered Orders</h6>

                                            </div>
                                            <div class="card-body pb-3 text-center">
                                                <MDBDataTable striped bordered hover data={data} id='earnings' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    earnings: state.dashboards.earnings
});
const actionCreators = { getEarings: getEarings };

export default connect(mapStateToProps, actionCreators)(TotalEarnings);
