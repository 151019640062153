import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MDBDataTable } from 'mdbreact';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { Fab } from 'react-tiny-fab';
import EditIcon from '@material-ui/icons/Edit';
import SideBar from '../CommonTemplates/SideBar.js';
import Header from '../CommonTemplates/Header.js';
import Moment from 'moment';
import { getCoupons,getAssignedCoupons, deleteCoupon } from '../../_actions/couponActions.js';

export class AssignedCoupon extends Component { 
    state = {
        readOnly: true,
        showModal: false,
        id: ''
    }
    async componentDidMount() {
        await this.props.getAssignedCoupons();
    }

    handleOpenModal = id => {
        this.setState({ showModal: true, id });
    };

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    handleConfirmDelete = async () => {
        this.setState({ showModal: false });
        await this.props.deleteCoupon(this.state.id);
        await this.props.getAssignedCoupons();
    };

    addNew = () => {
        this.props.history.push('add-coupon');
    }

    render() {
        console.log(this.props);
        const coupons = this.props.assignedData;
        let displayContent = '';
        let i = 1;
        displayContent = coupons.map(rowInfo => ( { 
            no: i++,
            name: rowInfo.name,
            code: rowInfo.code,
            type: rowInfo.type,
            value: rowInfo.value,
            expiry_date: Moment(rowInfo.expiry_date).format('DD/MM/YYYY'),
            
        } ));

        const data = {
            columns: [
                {
                    label: 'S.No',
                    field: 'no',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Code',
                    field: 'code',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Type',
                    field: 'type',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Value',
                    field: 'value',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Expiry Date',
                    field: 'expiry_date',
                    sort: 'asc',
                    width: 150
                }
                
            ],
            rows: displayContent
        };

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <SideBar/>
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                                <Header/>
                                <div className="main-content-container container-fluid px-4">
                                    <div class="page-header row no-gutters py-4">
                                        <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
                                            <span class="text-uppercase page-subtitle">Overview</span>
                                            <h3 class="page-title">Assigned Coupon List</h3>
                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col">
                                            <div class="card card-small mb-4">
                                                <div class="card-header border-bottom">
                                                    <h6 class="m-0">Assigned Coupons</h6>
                                                </div>
                                                <div class="card-body pb-3 text-center">
                                                    <MDBDataTable  striped bordered hover data={data}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <ToastContainer />
                            {/* <Footer/> */}
                        </main>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const customStyles = {
    content: {
        color: 'darkred',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    },
    overlay: {
        backgroundColor: '#444',
        opacity: '0.9'
    }
};

const mapStateToProps = state => ({
    assignedData : state.coupons.assignedData,
});
const actionCreators = {
    getCoupons  : getCoupons,
    getAssignedCoupons:getAssignedCoupons,
    deleteCoupon    : deleteCoupon,
};

export default connect(mapStateToProps, actionCreators )(AssignedCoupon);
