import {
    POST_SHIPPING_CHARGE_ADD_REQUEST, GET_SHIPPING_CHARGE_ADD_SUCCESS, GET_SHIPPING_CHARGE_ADD_FAILURE,
    POST_SHIPPING_CHARGES_REQUEST, GET_SHIPPING_CHARGES_SUCCESS, GET_SHIPPING_CHARGES_FAILURE,
    POST_SHIPPING_CHARGE_REQUEST, GET_SHIPPING_CHARGE_SUCCESS, GET_SHIPPING_CHARGE_FAILURE,
    POST_REMOVE_SHIPPING_CHARGE_REQUEST, GET_REMOVE_SHIPPING_CHARGE_SUCCESS, GET_REMOVE_SHIPPING_CHARGE_FAILURE,
    POST_UPDATE_SHIPPING_CHARGE_REQUEST, GET_UPDATE_SHIPPING_CHARGE_SUCCESS, GET_UPDATE_SHIPPING_CHARGE_FAILURE
} from '../_actions/_type';
import {
    URL_ADMIN_ADD_SHIPPING_CHARGE, URL_ADMIN_GET_SINGLE_SHIPPING_CHARGE, URL_ADMIN_GET_SHIPPING_CHARGES_LIST,
    URL_ADMIN_UPDATE_SHIPPING_CHARGE, URL_ADMIN_REMOVE_SHIPPING_CHARGE,
} from '../utils/admin-api-url.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';
import axios from 'axios';

// Add Shipping charge
export const addShipping = (data, history) => async dispatch => {
    try {
        dispatch({ type: POST_SHIPPING_CHARGE_ADD_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_ADD_SHIPPING_CHARGE, data, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_SHIPPING_CHARGE_ADD_SUCCESS, payload: response.data });
            history.push('/shippings-list');
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_SHIPPING_CHARGE_ADD_FAILURE, payload: error });
    }
};

// Get All Shipping charges list
export const getShippings = () => async dispatch => {
    try {
        dispatch({ type: POST_SHIPPING_CHARGES_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_GET_SHIPPING_CHARGES_LIST, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_SHIPPING_CHARGES_SUCCESS, payload: response.data.data });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_SHIPPING_CHARGES_FAILURE, payload: error });
    }
};

// Get Single Shipping charge
export const getShipping = ID => async dispatch => {
    try {
        dispatch({ type: POST_SHIPPING_CHARGE_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_GET_SINGLE_SHIPPING_CHARGE}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_SHIPPING_CHARGE_SUCCESS, payload: response.data.data });
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_SHIPPING_CHARGE_FAILURE, payload: error });
    }
};

// Update Shipping charge
export const updateShipping = (data, history) => async dispatch => {
    try {
        dispatch({ type: POST_UPDATE_SHIPPING_CHARGE_REQUEST, payload: true });
        const response = await axios.post(URL_ADMIN_UPDATE_SHIPPING_CHARGE, data, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_UPDATE_SHIPPING_CHARGE_SUCCESS, payload: response.data });
            history.push('/shippings-list');
            toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_UPDATE_SHIPPING_CHARGE_FAILURE, payload: error });
    }
};

// Delete Shipping charge.
export const deleteShipping = ID => async dispatch => {
    try {
        dispatch({ type: POST_REMOVE_SHIPPING_CHARGE_REQUEST, payload: true });
        const response = await axios.post(`${URL_ADMIN_REMOVE_SHIPPING_CHARGE}/${ID}`, {}, {
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'x-access-token': reactLocalStorage.getObject('access-token')
            }
        });
        if (response.data.status === true) {
            dispatch({ type: GET_REMOVE_SHIPPING_CHARGE_SUCCESS, payload: response.data });
            toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            //localStorage.clear('Auth')
            //localStorage.clear('access-token')
        }
    } catch (error) {
        dispatch({ type: GET_REMOVE_SHIPPING_CHARGE_FAILURE, payload: error });
    }
};
