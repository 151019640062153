import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MDBDataTable } from "mdbreact";
import EditIcon from "@material-ui/icons/Edit";
import SideBar from "../CommonTemplates/SideBar.js";
import Header from "../CommonTemplates/Header.js";
import { getOrders } from "../../_actions/orderActions.js";
import InfoIcon from '@material-ui/icons/Info';
import currency from "../../utils/currency.js";
export class OrderList extends Component {
  state = {
    readOnly: true,
    showModal: false,
    id: "",
  };
  async componentDidMount() {
    await this.props.getOrders();
    // console.log(this.props.orders);
  }

  handleOpenModal = (id) => {
    this.setState({ showModal: true, id });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleConfirmDelete = async () => {
    this.setState({ showModal: false });
    //await this.props.deleteCustoHelpOption(this.state.id);
    //await this.props.getCustoHelpOptions();
  };

  render() {
    const orders = this.props.orders;
    let displayContent = "";
    let i = 1;
    displayContent = orders.map((rowInfo) => ({
      no: i++,
      orderId: "#" + rowInfo.id,
      noInvoice: "#" + rowInfo.no_invoice,
      name: rowInfo.user_name,
      shippingResi: rowInfo.shipping_resi || '--',
      products: rowInfo.products,
      address: rowInfo.shipping_address,
      charge: 'IDR ' + currency(rowInfo.shipping_charge),
      total: 'IDR ' + currency(rowInfo.total),
      subTotal: 'IDR ' + currency(rowInfo.sub_total),
      discount: 'IDR ' + currency(rowInfo.discount),
      date: rowInfo.created_date,
      status: <div>{rowInfo.status}</div>,
      action: (
        <div>
          {/* <button className="btn btn-danger" onClick={() => this.handleOpenModal(rowInfo.id)}><i className="material-icons">delete</i></button>&nbsp; */}
          <Link to={`/edit-order/${rowInfo.id}`} className="btn btn-warning">
            <EditIcon />
          </Link>
          <Link to={`/detail-order/${(rowInfo.id)}`} className="btn btn-primary"><InfoIcon /></Link>
        </div>
      ),
    }));

    const data = {
      columns: [
        {
          label: "No",
          field: "no",
          sort: "asc",
          width: 150,
        },
        {
          label: "shippingResi",
          field: "shippingResi",
          sort: "asc",
          width: 150,
        },
        {
          label: "OrderId",
          field: "orderId",
          sort: "asc",
          width: 150,
        },
        {
          label: "NoInvoice",
          field: "noInvoice",
          sort: "asc",
          width: 150,
        },
        {
          label: "User Name",
          field: "name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Products",
          field: "products",
          sort: "asc",
          width: 150,
        },
        {
          label: "Shipping Charge",
          field: "charge",
          sort: "asc",
          width: 150,
        },
        {
          label: "Total",
          field: "total",
          sort: "asc",
          width: 150,
        },
        {
          label: "Sub Total",
          field: "subTotal",
          sort: "asc",
          width: 150,
        },
        {
          label: "Discount",
          field: "discount",
          sort: "asc",
          width: 150,
        },
        {
          label: "Order Status",
          field: "status",
          sort: "asc",
          width: 150,
        },
        {
          label: "Date",
          field: "date",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: displayContent,
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
              <Header />
              <div className="main-content-container container-fluid px-4">
                <div class="page-header row no-gutters py-4">
                  <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
                    <span class="text-uppercase page-subtitle">Overview</span>
                    <h3 class="page-title">Order List</h3>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="card card-small mb-4">
                      <div class="card-header border-bottom">
                        <h6 class="m-0">Active Orders</h6>
                      </div>
                      <div class="card-body pb-3 text-center">
                        <MDBDataTable striped bordered hover data={data} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ToastContainer />
              {/* <Footer/> */}
              <ReactModal
                isOpen={this.state.showModal}
                contentLabel="onRequestClose Example"
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.handleCloseModal}
                shouldCloseOnOverlayClick={false}
                style={customStyles}
              >
                <h3>Are you sure delete this order ?</h3>
                <div className="btn-modal-container">
                  <ul>
                    <li>
                      <button
                        className="btn btn-sm btn-warning btn-modal-close"
                        onClick={this.handleCloseModal}
                      >
                        Cancel
                      </button>
                    </li>
                    <li>
                      {" "}
                      <button
                        className="btn btn-sm btn-danger btn-modal-confirm"
                        onClick={this.handleConfirmDelete}
                      >
                        Delete
                      </button>
                    </li>
                  </ul>
                </div>
              </ReactModal>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const customStyles = {
  content: {
    color: "darkred",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "#444",
    opacity: "0.9",
  },
};

const mapStateToProps = (state) => ({
  orders: state.orders.orders,
});
const actionCreators = {
  getOrders: getOrders,
};

export default connect(mapStateToProps, actionCreators)(OrderList);
