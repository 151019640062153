import {  
    POST_ADD_BRAND_REQUEST, GET_ADD_BRAND_SUCCESS, GET_ADD_BRAND_FAILURE,
    POST_BRAND_LOGO_REQUEST, GET_BRAND_LOGO_SUCCESS, GET_BRAND_LOGO_FAILURE,
    POST_BRANDS_REQUEST, GET_BRANDS_SUCCESS, GET_BRANDS_FAILURE,
    POST_UPDATE_BRAND_REQUEST, GET_UPDATE_BRAND_SUCCESS, GET_UPDATE_BRAND_FAILURE,
    POST_REMOVE_BRAND_REQUEST, GET_REMOVE_BRAND_SUCCESS, GET_REMOVE_BRAND_FAILURE,
    POST_BRAND_REQUEST, GET_BRAND_SUCCESS, GET_BRAND_FAILURE,
} from '../_actions/_type'; 

export const initialState = {
    brandCreated       : false,
    brandUpdated       : false,
    brandRemoved       : false,
    brand              : [],
    brands             : [],
    logoPath           : '',
    errors             : {},
};
export default function brands (state = initialState, action) {
    switch (action.type) {
        case POST_UPDATE_BRAND_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_UPDATE_BRAND_SUCCESS:
            return {
                ...state,
                brandUpdated    : true,
                loading         : false
            };
        
        case GET_UPDATE_BRAND_FAILURE:
            return {
                ...state,
                loading         : false,
                errors          : action.payload
            };
        case POST_BRAND_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_BRAND_SUCCESS:
            return {
                ...state,
                brand           : action.payload,
                loading         : false
            };
        
        case GET_BRAND_FAILURE:
            return {
                ...state,
                loading         : false,
                errors          : action.payload
            };
        case POST_REMOVE_BRAND_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_REMOVE_BRAND_SUCCESS:
            return {
                ...state,
                brandRemoved    : true,
                loading         : false
            };
        
        case GET_REMOVE_BRAND_FAILURE:
            return {
                ...state,
                loading         : false,
                errors          : action.payload
            };
        case POST_ADD_BRAND_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_ADD_BRAND_SUCCESS:
            return {
                ...state,
                brandCreated    : true,
                loading         : false
            };
        
        case GET_ADD_BRAND_FAILURE:
            return {
                ...state,
                loading         : false,
                errors          : action.payload
            };
        case POST_BRANDS_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_BRANDS_SUCCESS:
            return {
                ...state,
                brands          : action.payload,
                loading         : false
            };
        case GET_BRANDS_FAILURE:
            return {
                ...state,
                loading         : false,
                errors          : action.payload
            };
        case POST_BRAND_LOGO_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_BRAND_LOGO_SUCCESS:
            return {
                ...state,
                logoPath        : action.payload.filepath,
                loading         : false
            };
        case GET_BRAND_LOGO_FAILURE:
            return {
                ...state,
                loading         : false,
                errors          : action.payload
            };
        default:
            return state;
    }
}
