import {
    POST_ADD_REVIEW_REQUEST, GET_ADD_REVIEW_SUCCESS, GET_ADD_REVIEW_FAILURE,
    POST_REVIEWS_REQUEST, GET_REVIEWS_SUCCESS, GET_REVIEWS_FAILURE,
    POST_REVIEW_REQUEST, GET_REVIEW_SUCCESS, GET_REVIEW_FAILURE,
    POST_REMOVE_REVIEW_REQUEST, GET_REMOVE_REVIEW_SUCCESS, GET_REMOVE_REVIEW_FAILURE,
    POST_UPDATE_REVIEW_REQUEST, GET_UPDATE_REVIEW_SUCCESS, GET_UPDATE_REVIEW_FAILURE,
} from '../_actions/_type';

export const initialState = {
    reviewCreated       : false,
    reviewUpdated       : false,
    reviewRemoved       : false,
    review              : [],
    reviews             : [],
    loading             : false,
    errors              : {},
};
export default function reviews (state = initialState, action) {
    switch (action.type) {
        case POST_ADD_REVIEW_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_ADD_REVIEW_SUCCESS:
            return {
                ...state,
                reviewCreated   : true,
                loading         : false
            };
        case GET_ADD_REVIEW_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_REVIEWS_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_REVIEWS_SUCCESS:
            return {
                ...state,
                reviews         : action.payload,
                loading         : false
            };
        case GET_REVIEWS_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_REVIEW_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_REVIEW_SUCCESS:
            return {
                ...state,
                review          : action.payload,
                loading         : false
            };
        case GET_REVIEW_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_REMOVE_REVIEW_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_REMOVE_REVIEW_SUCCESS:
            return {
                ...state,
                reviewRemoved   : action.payload,
                loading         : false
            };
        case GET_REMOVE_REVIEW_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        case POST_UPDATE_REVIEW_REQUEST:
            return {
                ...state,
                loading         : true
            };
        case GET_UPDATE_REVIEW_SUCCESS:
            return {
                ...state,
                reviewUpdated   : action.payload,
                loading         : false
            };
        case GET_UPDATE_REVIEW_FAILURE:
            return {
                ...state,
                errors          : action.payload,
                loading         : false
            };
        default:
            return state;
    }
}
