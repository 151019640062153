import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Header from '../CommonTemplates/Header.js';
import Sidebar from '../CommonTemplates/SideBar.js';
import 'react-toastify/dist/ReactToastify.css';
import { getOrder, updateOrder } from '../../_actions/orderActions.js';
import currency from '../../utils/currency.js';
import { BASEASSETURL } from '../../utils/admin-api-url.js';

class EditOrder extends Component {
    state = {
        readOnly: true,
        fields: {},
        errors: {},
    }

    async componentDidMount() {
        const id = this.props.match.params.id;
        await this.props.getOrder(id);
        var data = this.props.order;
        var fields = this.state.fields;
        Object.keys(data)
            .forEach(function eachKey(key) {
                fields[key] = data[key];
            });
        this.setState(fields);
    }

    // Input handle  
    handleChange = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ errors: '' });
        this.setState(fields);
    }

    // Validation 
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields['status']) {
            formIsValid = false;
            errors['status'] = 'Please select status.';
        } else if (fields['status'] === '') {
            formIsValid = false;
            errors['status'] = 'Please select status.';
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    //Handle Submit 
    handleSubmit = async (e) => {
        e.preventDefault();
        if (this.handleValidation()) {
            const data = {
                order_id: this.state.fields['id'],
                device_id: this.state.fields['device_id'],
                user_id: this.state.fields['user_id'],
                status: this.state.fields['status'],
                shipping_resi: this.state.fields['shippingResi'],
            };
            await this.props.updateOrder(data, this.props.history);
        }
    }
    render() {
        console.log(this.state)
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <Header />
                            <div className="main-content-container container-fluid px-4">
                                <div className="page-header row no-gutters py-4">
                                    <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                                        <span className="text-uppercase page-subtitle">Overview</span>
                                        <h3 className="page-title">Edit Order</h3>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card card-small mb-4">
                                            <div className="card-header border-bottom">
                                                <h6 className="m-0">Order Details</h6>
                                            </div>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item p-3">
                                                    <div className="row">
                                                        <div className="col">
                                                            <form onSubmit={this.handleSubmit} enctype='multipart/form-data'>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feName">Nomor Invoice</label>
                                                                        <p >
                                                                            {this.state.fields['no_invoice']}
                                                                        </p>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feName">Payment Fee</label>
                                                                        <p>
                                                                            IDR {currency(this.state.fields['payment_fee'])}
                                                                        </p>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feName">Shipping Kurir</label>
                                                                        <p>
                                                                            {this.state.fields['shipping_kurir']}
                                                                        </p>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feName">Shipping Charge</label>
                                                                        <p>
                                                                            IDR {currency(this.state.fields['shipping_charge'])}
                                                                        </p>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feName">Shipping Phone</label>
                                                                        <p>
                                                                            {this.state.fields['shipping_phone']}
                                                                        </p>
                                                                    </div>

                                                                    <div className="form-group col-md-6">
                                                                        <label for="feName">Discount</label>
                                                                        <p>
                                                                            IDR  {currency(this.state.fields['discount'])}
                                                                        </p>
                                                                    </div>

                                                                    <div className="form-group col-md-6">
                                                                        <label for="feName">Payment Type</label>
                                                                        <p>
                                                                            {this.state.fields['payment_type']}
                                                                        </p>
                                                                    </div>

                                                                    <div className="form-group col-md-6">
                                                                        <label for="feName">Total Gross</label>
                                                                        <p>
                                                                            IDR {currency(this.state.fields['gross_amount'])}
                                                                        </p>
                                                                    </div>

                                                                    <div className="form-group col-md-6">
                                                                        <label for="feName">Shipping Name</label>
                                                                        <p>
                                                                            {this.state.fields['shipping_name']}
                                                                        </p>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feName">Total Tax</label>
                                                                        <p>
                                                                            IDR {currency(this.state.fields['total_tax'])}
                                                                        </p>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feName">Shipping Address</label>
                                                                        <p>
                                                                            {this.state.fields['shipping_address']}
                                                                        </p>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feName">Sub Total</label>
                                                                        <p>
                                                                            IDR {currency(this.state.fields['sub_total'])}
                                                                        </p>
                                                                    </div>
                                                                    <div className="form-group col-md-6">

                                                                    </div>


                                                                    <div className="form-group col-md-6">
                                                                        <label for="feName">Total</label>
                                                                        <p>
                                                                            IDR {currency(this.state.fields['total'])}
                                                                        </p>
                                                                    </div>



                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feStatus">Status</label>
                                                                        <select id="status" name="status" className="form-control" value={this.state.fields['status']} onChange={this.handleChange}>
                                                                            <option value=''>Select</option>
                                                                            <option value='pending'>Menunggu Konfirmasi</option>
                                                                            <option value='paid'>Diproses</option>
                                                                            <option value='packed'>Dikemas</option>
                                                                            <option value='dispatched'>Dikirim</option>
                                                                            <option value='delivered'>Tiba di tujuan</option>
                                                                        </select>
                                                                        <span style={{ color: 'red' }}>{this.state.errors['status']}</span>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feStatus">Payment Status</label>
                                                                        <select id="payment_status" name="payment_status" className="form-control" value={this.state.fields['payment_status']} onChange={this.handleChange}>
                                                                            <option value=''>Select</option>
                                                                            <option value='authorize'>authorize</option>
                                                                            <option value='capture'>capture</option>
                                                                            <option value='settlement'>settlement</option>
                                                                            <option value='deny'>deny</option>
                                                                            <option value='pending'>pending</option>
                                                                            <option value='cancel'>cancel</option>
                                                                            <option value='refund'>refund</option>
                                                                            <option value='partial_refund'>partial_refund</option>
                                                                            <option value='chargeback'>chargeback</option>
                                                                            <option value='partial_chargeback'>partial_chargeback</option>
                                                                            <option value='expire'>expire</option>
                                                                            <option value='failure'>failure</option>
                                                                        </select>
                                                                        <span style={{ color: 'red' }}>{this.state.errors['payment_status']}</span>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label for="feshippingResi">Nomor Resi</label>
                                                                        <input id="shippingResi" name="shippingResi" className="form-control" value={this.state.fields['shippingResi']} onChange={this.handleChange} />
                                                                        <span style={{ color: 'red' }}>{this.state.errors['shippingResi']}</span>
                                                                    </div>
                                                                </div>
                                                                <button type="submit" className="btn btn-success">Update</button>&nbsp;
                                                                <Link to="/orders-list" className="btn btn-danger">Cancel</Link>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className='card'>
                                            <div className="card-header border-bottom">
                                                <h6 className="m-0">Product Order</h6>
                                            </div>                                            <div className='card-body'>
                                                <div class="row">
                                                    <div class="col-sm-12 ">

                                                        <div style={{ overflow: "auto" }} className='w-100'>
                                                            <table className='table'>
                                                                <thead className='thead-dark'>
                                                                    <tr>
                                                                        <th scope="col">#</th>
                                                                        <th scope="col">Image</th>
                                                                        <th scope="col">Name</th>
                                                                        <th scope="col">Brand</th>
                                                                        <th scope="col">Price</th>
                                                                        <th scope="col">Quantity</th>
                                                                        <th scope="col">Weight</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.fields['product_details']?.map((item, idx) => (
                                                                        <tr>
                                                                            <th scope="row">{idx + 1}</th>
                                                                            <td><img src={BASEASSETURL + item.image} className='mb-3' height='50' width='50' alt='' /></td>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.brand}</td>
                                                                            <td>{currency(item.price)}</td>
                                                                            <td>{item.quantity}</td>
                                                                            <td>{item.weight}  {item.unit}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer />
                            {/* <Footer/> */}
                        </main>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    order: state.orders.order,
});
const actionCreators = {
    getOrder: getOrder,
    updateOrder: updateOrder,
};
export default connect(mapStateToProps, actionCreators)(EditOrder);