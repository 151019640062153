import {
    POST_CUSTOMER_QUERIES_REQUEST, GET_CUSTOMER_QUERIES_SUCCESS, GET_CUSTOMER_QUERIES_FAILURE,
    POST_CUSTOMER_QUERY_REQUEST, GET_CUSTOMER_QUERY_SUCCESS, GET_CUSTOMER_QUERY_FAILURE,
    POST_QUERY_RESPONSE_REQUEST, GET_QUERY_RESPONSE_SUCCESS, GET_QUERY_RESPONSE_FAILURE
} from '../_actions/_type';

export const initialState = {
    responseSent: false,
    queries: [],
    query: [],
    user:{},
    loading: false,
    errors: {},
};
export default function queries(state = initialState, action) {
    switch (action.type) {
        case POST_CUSTOMER_QUERIES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_CUSTOMER_QUERIES_SUCCESS:
            return {
                ...state,
                queries: action.payload,
                loading: false
            };
        case GET_CUSTOMER_QUERIES_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        case POST_CUSTOMER_QUERY_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_CUSTOMER_QUERY_SUCCESS:
            return {
                ...state,
                query: action.payload.data,
                user: action.payload.user,
                loading: false
            };
        case GET_CUSTOMER_QUERY_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        case POST_QUERY_RESPONSE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_QUERY_RESPONSE_SUCCESS:
            return {
                ...state,
                responseSent: true,
                loading: false
            };
        case GET_QUERY_RESPONSE_FAILURE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        default:
            return state;
    }
}
