import React, { Component } from 'react';
import PeopleAltTwoToneIcon from '@material-ui/icons/PeopleAltTwoTone';
import MonetizationOnTwoToneIcon from '@material-ui/icons/MonetizationOnTwoTone';
import ListTwoToneIcon from '@material-ui/icons/ListTwoTone';
import { getdashboard } from '../../_actions/dashboardActions.js';
import Header from '../CommonTemplates/Header.js';
import SideBar from '../CommonTemplates/SideBar.js';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import currency from '../../utils/currency.js';

class Dashboard extends Component {
  componentDidMount = async () => {
    await this.props.getdashboard();
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <SideBar />

            <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
              <Header />

              <div className="main-content-container container-fluid px-4">
                <div className="page-header row no-gutters py-4">
                  <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                    <span className="text-uppercase page-subtitle">Dashboard</span>
                    {/* <h3 className="page-title">Blog Overview</h3> */}
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-4'>
                    <div class="stats-small stats-small--1 card card-small mb-2">
                      <div class="card-header border-bottom">
                        <div className="stats-small__label text-center text-uppercase">Total Orders</div>
                      </div>
                      <div class="card-body">
                        <div className='stats-small__data text-center'>
                          <h6 class="stats-small__value count my-3">{this.props.data.total_orders}</h6>
                          <ListTwoToneIcon style={{ color: "#7fdbeb" }} />
                        </div>
                      </div>
                      <div className='card-footer border-top'>
                        <div className="text-right view-report">
                          <Link className="stats-small__label" to='/orders-list'>More info &rarr;</Link>
                        </div>
                      </div>
                      <canvas height="120" class="blog-overview-stats-small-1"></canvas>
                    </div>
                  </div>

                  <div className='col-md-4'>
                    <div class="stats-small stats-small--1 card card-small mb-2">
                      <div class="card-header border-bottom">
                        <div className="stats-small__label text-center text-uppercase">Total Earnings</div>
                      </div>
                      <div class="card-body">
                        <div className='stats-small__data text-center'>
                          <h6 class="stats-small__value count my-3">IDR {currency(this.props.data.total_earnings)}</h6>
                          <MonetizationOnTwoToneIcon style={{ color: "#8be2b8" }} />
                        </div>
                      </div>
                      <div className='card-footer border-top'>
                        <div className="text-right view-report">
                          <Link className="stats-small__label" to='/earning-reports'>More info &rarr;</Link>
                        </div>
                      </div>
                      <canvas height="120" class="blog-overview-stats-small-2"></canvas>
                    </div>
                  </div>

                  <div className='col-md-4'>
                    <div class="stats-small stats-small--1 card card-small mb-2">
                      <div class="card-header border-bottom">
                        <div className="stats-small__label text-center text-uppercase">Total Customers</div>
                      </div>
                      <div class="card-body">
                        <div className='stats-small__data text-center'>
                          <h6 class="stats-small__value count my-3">{this.props.data.total_users}</h6>
                          <PeopleAltTwoToneIcon style={{ color: "#7fbdff" }} />
                        </div>
                      </div>
                      <div className='card-footer border-top'>
                        <div className="text-right view-report">
                          <Link className="stats-small__label" to='/users-list'>More info &rarr;</Link>
                        </div>
                      </div>
                      <canvas height="120" class="blog-overview-stats-small-5"></canvas>
                    </div>
                  </div>
                </div>

              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const styles = {
  iconStyles: {
    fontSize: 75,
    color: '#0d0da6',
    position: 'relative',
  }
};
const mapStateToProps = state => ({
  data: state.dashboards.dashboards
});
const actionCreators = { getdashboard: getdashboard };
export default connect(mapStateToProps, actionCreators)(Dashboard)
//export default Dashboard;